<div class="sw-layout_press-footer">
    <div>
        <ng-content select="layout-header"></ng-content>
    </div>
    <div class="sw-layout_press-footer__content">
        <ng-content></ng-content>
    </div>
    <div>
        <ng-content select="layout-footer"></ng-content>
    </div>
</div>
