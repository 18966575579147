import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { createPopper, flip, Instance, preventOverflow, hide } from '@popperjs/core';

@Component({
    selector: 'sw-tooltip',
    template: `
        <div
            [hidden]="!arrow"
            class="sw-tooltip__arrow"
            data-popper-arrow
        ></div>
        <slot></slot>
    `,
    styleUrls: ['tooltip.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipElement implements OnDestroy, OnInit {
    // тот элемент который вызывает тултип
    @Input() reference: Element;

    // действие которое вызывает тултип, по умолчанию наведение
    @Input() eventType: 'hover' | 'click' = 'hover';

    // расположение тултипа, по умолчанию снизу от начала вызывающего элемента.
    @Input() placement:
        | 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end' = 'bottom-start';

    // включает слушатель изменения контента
    @Input() watchChange: boolean = false;

    @Input() type: 'dark' | 'light' = 'dark';

    // смещение тултипа [x, y]
    @Input() offset: [number, number] = [0, 4];

    // разрешает клик внутри тултипа, не будет сворачивать его по этому клику
    @Input() allowClickInside: boolean = false;

    // Если тултип статичный, то не будет происходить его переориентирование по расположению
    // Возможно использование только при eventType = 'hover'
    // Наведение будет работать со стилями, без слушателей наведения
    // Статичный компонент тултипа, должен находиться внутри вызывающего родителя!!!
    @Input() isStatic: boolean = false;

    @Input() arrow: boolean = true;

    @HostBinding('attr.role') role: string = 'tooltip';

    @HostBinding('class') get tooltipClass() {
        return {
            [`sw-tooltip_${this.type}`]: !!this.type,
            ['sw-tooltip_static']: this.isStatic,
        };
    }

    @HostBinding('style') get tooltipStyle() {
        if (!this.isStatic) return;
        return {
            [`--offset-left`]: this.offset[0] + 'px',
            [`--offset-top`]: this.offset[1] + 'px',
        };
    }

    readonly showEvents = ['mouseenter', 'focus'];

    readonly hideEvents = ['mouseleave', 'blur'];

    instancePopper: Instance;

    isShowed: boolean = false;

    showListen: (() => void)[] = [];

    hideListen: (() => void)[] = [];

    globalListen: () => void;

    // слушает изменения содержимого
    changeListen: () => void;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        if (this.reference) {
            if (!this.isStatic) {
                this.instancePopper = createPopper(this.reference, this.elementRef.nativeElement, {
                    // options
                    placement: this.placement,
                    strategy: 'fixed',
                    modifiers: [
                        preventOverflow,
                        flip,
                        hide,
                        {
                            name: 'offset',
                            options: {
                                offset: this.offset,
                            },
                        },
                        {
                            name: 'computeStyles',
                            options: {
                                gpuAcceleration: false, // true by default
                            },
                        },
                    ],
                });
            } else {
                this.renderer.setStyle(this.reference, 'position', 'relative');
            }

            if (this.eventType === 'hover') {
                this.showEvents.forEach((event) => {
                    this.showListen.push(
                        this.renderer.listen(this.reference, event, this.show.bind(this)),
                    );
                });

                this.hideEvents.forEach((event) => {
                    this.hideListen.push(
                        this.renderer.listen(this.reference, event, this.hideTooltip.bind(this)),
                    );
                });
            } else {
                this.showListen.push(
                    this.renderer.listen(this.reference, 'click', this.toggle.bind(this)),
                );

                this.globalListen = this.renderer.listen('document', 'click', (event) => {
                    const clickedInside = this.reference.contains(event.target);
                    if (this.isShowed && !clickedInside && !this.allowClickInside) {
                        this.renderer.removeAttribute(this.elementRef.nativeElement, 'data-show');
                        this.isShowed = !this.isShowed;
                    }
                });
            }

            if (this.watchChange && this.instancePopper) {
                this.changeListen = this.renderer.listen(
                    this.elementRef.nativeElement,
                    'DOMCharacterDataModified',
                    this.instancePopper.update.bind(this),
                );
            }
        }
    }

    ngOnDestroy(): void {
        if (this.instancePopper) this.instancePopper.destroy();
        // Remove listeners
        this.hideListen.forEach((fn) => fn());
        this.showListen.forEach((fn) => fn());
        if (this.globalListen) this.globalListen();
        if (this.changeListen) this.changeListen();
    }

    private show() {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'data-show', '');
        if (this.instancePopper) this.instancePopper.update();
        this.isShowed = !this.isShowed;
    }

    private hideTooltip() {
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'data-show');
        this.isShowed = !this.isShowed;
    }

    private toggle() {
        if (this.isShowed) {
            this.hideTooltip();
        } else {
            this.show();
        }
    }
}
