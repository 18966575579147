import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TimepickerElement } from './timepicker.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';
import { ButtonModule } from '@shared/components/button/button.module';

@NgModule({
    declarations: [TimepickerElement],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, ButtonModule, IconModule],
    exports: [TimepickerElement],
})
export class TimepickerModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TimepickerElement, {
            injector: this.injector,
        });

        customElements.define('sw-timepicker', el);
    }
}
