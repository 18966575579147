import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-press-footer',
    templateUrl: './layout-press-footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPressFooterComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
