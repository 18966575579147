<ng-container *ngIf="{ tablist: tablist$ | async } as data"> </ng-container>
<div
    class="sw-tabs__wrapper"
    [style.margin-right]="containerWidth < allTabsWidth ? '91px' : 0"
>
    <div
        class="sw-tabs__container"
        #tabsContainer
        [style.transform]="'translateX(' + shift + 'px)'"
        swipe
        (swipeLeft)="slideStart()"
        (swipeRight)="slideEnd()"
    >
        <ng-content></ng-content>
    </div>
</div>

<div
    class="sw-btn-group swh-hide-sm-down"
    *ngIf="containerWidth < allTabsWidth"
>
    <sw-button
        [disabled]="shift === 0"
        (click)="slideStart()"
    >
        <sw-icon name="angle-left"></sw-icon>
    </sw-button>
    <sw-button
        [disabled]="isEnd"
        (click)="slideEnd()"
    >
        <sw-icon name="angle-right"></sw-icon>
    </sw-button>
</div>
