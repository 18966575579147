<ng-container *ngIf="assembly.production">
    <!-- Yandex.Metrika counter -->
    <noscript
        ><div
            ><img
                src="https://mc.yandex.ru/watch/93017534"
                style="position: absolute; left: -9999px"
                alt="" /></div
    ></noscript>
    <!-- /Yandex.Metrika counter -->
</ng-container>
<router-outlet></router-outlet>
<app-modals></app-modals>
<app-alert></app-alert>
<app-toast></app-toast>
<app-global-loader></app-global-loader>
