import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import { EntDescription } from '@shared/models/srv.types';

@Pipe({
    name: 'entity',
})
export class EntityPipe implements PipeTransform {
    constructor(private entity: EntityService) {}

    transform(
        id: string | EntDescription,
        entityType: string = '',
        forceLoad = false,
        doNotInitLoad = false,
    ) {
        if (!id) return of(null);
        if (id instanceof Object)
            return this.entity.getEntity$(id.type, id.id, forceLoad, doNotInitLoad);
        return this.entity.getEntity$(entityType, id, forceLoad, doNotInitLoad);
    }
}
