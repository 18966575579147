import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BadgeElement } from './badge.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';

@NgModule({
    declarations: [BadgeElement],
    imports: [CommonModule, IconModule],
    exports: [BadgeElement],
})
export class BadgeModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(BadgeElement, {
            injector: this.injector,
        });

        customElements.define('sw-badge', el);
    }
}
