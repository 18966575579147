import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'sw-badge',
    template: `<slot></slot>`,
    styleUrls: ['badge.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class BadgeElement {
    @Input() size: 'xxs' | 'xs' | 'small' | 'medium' | 'large' = 'small';

    @HostBinding('class') get badgeStyle() {
        return {
            [`sw-badge-${this.size}`]: !!this.size,
        };
    }

    constructor() {}
}
