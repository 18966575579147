export interface User {
    first_name: string;
    id: string;
    last_name: string;
    organization: Organization;
    roles: UserRole[];
    username: string;
    orgname?: string;
    organization_info_id?: string;
    _organization_info?: {
        id: string;
    };
    _organization?: {
        id: string;
        __str__: string;
        address?: string;
        point?: any;
    };
    modeling_host?: string;
    telemetry_host?: string;
    session_id?: string;
}

export interface Organization {
    address_legal: string;
    address_post: string;
    address_post_index: string;
    author: string;
    author_updated: string;
    bank_name: string;
    bik: string;
    comment: string;
    contacts: { fax: string; email: string; phone: string };
    director_name: string;
    director_position: string;
    egr: string;
    id: string;
    inn: number;
    justification: string;
    kpp: string;
    ks: string;
    name_full: string;
    name_short: string;
    ogrn: number;
    okpo: string;
    okved: string;
    opf: string;
    org_owner: string;
    owner_type: string;
    personal_account: string;
    rs: string;
}

export interface IAggsOrg {
    count_wasteplace_all?: number;
    count_wasteplace_accepted?: number;
    count_facility_all?: number;
    count_facility_accepted?: number;
    count_vehicle_all?: number;
    count_vehicle_accepted?: number;
    count_users?: number;
}

export interface UserRole {
    description: string;
    id: string;
    name: string;
    slug: string; //"RPRN00102.UOIT_OPVK_LK"
}

export interface UIRole {
    id: string;
    name: string;
    description: string;
    permissions: UIPermission[];
}

export interface UIPermission {
    slug: string;
    value: any;
}

export interface AuthEsia {
    data: {
        error?: {
            code: string;
            text: string;
        };
        email?: string;
        first_name?: string;
        last_name?: string;
        state: AuthSates; //"auth", "wait", "unauth",
        token_session?: string;
        user_id?: number;
        orgs?: AuthOrg[];
        url_follow?: string;
    };
}

export interface AuthToken {
    access_token: 'string';
    token_type: 'string';
}

export type AuthSates = 'auth' | 'wait' | 'unauth';

export interface AuthOrg {
    name: string;
    oid: number;
    ogrn: string;
}

export interface SrvResponse {
    data: EntData[] | EntData;
    meta: any;
    included: any[];
}

export interface FieldsSchema {
    [key: string]: EntPropertyConfig;
}

export interface EntPropertyConfig {
    type?: string;
    name?: string;
    modality?: string;
    readonly?: boolean;
    nullable?: boolean;
    initial?: any;
    string_allow_blank?: boolean;
    string_len_max?: number;
    string_len_max_note?: string;
    string_len_min?: number;
    string_len_min_note?: string;
    number_max?: number;
    number_max_note?: string;
    number_min?: number;
    number_min_note?: string;
    empty_value?: any;
    minDate?: string;
    maxDate?: string;
    dateFormat?: string;
    email?: boolean;
    date_unloading?: {
        date_unloading_min: number; // не ранее от текущей даты, дней
        date_unloading_max: number; // не позднее от текущей даты, дней
        date_unloading_interval: number; // ширина диапазона дат не менее, дней
    };
}

export interface SimpleData {
    id: string;
    [key: string]: any;
}

export interface EntDescription {
    id: string;
    type: string;
}

export interface EntData {
    type: string;
    id?: string;
    included?: [{ id: string; type: string }];
    $snapshot?: any;
    $meta?: {
        schema?: FieldsSchema;
        state_actions: any[];
        //filters
        allow_filter_statuses?: string[];
    };
    $transitMap?: {
        [index: string]: EntTransit;
    };
    $transits?: string[];
    $canSign?: boolean;
    $signContext?: string;
    $canAdd?: boolean;
    $canChange?: boolean;
    $canView?: boolean;
    $canDelete?: boolean;
}

export interface EntList {
    type?: string;
    list: EntData[];
    $meta?: {
        schema?: FieldsSchema;
        pagination: any;
        status_allowed?: string[];
        allow_filter_statuses?: string[];
    };
}

export interface EntTransit {
    id: string;
    isAllowed: boolean;
    restricts: any[];
    payload: string[];
    hint: string;
    url: string;
    needMySign: boolean;
    needOtherSign: boolean;
    showSignButtonInsteadOfTransit: boolean;
    restrictsToDisplay: any[];
    payloadMap: { [index: string]: any };
}

export interface EntTransitData {
    transit: string;
    payload: string;
}

export interface EntDocumentSettings {
    entityType: string;
    entityId: string;
    contextLabel?: string;
    documentPayload?: any;
    signBodyPayload?: any;
    document?: EntData;
    cancelPreviousSignings?: boolean;
}

export interface EntSchema {
    type: string;
    id: string;
    attributes: any;
    relationships: any;
    required: string[];
    error?: number;
}

export type SchemaType =
    | 'schema_list'
    | 'schema_create'
    | 'schema_update'
    | 'schema_transit'
    | 'schema_retrieve';

export interface SrvSchema {
    title: string;
    entityType: string;
    required?: string[];
    definitions: {
        [index: string]: any;
    };
    properties: {
        [index: string]: any;
    };
    error?: number;
}
export interface SrvEntData {
    type: string;
    id: string;
    attributes: {
        [index: string]: any;
    };
    relationships: {
        [index: string]: any;
    };
    meta: {
        [index: string]: any;
    };
}

export interface SrvErrors {
    errors: SrvError[];
}

export interface SrvError {
    status: string;
    title?: string;
    code: string;
    source?: {
        pointer: string;
        parameter: string;
    };
    detail: string;
    meta: any;
}

export interface SearchSettings {
    entityType: string;
    fieldForId?: string;
    suffix?: string;
    params?: { [index: string]: string };
    limit?: number;
    paramsGenerator?: Function;
}

export interface GroupRequestItem {
    entityType: string;
    entityId?: string;
    requestType: 'list' | 'item';
    meta: string[];
    limit?: number;
    offset?: number;
    sort?: number;
    params: Object;
    body?: Object;
    method?: 'PATCH' | 'POST' | 'GET' | 'DELETE';
}

export interface MediaDescription {
    src: string;
    type: 'image' | 'video' | 'plot';
    name?: string;
    data?: ArrayBuffer;
    alt?: string;
    isLoaded?: boolean;
}
