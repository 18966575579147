import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    list$ = this.breadcrumbs.breadcrumbs$;

    constructor(public breadcrumbs: BreadcrumbsService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
