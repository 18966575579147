<div
    class="sw-cookie"
    *transloco="let t"
>
    <div
        *ngIf="showCookie"
        class="sw-container"
    >
        <div class="sw-block sw-block_shadow">
            <div class="swh-flex">
                <sw-icon name="cookie"></sw-icon>
                <div
                    [innerHTML]="t('cookie', {link})"
                ></div>
            </div>
            <sw-button
                color="action"
                (click)="accept()"
            >
                {{ t('action.accept') }}
            </sw-button>
        </div>
    </div>
</div>
