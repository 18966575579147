<ng-container *transloco="let t">
    <ng-container *ngIf="role.id | entity: role.type | async as roleData">
        <div
            class="sw-item"
            [class.sw-item--disabled]="mobileRoles.indexOf(roleData.$snapshot.slug) > -1"
            #roleItem
        >
            <sw-button
                fill="clear"
                (click)="select.emit()"
                [disabled]="mobileRoles.indexOf(roleData.$snapshot.slug) > -1"
            >
                <sw-icon [name]="menuItem.icon"></sw-icon>
                {{
                    t(menuItem.titleKey, {
                        roleName: roleData.$snapshot.name.toLowerCase()
                    })
                }}
            </sw-button>
        </div>
        <sw-tooltip
            *ngIf="mobileRoles.indexOf(roleData.$snapshot.slug) > -1"
            [reference]="roleItem"
            [isStatic]="false"
            placement="bottom"
            class="swh-popper-z-index"
        >
            {{ t('header.roleTooltip') }}
        </sw-tooltip>
    </ng-container>
</ng-container>
