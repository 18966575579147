import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';

export class TemplateObservable<T> {
    private value$: BehaviorSubject<T>;

    private _value: T;

    $: Observable<T>;

    get _() {
        return this._value;
    }

    constructor(defaultValue: T = null) {
        this._value = defaultValue;
        this.value$ = new BehaviorSubject<T>(this._value);
        this.$ = this.value$.asObservable().pipe(shareReplay(1));
    }

    set(value: T) {
        this._value = value;
        this.value$.next(this._value);
    }
}
