import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TemplateObservable } from '@shared/classes/template-observable';
import isEqual from 'lodash.isequal';

export interface Toast {
    title?: '';
    message?: '';
    titleKey?: '';
    messageKey?: '';
    titleParams?: any;
    messageParams?: any;
    cssClass?: string | string[];
    duration?: number;
    buttons?: (ToastButton | string)[];
    position?: 'top' | 'bottom' | 'middle';
    animated?: boolean;
    icon?: string;
    color?: string;
    id?: string;
    createdAt?: number;
    dismissAt?: number;
    stack?: boolean; // do not show message if previous is the same
    type?: 'success' | 'error' | 'warning';
}

export interface ToastButton {
    text?: string;
    icon?: string;
    side?: 'start' | 'end';
    role?: 'cancel' | string;
    cssClass?: string | string[];
    handler?: () => boolean | void | Promise<boolean | void>;
}

export const TOAST_GROUPS = ['top', 'bottom', 'center', 'middle'];

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private _defaultParams: Toast = {
        title: '',
        message: '',
        titleKey: '',
        messageKey: '',
        cssClass: '',
        duration: 5000,
        buttons: [],
        position: 'bottom',
        animated: false,
        icon: '',
        color: '',
        id: '',
        createdAt: 0,
        stack: false,
    };

    toastGroups = new TemplateObservable({
        top: [],
        bottom: [],
        center: [],
        middle: [],
    });

    constructor() {
        this.startCounter();
    }

    startCounter() {
        setInterval(() => {
            const currentTime = new Date().getTime();
            const toastGroups = this.toastGroups._;
            TOAST_GROUPS.forEach((group) => {
                toastGroups[group] = toastGroups[group].filter((v) => v.dismissAt > currentTime);
            });
            this.toastGroups.set(toastGroups);
        }, 1000);
    }

    create(params) {
        const createdAt = new Date().getTime();

        if (params.type === 'success') {
            params = {
                ...params,
                icon: 'check',
                color: 'success',
                position: 'top',
            };
        }
        if (params.type === 'error') {
            params = {
                ...params,
                icon: 'cross',
                color: 'danger',
                position: 'top',
                stack: true,
            };
        }
        if (params.type === 'warning') {
            params = {
                ...params,
                icon: 'exclamation',
                color: 'warning',
                position: 'top',
            };
        }

        params = {
            ...this._defaultParams,
            ...params,
            id: params.id || uuidv4(),
            createdAt,
        };
        params.dismissAt = params.createdAt + params.duration;
        this.toastGroups.set(this.stackToasts(params));
        return params;
    }

    stackToasts(params) {
        const toastGroups = this.toastGroups._;
        const targetGroup = toastGroups[params.position];
        let addToGroup = !params.stack || targetGroup.length === 0;
        if (!addToGroup) {
            const lastElement = targetGroup[targetGroup.length - 1];
            if (!lastElement.stack) {
                addToGroup = true;
            } else {
                const copy = { ...lastElement };
                copy.createdAt = params.createdAt;
                copy.dismissAt = params.dismissAt;
                copy.id = params.id;
                addToGroup = !isEqual(copy, params);
            }
        }
        if (addToGroup) {
            targetGroup.push(params);
        } else {
            targetGroup[targetGroup.length - 1].createdAt = params.createdAt;
            targetGroup[targetGroup.length - 1].dismissAt = params.dismissAt;
        }
        return toastGroups;
    }

    dismiss(id, group = null) {
        const toastGroups = this.toastGroups._;
        if (group) {
            toastGroups[group] = toastGroups[group].filter((v) => v.id !== id);
        } else {
            TOAST_GROUPS.forEach((toastGroup: string) => {
                toastGroups[toastGroup] = toastGroups[toastGroup].filter((v) => v.id !== id);
            });
        }
        this.toastGroups.set(toastGroups);
    }

    // TODO: dismiss all toast
    dismissAllToast(position = null) {
        if (position) {
        } else {
        }
    }
}
