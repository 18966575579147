import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

export type EntityStatusListItem = {
    title?: string;
    titleKey?: string;
    titleKeyParam?: any;
    titleEntity?: TitleEntity;
    defaultTitle?: string;
    defaultTitleKey?: string;
    defaultTitleKeyParam?: any;
    isValid: boolean;
    children?: EntityStatusListItem[];
};

export type TitleEntity = {
    id?: string;
    entityType?: string;
    titleField?: string;
    titleEntity?: TitleEntity;
};

@Component({
    selector: 'app-entity-status-list',
    templateUrl: './entity-status-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusListComponent implements OnDestroy, OnInit {
    @Input()
    list$: Observable<EntityStatusListItem[]>;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
