<!--группы вынести в toast-group (файлы созданы) c учетом параметров,
принимающихся сервисом, можно добавлять/редактировать параметры при необходимости
-->
<ng-container *ngIf="alertService.alert$ | async as alert">
    <div
        class="sw-alert"
        *transloco="let t"
    >
        <div
            *ngIf="alert.options as alert"
            class="sw-toast"
            [class]="alert.cssClass"
        >
            <div
                *ngIf="alert.icon"
                class="sw-toast__icon"
            >
                <div
                    class="sw-sign"
                    [color]="alert.color"
                >
                    <sw-icon [name]="alert.icon"></sw-icon>
                </div>
            </div>

            <div class="sw-toast__content">
                <div
                    *ngIf="alert.headerKey"
                    class="sw-toast__title"
                >
                    {{ t(alert.headerKey, alert.headerParams) }}
                </div>
                <div
                    *ngIf="alert.header"
                    class="sw-toast__title"
                >
                    {{ alert.header }}
                </div>
                <div
                    class="sw-toast__message"
                    *ngIf="alert.message"
                    [innerHTML]="alert.message"
                ></div>
                <div
                    class="sw-toast__message"
                    *ngIf="alert.messageKey"
                    [innerHTML]="t(alert.messageKey, alert.messageParams)"
                ></div>

                <div class="sw-toast__buttons">
                    <ng-container *ngFor="let button of alert.buttons">
                        <sw-button
                            [fill]="button.fillButton || 'solid'"
                            [color]="button.color || 'action'"
                            (click)="buttonClick(button)"
                        >
                            <sw-icon
                                *ngIf="button.iconStart"
                                slot="start"
                                [icon]="button.iconStart"
                            ></sw-icon>
                            <sw-icon
                                *ngIf="button.iconEnd"
                                slot="end"
                                [icon]="button.iconEnd"
                            ></sw-icon>
                            {{ t(button.titleKey, button.titleParams) }}
                        </sw-button>
                    </ng-container>
                </div>
            </div>

            <button
                *ngIf="alert.hasCloseIcon"
                class="sw-toast__close"
                (click)="dismiss()"
            >
                <sw-icon
                    name="cross-small"
                    size="small"
                ></sw-icon>
            </button>
        </div>
    </div>
    <div
        class="sw-backdrop"
        (click)="dismiss()"
    ></div>
</ng-container>
