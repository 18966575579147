<div class="sw-gallery-slider">
    <div class="swh-overhide">
        <div
            #sliderContent
            class="sw-list sw-list_h sw-list_gallery sw-slider__content"
            [style.margin-left]="margin + 'px'"
            swipe
            (swipeLeft)="slide(1)"
            (swipeRight)="slide(-1)"
        >
            <ng-content></ng-content>
        </div>
    </div>
    <sw-button
        fill="outline"
        color="action"
        class="sw-gallery-slider__prev"
        [disabled]="current === 0"
        (click)="slide(-1)"
    >
        <sw-icon slot="icon-only" name="angle-small-left"></sw-icon>
    </sw-button>
    <sw-button
        fill="outline"
        color="action"
        class="sw-gallery-slider__next"
        [disabled]="isEndSlider"
        (click)="slide(1)"
    >
        <sw-icon slot="icon-only" name="angle-small-right"></sw-icon>
    </sw-button>
</div>
