export const TILE_SERVER_DEFAULT = 'map.asu.big3.ru';

export const COMMON_OPTIONS = {
    maxNativeZoom: 18,
    maxZoom: 21,
};

export const LAYERS = {
    GRID: {
        key: 'GRID',
        type: 'grid',
        title: '<span class="icon icon_border-outer"></span> Сетка',
        options: {
            isCoordShown: true,
            step: 10,
            coordStyle: 'MinDec',
            // coordTemplates: 10,
            lineStyle: {
                stroke: true,
                color: '#111',
                opacity: 0.7,
                weight: 1,
            },
            // redraw: 'move'
        },
    },
    GRID2: {
        key: 'GRID2',
        type: 'grid',
        title: '<span class="icon icon_border-inner"></span> Сетка',
        options: {
            coordStyle: 'DMS',
            // coordTemplates: 10,
            // maxStepsCount: 200,
            lineStyle: {
                stroke: true,
                color: '#000',
                opacity: 0.4,
                weight: 1,
            },
            // redraw: 'move'
        },
    },
    GRID10: {
        key: 'GRID10',
        type: 'grid',
        title: '<span class="icon icon_border-inner"></span> Сетка с метками',
        options: {
            coordStyle: 'DMS',
            isCoordShown: true,
            step: 1,
            lineStyle: {
                stroke: true,
                color: '#fff',
                opacity: 0.5,
                weight: 3,
            },
            // redraw: 'move'
        },
    },
    EDITABLE: {
        key: 'EDITABLE',
        title: '<span class="icon icon_map2"></span> Редактируемая карта',
        url: '/svc/map/tiles/editable/{z}/{x}/{y}.png',
        isEditable: true,
        options: { ...COMMON_OPTIONS, className: 'nc-maplayer_editable' },
    },
    OPENSTREET: {
        key: 'OPENSTREET',
        title: '<span class="icon icon_map2"></span> OpenStreetMap',
        url: `https://{s}.${TILE_SERVER_DEFAULT}/street/{z}/{x}/{y}.png`,
        options: COMMON_OPTIONS,
    },
    BLACK_WHITE: {
        key: 'BLACK_WHITE',
        title: '<span class="asuicon asuicon_blackandwhite"></span> Черно-белая OpenStreetMap',
        url: `https://{s}.${TILE_SERVER_DEFAULT}/bw/{z}/{x}/{y}.png`,
        options: COMMON_OPTIONS,
    },
    LIGHT: {
        key: 'LIGHT',
        title: '<span class="asuicon asuicon_lighten"></span> Светлая',
        url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    GRAY: {
        key: 'GRAY',
        title: '<span class="asuicon asuicon_grey"></span> Серая',
        url: 'https://korona.geog.uni-heidelberg.de/tiles/roadsg/x={x}&y={y}&z={z}',
        options: COMMON_OPTIONS,
    },
    DARK: {
        key: 'DARK',
        title: '<span class="asuicon asuicon_dark"></span> Тёмная',
        url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    TONER: {
        key: 'TONER',
        title: '<span class="asuicon asuicon_toner"></span> Тонер',
        url: 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    TONERX2: {
        key: 'TONERX2',
        title: '<span class="asuicon asuicon_toner"></span> Тонер 2',
        url: 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}@2x.png',
        options: COMMON_OPTIONS,
    },
    GPS: {
        key: 'GPS',
        title: '<span class="icon icon_radar"></span> GPS треки',
        url: 'https://{s}.gps-tile.openstreetmap.org/lines/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    YANDEX: {
        key: 'YANDEX',
        title: 'Яндекс',
        url: 'https://vec02.maps.yandex.net/tiles?l=map&v=4.40&x={x}&y={y}&z={z}',
        options: COMMON_OPTIONS,
    },
    GLOBE: {
        key: 'GLOBE',
        title: '<span class="icon icon_satellite"></span> Спутник 2',
        url: 'https://{s}.tiles.mapbox.com/v4/digitalglobe.316c9a2e/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqOGRmNXltOTBucm0yd3BtY3E5czl6NmYifQ.qJJsPgCjyzMCm3YG3YWQBQ',
        options: COMMON_OPTIONS,
    },
    TRAFFIC: {
        key: 'TRAFFIC',
        title: '<span class="icon icon_traffic-lights"></span> Пробки',
        url: 'https://3.traffic.maps.api.here.com/maptile/2.1/traffictile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=xWVIueSv6JL0aJ5xqTxb&token=djPZyynKsbTjIUDOBcHZ2g&lg=RUS',
        options: COMMON_OPTIONS,
    },
    RELIEF: {
        key: 'RELIEF',
        title: '<span class="icon icon_"></span> Рельеф',
        url: 'https://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg',
        options: COMMON_OPTIONS,
    },
    TWOGIS: {
        key: 'TWOGIS',
        title: '<span class="icon icon_map"></span> 2ГИС',
        url: 'http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1',
        options: { ...COMMON_OPTIONS, attribution: '&copy; <a href="http://law.2gis.ru">2ГИС</a>' },
    },
    SATELLITE: {
        key: 'SATELLITE',
        title: '<span class="icon icon_satellite"></span> Спутник',
        url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        options: {
            ...COMMON_OPTIONS,
            attribution:
                "Map: <a href='http://www.arcgis.com/home/item.html?id=c4ec722a1cd34cf0a23904aadf8923a0'>ArcGIS - World Physical Map</a>",
        },
    },
    LANDSCAPE: {
        key: 'LANDSCAPE',
        title: '<span class="icon icon_"></span> Ландшафт',
        url: 'http://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png',
        options: {
            ...COMMON_OPTIONS,
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
        },
    },
    CADASTRE: {
        key: 'CADASTRE',
        type: 'wms',
        url: 'http://pkk5.rosreestr.ru/arcgis/services/Cadastre/CadastreWMS/MapServer/WMSServer',
        title: '<span class="icon icon_compass"></span> Кадастровая карта',
        options: {
            layers: '0,1,2,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,23,24,25,26,27,28,29,30,31',
            format: 'image/png',
            transparent: true,
            zIndex: 20,
        },
    },
    VOLGA: {
        key: 'VOLGA',
        url: 'https://map.b3asu.ru/volga/{z}/{x}/{y}.png',
        title: '<span class="icon icon_panorama"></span> Берега Волги',
        options: {
            minNativeZoom: 8,
            maxNativeZoom: 21,
            maxZoom: 21,
            attribution: '&copy; <a href="">Volga</a>',
        },
    },
};

export const STATE_DIGGER = {
    FPSCapture: 60,
    reverse: false,
    reverseEvent: false,
    globalTimeMultiplier: 1,
    time: 0,
    useFixedTimes: false,
    loadTime: 1300,
    globalScale: 1,
    scale: 2.5,
    scaleX: 1,
    scaleY: 1,
    scaleZ: 1,
    decay: 1.51,
    speed: 1,
    persistence: 0.7,
    decayShrinkingStart: 10,
    decayShrinkingEnd: 0,
    decayEnlargementStart: 100,
    decayEnlargementEnd: 90,
    randomOffset: 0,
    renderingFPS: 300,
    particlesAccumulationCenterPosX: 0,
    particlesAccumulationCenterPosY: 0,
    particlesAccumulationCenterPosZ: 0,
    particleAccumulationRadius: 0,
    particleAccumulationSmoothingDistance: 0,
    clearThreads: true,
    animationType: 'original',
    useKeyframes: true,
    keyframes: [
        { active: true, t: 0, globalScale: 1, scale: 6, decay: 1.51, speed: 1, persistence: 0.7 },
        {
            active: false,
            t: 0.125,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.25,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.375,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: true,
            t: 0.5,
            globalScale: 1.1,
            scale: 6,
            decay: 1.51,
            speed: 0.5,
            persistence: 0.6,
        },
        {
            active: false,
            t: 0.625,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.75,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.875,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        { active: true, t: 1, globalScale: 1, scale: 6, decay: 1.51, speed: 1, persistence: 0.7 },
    ],
    animate: true,
    rotModel1X: -0.05652,
    rotModel1Y: 0.92944,
    rotModel1Z: 0,
    translateModel1X: 0,
    translateModel1Y: 0,
    translateModel1Z: 0,
    scaleModel1: 1.5,
    rotModel2X: 0,
    rotModel2Y: 0,
    rotModel2Z: 0,
    translateModel2X: 0,
    translateModel2Y: 0,
    translateModel2Z: 0,
    scaleModel2: 1,
    dofOn: false,
    bloomOn: false,
    godRaysOn: false,
    pixelRatio: 1,
    squashiness: 0.56,
    mesh: 0,
    model: 0,
    t: 0,
    easing: 'quarticInOut',
    animationSpeed: 10,
    orbitControls: true,
    timeSpeed: 12.5,
    lighting: 'original',
    useOneMaterialColor: false,
    baseColor: '#123a68',
    baseColor2: '#4f9eb8',
    baseColor3: '#06180e',
    baseColor4: '#c9d8e2',
    roughness: 0.8,
    metallic: 0.071,
    ambientColor: '#07151a',
    ambientIntensity: 0.306,
    effectColor: '#191006',
    postgrey: false,
    baseHue: 0.4,
    baseSaturation: 0,
    baseValue: 1,
    baseHue2: 0.44,
    baseSaturation2: 1,
    baseValue2: 0.6,
    baseHue3: 0.384,
    baseSaturation3: 0,
    baseValue3: 1,
    baseHue4: 0,
    baseSaturation4: 0,
    baseValue4: 1,
    specularStrength: 0,
    effectHue: 0.5,
    effectSaturation: 1,
    effectValue: 1,
    shadowDarkness: 0,
    lightIntensity: 10,
    lightRadius: 400,
    startPosX: 0,
    startPosY: 0,
    startPosZ: 0,
    endPosX: 0,
    endPosY: 0,
    endPosZ: 0,
    clearColor: '#ffffff',
    lightPosX: -153.2732548542354,
    lightPosY: 0,
    lightPosZ: 1994.1181783817874,
    lightRotX: 0,
    lightRotY: 0,
    lightRotZ: 0,
    cameraPosX: 148.4231687993237,
    cameraPosY: 131.5789473685664,
    cameraPosZ: 775,
    rotationMode: 'cameraLightRotation',
    rotTargetPosX: 0,
    rotTargetPosY: 0,
    rotTargetPosZ: 0,
    rotSpeed: 0,
    focus: 1477.05,
    aperture: 0.00001,
    maxblur: 0.007,
    gradientOn: false,
    gradientAngle: 0,
    gradientRotate: false,
    gradientColorTop: '#ffffff',
    gradientColorBottom: '#000000',
    environmentOn: false,
    environmentIntensity: 0.16,
    envRoughness: 0.1,
    exposure: 3.5,
    godRayIntensity: 0.1,
    bgColor: '#000511',
    sunColor: '#ffee00',
    bloomThreshold: 0,
    bloomStrength: 0.284,
    bloomRadius: 0.1,
    lutEnabled: false,
    lut: 'Bourbon 64.CUBE',
    intensity: 1,
    use2DLut: false,
    drawRange: 40000,
    particleTexSize: 256,
    renderShadows: false,
    useSoftShadows: false,
    shadowRadius: 0.2,
    shadowBias: 0.005,
    numOfPoissonDisks: 6,
    renderShadowPerNFrame: 1,
    shadowParticleProportion: 1,
    cameraRotX: -0.1681756356988577,
    cameraRotY: 0.1866149421914925,
    cameraRotZ: 0.031489363380033344,
    forwardRun: false,
    backwardRun: false,
};

export const TRANSFORMS_DIGGER = [
    {
        rotModelX: 0,
        rotModelY: 0,
        rotModelZ: 0,
        translateModelX: 0,
        translateModelY: -10,
        translateModelZ: 0,
        scaleModel: 1.05,
    },
    {
        rotModelX: -0.05652,
        rotModelY: 0.97944,
        rotModelZ: 0,
        translateModelX: 150,
        translateModelY: -15,
        translateModelZ: 0,
        scaleModel: 1.45,
    },
    {
        rotModelX: -0.2,
        rotModelY: 0,
        rotModelZ: 0,
        translateModelX: -25,
        translateModelY: 0,
        translateModelZ: 0,
        scaleModel: 1.2,
    },
];
