<ng-container *transloco="let t">
    <ng-container *ngIf="parts[0] === ''; else tplNumber">
        <div class="sw-vehicle-number sw-vehicle-number_rus sw-vehicle-number--disabled">
            <span class="sw-vehicle-number__noparts">
                {{ t('vehicle.title.noNumber')}}
            </span>
            <span class="sw-vehicle-number__part_3">---</span>
        </div>
    </ng-container>
    <ng-template #tplNumber>
        <div class="sw-vehicle-number sw-vehicle-number_rus">
            <span
                *ngFor="let part of parts; let i = index"
                [ngClass]="[ 'sw-vehicle-number__part_' + i]"
            >{{ part }}</span>
        </div>
    </ng-template>
</ng-container>
