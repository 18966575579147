<div
    class="sw-dummy-page"
    *transloco="let t"
>
    <div class="sw-dummy-page__content">
        <img
            src="/assets/images/dummy.svg"
            alt="background"
        />
        <h2>{{ t('dummy.mobile.h1') }}</h2>
        <p>{{ t('dummy.mobile.description') }}</p>
    </div>
</div>
