<ng-container *transloco="let t">
    <div
        class="sw-notification-item__major-line"
        *ngIf="notification"
    >
        <div class="sw-notification-item__message">
            <span class="sw-notification-item__checkbox">
                <ng-content select="options"></ng-content>
            </span>
            <sw-icon
                class="sw-notification-item__type"
                size="small"
                [icon]="nameIcon"
                [color]="colorIcon"
            ></sw-icon>
            <div class="sw-notification-item__message__content">
                <div
                    [innerHTML]="notification.$snapshot.body | safe: 'html'"
                    [class.sw-more-mute]="!isShortNotification"
                ></div>
                <p
                    *ngIf="!isShortNotification"
                    class="swh-margin-top"
                >
                    <sw-link
                        borderStyle="none"
                        textSize="s_reduced"
                        (click)="openModal(notification.$snapshot.body)"
                    >
                        {{ t('notification.action.more') }}
                    </sw-link>
                </p>
            </div>
        </div>
        <div class="sw-notification-item__datetime">
            <ng-container *ngIf="isToday">
                {{ notification.$snapshot.dt_created | localDate: 'time' }}
            </ng-container>
            <ng-container *ngIf="!isToday">
                {{ notification.$snapshot.dt_created | localDate: 'dd MMMM HH:mm' }}
            </ng-container>
        </div>
        <div
            class="sw-notification-item__action"
            #importantBtn
        >
            <sw-button
                size="small"
                fill="clear"
                [disabled]="isImportant !== notification.$snapshot.is_important"
                (click)="changeImportance(!notification.$snapshot.is_important)"
            >
                <sw-icon
                    slot="icon-only"
                    [name]="notification.$snapshot.is_important ? 'star-solid' : 'star'"
                    [color]="notification.$snapshot.is_important ? 'action' : ''"
                ></sw-icon>
            </sw-button>
            <sw-tooltip
                [reference]="importantBtn"
                [isStatic]="true"
                placement="bottom-end"
                [offset]="[2, 10]"
                class="swh-popper-z-index"
            >
                {{ isImportant ? t('tooltip.importantDelete') : t('tooltip.importantAdd') }}
            </sw-tooltip>
        </div>
        <div
            class="sw-notification-item__action"
            [class.swh-hidden]="notification.$snapshot.state === 'read'"
            #readBtn
        >
            <sw-button
                size="small"
                fill="clear"
                [disabled]="state !== notification.$snapshot.state"
                (click)="changeState('read')"
            >
                <sw-icon
                    slot="icon-only"
                    name="check"
                ></sw-icon>
            </sw-button>
            <sw-tooltip
                [reference]="readBtn"
                [isStatic]="true"
                placement="bottom-end"
                [offset]="[2, 10]"
                class="swh-popper-z-index"
            >
                {{ t('tooltip.notificationIsRead') }}
            </sw-tooltip>
        </div>
    </div>
    <ng-content></ng-content>
</ng-container>
