<ng-container *transloco="let t">
    <div
        class="sw-coordinates"
        *ngIf="point"
    >
        <!-- широта, долгота -->
        <span>
            {{ point.coordinates[1] | localNumber: '1.0-6' }},
            {{ point.coordinates[0] | localNumber: '1.0-6' }}
        </span>
        <div #tooltip>
            <sw-button
                fill="clear"
                color="action"
                (click)="copy($event)"
            >
                <sw-icon
                    slot="icon-only"
                    name="duplicate"
                ></sw-icon>
            </sw-button>
        </div>

        <sw-tooltip
            [reference]="tooltip"
            placement="top"
            [isStatic]="false"
            [watchChange]="true"
            class="swh-popper-z-index"
        >
            {{ t(tooltipKey$ | async, tooltipParams) }}
        </sw-tooltip>
    </div>
</ng-container>
