<ng-container *transloco="let t">
    <p textSize="m" textMedium>
        {{ contact.last_name }}&nbsp;
        {{ contact.first_name }}&nbsp;
        {{ contact.middle_name }}
    </p>
    <p
        class="swh-margin-bottom"
        *ngIf="contact.position"
    >{{ contact.position }}</p>

    <p *ngIf="contact.email">
        <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
    </p>
    <p *ngIf="contact.phone">
        {{ contact.phone }}
        <ng-container *ngIf="contact.phone_additional">
            , {{ t('title.phone_additional') }} {{ contact.phone_additional }}
        </ng-container>
    </p>
</ng-container>
