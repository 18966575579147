import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';

@Component({
    selector: 'app-simple-modal',
    templateUrl: './simple-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleModalComponent implements OnInit {
    @Input()
    message = '';

    @Input()
    titleKey = '';

    constructor(private modalService: ModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeModal() {
        this.modalService.dismiss();
    }
}
