import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable, of, shareReplay, switchMap } from 'rxjs';
import { EntData } from '@shared/models/srv.types';
import { map, tap } from 'rxjs/operators';
import {
    DOCUMENT_LINKS,
    FOOTER_MENU,
    FAQ_TG_CHANNEL_LINK,
    UNDERCONSTRUCTION_TITLE,
    ROLE,
} from '@app/configuration.service';
import { EntityService } from '@shared/services/entity.service';
import { AppService } from '@app/app.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnDestroy, OnChanges {
    @Input()
    contacts: { email: string; phone: string };

    userInfo$;

    userMenu = FOOTER_MENU;

    privacyPolicy = DOCUMENT_LINKS.privacyPolicy;

    userAgreement = DOCUMENT_LINKS.userAgreement;

    sectionUnderConstruction = UNDERCONSTRUCTION_TITLE.section;

    featureUnderConstruction = UNDERCONSTRUCTION_TITLE.feature;

    telegram = FAQ_TG_CHANNEL_LINK;

    role$ = this.appService.role$;

    now = new Date();

    ROLE = ROLE;

    langChanges$ = this.translocoService.langChanges$;

    constructor(
        private entityService: EntityService,
        private appService: AppService,
        public translocoService: TranslocoService,
    ) {}

    ngOnChanges(): void {
        this.userInfo$ = this.appService.user$.pipe(
            map((userInfo: EntData) => (userInfo ? userInfo.$snapshot : null)),
            shareReplay(),
        );
    }

    ngOnDestroy(): void {}
}
