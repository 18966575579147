import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements AfterViewInit {
    // TODO: пределать в переиспольуземый web-component, установка класса детям (item)
    // TODO: подумать о динамической ширине, что-то похожее реализовано в tabs-slider
    @ViewChild('sliderContent', { static: true }) set contentWrapEl(el: ElementRef) {
        if (!el) return;

        this.wrapper = el.nativeElement;
    }

    // количество итемов
    @Input()
    count = 10;

    // ширина итема
    @Input()
    width = 271;

    // отступ между итемами
    @Input()
    offset = 8;

    @Input()
    iconPrev: string = 'arrow-left';

    @Input()
    iconNext: string = 'arrow-right';

    @Input()
    typeButton: 'solid' | 'clear' | 'outline' = 'outline';

    @Input()
    sizeButton: 'xs' | 'small' | 'default' | 'large' = 'default';

    @Output()
    resized = new EventEmitter();

    wrapper;

    current = 0;

    margin = 0;

    isEndSlider = false;

    constructor() {}

    ngAfterViewInit(): void {
        this.marginCorrect();
    }

    slide(step) {
        const next = this.current + step;
        this.current = next < 0 ? 0 : next < this.count ? next : this.count - 1;
        this.marginCorrect();
    }

    marginCorrect() {
        const total = this.wrapper.children.length;
        const fullWidth = this.width * total + this.offset * (total - 1);
        const parentWidth = this.wrapper.parentNode.clientWidth;

        // console.log('[GALLERY]', total, 'fullWidth: ', fullWidth, 'parentWidth: ', parentWidth);

        this.margin = -this.width * this.current;

        if (this.margin <= parentWidth - fullWidth) {
            this.margin = parentWidth - fullWidth;
            this.isEndSlider = true;
        } else {
            this.isEndSlider = false;
        }
        if (this.margin > 0) this.margin = 0;
        this.resized.emit(true);
    }
}
