import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService, TOAST_GROUPS } from '@shared/services/toast.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy, OnInit {
    groupNames = TOAST_GROUPS;

    currentGroups$ = this.toastService.toastGroups.$.pipe(
        map((groups) => {
            return this.groupNames.filter((group) => groups[group].length);
        }),
    );

    constructor(public toastService: ToastService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    dismiss(id, group) {
        this.toastService.dismiss(id, group);
    }
}
