import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import { ConfigurationService } from '@app/configuration.service';

@Pipe({
    name: 'enum',
})
export class EnumPipe implements PipeTransform {
    constructor(private entity: EntityService, private config: ConfigurationService) {}

    transform(enumValue: string, type: string, property: string) {
        type = type.replace('.', '__');
        const attribute = this.config.schemas[`${type}__schema_retrieve`].attributes[property];
        return enumValue ? attribute.enumDict[enumValue] : null;
    }
}
