import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, shareReplay } from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import {
    combineLatestWith,
    debounceTime,
    filter,
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import { EntData, EntList, SearchSettings } from '@shared/models/srv.types';
import { DEFAULT_LIST_LIMIT } from '@shared/services/srv.service';

@Component({
    selector: 'app-infinite-list',
    templateUrl: './infinite-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListComponent implements OnDestroy, OnInit {
    @Input() searchSettings: SearchSettings;

    @Input() search$: Observable<string>;

    @Input() limit = 20;

    @Input() searchDebounce: number = 200;

    @Input() emptyValue;

    @Input() emptyListKey;

    @Input() excludeIds$: Observable<string[]> = new BehaviorSubject([]);

    @ContentChild(TemplateRef) itemTpl: TemplateRef<any>;

    list = [];

    isLoading = false;

    needNextPage$ = new BehaviorSubject(false);

    listAll$: Observable<any[]>;

    list$: Observable<any[]>;

    countListAll: number;

    countPortionLoad: number;

    currentPortionLoad: number;

    searchUpdated$: Observable<string>;

    constructor(protected entityService: EntityService) {}

    ngOnInit(): void {
        if (!this.excludeIds$) this.excludeIds$ = new BehaviorSubject([]);
        this.list = this.generateInitialList();
        if (!this.search$) {
            this.search$ = new BehaviorSubject('');
        }

        this.searchUpdated$ = this.search$.pipe(
            startWith(''),
            tap(() => {
                this.isLoading = false;
                this.list = this.generateInitialList();
            }),
            shareReplay(),
        );

        this.listAll$ = merge(this.needNextPage$, this.searchUpdated$).pipe(
            filter(() => this.searchSettings && !this.isLoading),
            combineLatestWith(this.searchUpdated$),
            debounceTime(this.searchDebounce),
            switchMap(([init, search]) => {
                this.isLoading = true;

                const params = this.searchSettings.paramsGenerator
                    ? this.searchSettings.paramsGenerator(search)
                    : this.searchSettings.params;

                search = this.searchSettings.paramsGenerator ? '' : search;

                return this.entityService.getEntityList$(
                    this.searchSettings.entityType,
                    this.searchSettings.suffix || '',
                    search || '',
                    params || {},
                    this.emptyValue ? this.list.length - 1 : this.list.length,
                    this.searchSettings.limit || DEFAULT_LIST_LIMIT,
                );
            }),
            tap((response) => {
                this.countListAll = response.$meta?.pagination.count;
                this.list = this.list.concat(response.list);
                this.isLoading = false;
            }),
            map(() => this.list),
        );

        this.list$ = combineLatest([this.listAll$, this.excludeIds$]).pipe(
            map(([list, excludeIds]) => {
                if (!excludeIds || excludeIds.length === 0) return list;
                return list.filter((v) => excludeIds.indexOf(v.id) === -1);
            }),
            tap((list) => {
                this.countPortionLoad = Math.ceil(this.countListAll / this.limit);
                this.currentPortionLoad = Math.ceil(list.length / this.limit);
            }),
            shareReplay(),
        );
        this.needNextPage$.next(true);
    }

    generateInitialList() {
        return this.emptyValue ? [this.emptyValue] : [];
    }

    ngOnDestroy(): void {}

    trackById(index, item) {
        return item.id;
    }
}
