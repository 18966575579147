<div class="sw-slider">
    <div class="swh-overhide">
        <div
            #sliderContent
            class="sw-slider__content"
            [style.margin-left]="margin + 'px'"
            swipe
            (swipeLeft)="slide(1)"
            (swipeRight)="slide(-1)"
        >
            <ng-content></ng-content>
        </div>
    </div>
    <div class="sw-slider__controls"
        [hidden]="current === 0 && isEndSlider"
    >
        <sw-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="sw-slider__prev"
            [disabled]="current === 0"
            (click)="slide(-1)"
        >
            <sw-icon slot="icon-only" [icon]="iconPrev"></sw-icon>
        </sw-button>
        <sw-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="sw-slider__next"
            [disabled]="isEndSlider"
            (click)="slide(1)"
        >
            <sw-icon slot="icon-only" [icon]="iconNext"></sw-icon>
        </sw-button>
    </div>
</div>
