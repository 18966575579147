import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfiniteListComponent } from '@shared/components/infinite-list.component';
import { EntData } from '@shared/models/srv.types';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-infinite-list-sort-selected',
    templateUrl: './infinite-list-sort-selected.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListSortSelectedComponent extends InfiniteListComponent {
    @Input() selectedList$: Observable<EntData[]>;

    @Input() selectedMap: Map<any, boolean> = new Map([]);

    @Input() isMultiple: boolean = false;

    @Input() hasApplyBtn: boolean = false;

    @Output() cleared = new EventEmitter();

    @Output() applied = new EventEmitter();

    showSelectedGroup: boolean = true;

    showSearchGroup: boolean = true;

    toggleSelectedGroup() {
        this.showSelectedGroup = !this.showSelectedGroup;
    }

    toggleSearchGroup() {
        this.showSearchGroup = !this.showSearchGroup;
    }

    clearAll() {
        this.cleared.emit();
    }

    applyAll() {
        this.applied.emit();
    }
}
