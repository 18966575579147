import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import * as THREE from 'three';
import ParticleFactory from '@assets/js/model.min';
import { BehaviorSubject } from 'rxjs';
import { STATE_DIGGER, TRANSFORMS_DIGGER } from '@app/const-dev';

@Component({
    selector: 'app-webgl-digger',
    template: '',
})
export class WebglDiggerComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() inViewport$ = new BehaviorSubject(false);

    private worldTex = '/assets/images/textures/world.png';

    private digger = '/assets/images/textures/digger.png';

    private logo = '/assets/images/textures/logo.png';

    private params = STATE_DIGGER;

    private transforms = TRANSFORMS_DIGGER;

    private textures = [this.worldTex, this.digger, this.logo];

    private readonly factory = new ParticleFactory({ THREE });

    private k = 1;

    private readonly m = 3;

    private readonly particles = this.factory
        .produce(this.params, {
            // width: '448px',
            // height: '448px',
            // left: '0px',
            // top: '0px',
            // position: 'absolute',
        })
        .buildModel1(this.textures[(this.m + this.k) % this.m])
        .buildModel2(this.textures[(this.m + (this.k + 1)) % this.m])
        .buildInteractive();

    private isAnimate: boolean = false;

    constructor(private renderer: Renderer2, private hostEl: ElementRef) {}

    ngOnInit(): void {
        this.inViewport$.subscribe((vp) => {
            if (vp && !this.isAnimate) {
                this.factory.animate();
                this.isAnimate = true;

                // console.log('animate');
            }

            if (vp && this.particles.stopR) {
                // console.log('stopRunRender 1');
                this.particles.stopRunRender();
            }

            if (!vp && !this.particles.stopR) {
                // console.log('stopRunRender 2');
                this.particles.stopRunRender();
            }
        });
    }

    ngAfterViewInit(): void {
        this.createScene();
    }

    ngOnDestroy(): void {
        this.factory.destroy();
    }

    private createScene() {
        this.particles.setModel1Transforms(this.transforms[(this.m + this.k) % this.m]);
        this.particles.setModel2Transforms(this.transforms[(this.m + (this.k + 1)) % this.m]);

        this.renderer.appendChild(this.hostEl.nativeElement, this.particles.container);

        this.particles.setForwardCallbackBefore((global) => {
            global.state.t = 0;
            global.buildModel1(this.textures[(this.m + this.k) % this.m]);
            global.setModel1Transforms(this.transforms[(this.m + this.k) % this.m]);

            global.buildModel2(this.textures[(this.m + (this.k + 1)) % this.m]);
            global.setModel2Transforms(this.transforms[(this.m + (this.k + 1)) % this.m]);

            this.k++;
            this.k = (this.m + this.k) % this.m;
        });

        this.particles.setBackwardCallbackBefore((global) => {
            global.state.t = 1;
            global.buildModel2(this.textures[(this.m + this.k) % this.m]);
            global.setModel2Transforms(this.transforms[(this.m + this.k) % this.m]);

            global.buildModel1(this.textures[(this.m + (this.k - 1)) % this.m]);
            global.setModel1Transforms(this.transforms[(this.m + (this.k - 1)) % this.m]);

            this.k--;
            this.k = (this.m + this.k) % this.m;
        });

        this.particles.renderer.domElement.addEventListener('click', () => {
            this.particles.backwardAnimation();
        });

        // this.factory.animate();
    }
}
