import { Injectable } from '@angular/core';
import { combineLatest, fromEvent, mergeMap, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { SrvService } from '@shared/services/srv.service';
import { LoaderService } from '@shared/services/loader.service';
import { ToastService } from '@shared/services/toast.service';
import { ModalService } from '@shared/services/modal.service';
import { EntData, EntDocumentSettings } from '@shared/models/srv.types';
import { TemplateObservable } from '@shared/classes/template-observable';
import { SigningComponent } from '@crypto/components/signing/signing.component';
import { EntityService } from '@shared/services/entity.service';
import { HttpClient } from '@angular/common/http';
import { sign } from 'three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(
        private srv: SrvService,
        private http: HttpClient,
        private loaderService: LoaderService,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private entityService: EntityService,
    ) {}

    createGroupToSignBySettings(
        settings: EntDocumentSettings[],
    ): Observable<EntDocumentSettings[]> {
        const documentSettings = new TemplateObservable(settings);
        this.openModalToSignDocuments(documentSettings);
        return documentSettings.$;
    }

    getSignDocument$(
        entityType: string,
        entityId: string,
        contextLabel: string = null,
        documentPayload: any = {},
        cancelPreviousSignings: boolean = false,
    ): Observable<EntData> {
        return cancelPreviousSignings
            ? this.srv.cancelEntityDocumentSignings$(entityType, entityId, contextLabel).pipe(
                  switchMap(() => this.entityService.getEntity$(entityType, entityId, true)),
                  switchMap(() =>
                      this.srv.fetchEntityDocument$(
                          entityType,
                          entityId,
                          contextLabel,
                          documentPayload,
                      ),
                  ),
              )
            : this.srv.fetchEntityDocument$(entityType, entityId, contextLabel, documentPayload);
    }

    signEntityDocument$(settings: EntDocumentSettings, signature, signBodyPayload = null) {
        return this.srv.signEntity$(settings, signature, signBodyPayload).pipe(
            mergeMap(() =>
                this.entityService.getEntity$(settings.entityType, settings.entityId, true),
            ),
            take(1),
        );
    }

    signEntityDocuments$(
        signSettings: { settings: EntDocumentSettings; signature; signBodyPayload }[],
    ) {
        return this.srv.signEntities$(signSettings).pipe(
            mergeMap(() => {
                const entities = [];
                signSettings.forEach((setting) => {
                    if (
                        !entities.find(
                            (entity) =>
                                entity.entityType === setting.settings.entityType &&
                                entity.entityId === setting.settings.entityId,
                        )
                    ) {
                        entities.push(setting.settings);
                    }
                });
                return entities.length > 0
                    ? combineLatest([
                          ...entities.map((entity) =>
                              this.entityService.getEntity$(
                                  entity.entityType,
                                  entity.entityId,
                                  true,
                              ),
                          ),
                      ])
                    : of([]);
            }),
            filter((items) => items.filter((v) => !!v).length === items.length),
            catchError((e) => {
                this.modalService.dismiss();
                throw e;
            }),
        );
    }

    openModalToSignDocuments(settings: TemplateObservable<EntDocumentSettings[]>) {
        const modal = this.modalService.create({
            component: SigningComponent,
            componentProperties: {
                itemsToSign: settings,
            },
            // hasCloseIcon: false,
            // backdropClose: false,
            // hasBackdrop: false,
            // keyboardClose: false,
            // title: 'crypto.docsSigning',
            cssClass: 'sw-modal_signing',
        });

        modal
            .onDidDismiss()
            .then((response) => {
                if (!response) return null;
                const remainToSign = response.signed
                    ? settings._.filter(
                          (entity) =>
                              !response.signed[
                                  `${entity.entityType}-${entity.entityId}-${
                                      entity.contextLabel || ''
                                  }`
                              ],
                      )
                    : settings._;
                //settings.set(remainToSign);
                settings.set(remainToSign.length > 0 ? null : remainToSign);
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getFileContentToSign(url) {
        return this.http
            .get(url, {
                responseType: 'blob',
            })
            .pipe(
                mergeMap((blob: any) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return fromEvent(reader, 'load').pipe(
                        map((event) => {
                            let header = ';base64,';
                            let sFileData = reader.result as string;
                            return sFileData.substr(sFileData.indexOf(header) + header.length);
                        }),
                        take(1),
                    );
                }),
            );
    }

    static generateSignDocumentsMap(signDocuments: any[]) {
        const result = {};
        signDocuments.forEach((document) => {
            result[document.id] = {
                ...document,
                attributes: {
                    ...document.attributes,
                },
            };
        });
        return result;
    }

    static generateAvailiableDocuments(documents: EntData[], signDocuments: []) {
        const signTargetsMap = DocumentService.generateSignDocumentsMap(signDocuments);
        return documents.filter(
            (document) =>
                signTargetsMap[document.$snapshot.id] &&
                signTargetsMap[document.$snapshot.id].attributes.signatories.length > 0,
        );
    }
}
