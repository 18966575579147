import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';
import { TooltipElement } from './tooltip.component';

@NgModule({
    declarations: [TooltipElement],
    imports: [CommonModule, IconModule],
    exports: [TooltipElement],
})
export class TooltipModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TooltipElement, {
            injector: this.injector,
        });

        customElements.define('sw-tooltip', el);
    }
}
