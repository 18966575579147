import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntDescription } from '@shared/models/srv.types';
import { MOBILE_ROLES } from '@app/configuration.service';

@Component({
    selector: 'app-role-menu-item',
    templateUrl: './role-menu-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleMenuItemComponent {
    @Input()
    menuItem = {
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
    };

    @Input()
    role: EntDescription;

    @Input()
    currentUserRole;

    @Output()
    select = new EventEmitter();

    mobileRoles = MOBILE_ROLES;
}
