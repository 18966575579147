<ng-container *transloco="let t">
    <ul class="sw-scheduler sw-list sw-list_underline sw-list_between">
        <li
            *ngFor="let dayOfWeek of daysOfWeek"
            class="sw-item"
        >
            <div>
                <span
                    class="sw-scheduler__day sw-text_secondary"
                    textSize="minicaps"
                >
                    {{ t('weekDays.short.' + dayOfWeek) }}
                </span>
                <span
                    *ngIf="
                        !schedule ||
                        !schedule[dayOfWeek] ||
                        !(schedule[dayOfWeek].work.from | scheduleTime) ||
                        !schedule[dayOfWeek].work.to
                    "
                    class="sw-text_secondary"
                >
                    {{ t('title.dayOff.short') }}
                </span>
                <span *ngIf="schedule && schedule[dayOfWeek]?.work">
                    {{ schedule[dayOfWeek].work.from | scheduleTime }} -
                    {{ schedule[dayOfWeek].work.to | scheduleTime }}
                </span>
            </div>
            <span
                *ngIf="schedule && schedule[dayOfWeek]?.rest"
                class="sw-text_secondary"
                textSize="xs"
            >
                <ng-container *ngIf="schedule[dayOfWeek].rest.from && schedule[dayOfWeek].rest.to">
                    {{ t('title.sheduleBreak') }}:
                    {{ schedule[dayOfWeek].rest.from | scheduleTime }} -
                    {{ schedule[dayOfWeek].rest.to | scheduleTime }}
                </ng-container>
            </span>
        </li>
    </ul>
</ng-container>
