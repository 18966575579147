import { Injectable, Inject } from '@angular/core';
import { EntData, EntList } from '@shared/models/srv.types';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { SrvService } from '@shared/services/srv.service';
import { Router } from '@angular/router';
import { ROLE, SHARE_REPLAY_SETTINGS } from './configuration.service';
import { ToastService } from '@shared/services/toast.service';
import { TemplateObservable } from '@shared/classes/template-observable';
import {
    BehaviorSubject,
    fromEvent,
    merge,
    Observable,
    of,
    share,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import { StorageService } from '@shared/services/storage.service';
import { EntityService } from '@shared/services/entity.service';
import { AuthService } from '@shared/services/auth.service';
import { WebSocketService } from '@shared/services/web-socket.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    user$: Observable<EntData> = this.authService.user$;

    userId$: Observable<string> = this.authService.userId$;

    role$: Observable<string> = this.authService.role$;

    accountType$ = this.authService.accountType$;

    organizationId$ = this.authService.organizationId$;

    organizationInfo$ = this.authService.organizationInfo$;

    organizationInfoId$: Observable<string> = this.authService.organizationInfoId$;

    agencyContract$ = this.authService.agencyContract$;

    systemNotifications$ = fromEvent(window, 'storage').pipe(
        filter((event: any) => event.key === 'event' && event.newValue),
        withLatestFrom(this.authService.userId$),
        tap(([event, userId]) => {
            if (!event.newValue || !userId) return;
            let value = null;
            try {
                value = JSON.parse(event.newValue);
            } catch {
                console.log('[DEV][ERROR] invalid event data');
            }
            if (!value) return;

            if (value.eventName === 'logout') {
                this.authService.afterLogout();
                this.authService.redirectToHomePage();
            }
            if (value.eventName === 'roleChanged') {
                this.authService.needUpdateUser('socket');
                this.authService.redirectToHomePage();
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );
    // TODO: remove when #793 task will be marked as done
    // systemNotifications$ = this.socketService.message$.pipe(
    //     filter((v) => v && v?.$snapshot?.notice_type === 'notice.system'),
    //     withLatestFrom(this.authService.token.$),
    //     map(([message, token]) => {
    //         if (
    //             message.$snapshot.title === 'user logout event' &&
    //             !this.authService.isLogouting &&
    //             token
    //         ) {
    //             this.authService.afterLogout();
    //             this.authService.redirectToHomePage();
    //         }
    //
    //         if (message.$snapshot.title === 'role_current changed') {
    //             this.authService.needUpdateUser('socket');
    //             this.authService.redirectToHomePage();
    //         }
    //     }),
    //     shareReplay(SHARE_REPLAY_SETTINGS),
    // );

    constructor(
        private http: HttpClient,
        private toast: ToastService,
        private srv: SrvService,
        private router: Router,
        private storageService: StorageService,
        private entityService: EntityService,
        private authService: AuthService,
        private socketService: WebSocketService,
        @Inject(DOCUMENT) readonly document: Document,
    ) {
        this.systemNotifications$.subscribe();
    }
}
