<div
    *transloco="let t"
    class="sw-modal-wrap"
>
    <div
        *ngIf="titleKey"
        class="sw-modal__header"
    >
        <h1>{{ t('titleKey') }}</h1>
    </div>
    <div
        class="sw-modal__content sw-cckeditor"
        [innerHTML]="message"
    ></div>
    <div class="sw-modal__footer">
        <sw-toolbar>
            <sw-button
                slot="end"
                color="action"
                (click)="closeModal()"
            >
                {{ t('action.close') }}
            </sw-button>
        </sw-toolbar>
    </div>
</div>
