<div
    sticky
    [stickyTop]="50"
    class="sw-list sw-list_checkable"
>
    <div
        class="sw-item"
        *ngFor="let item of [].constructor(10)"
    >
        <sw-skeleton [animated]="true"></sw-skeleton>
    </div>
</div>
