<ng-container *transloco="let t">
    <div>
        <p textSize="l" textMedium>{{ t('crypto.info.title') }}</p>
        <p textSize="m" [innerHTML]="t('crypto.info.description')"></p>
        <sw-button
            class="swh-margin-top"
            coef="6"
            color="action"
            href="https://www.cryptopro.ru/products/cades/plugin"
            target="_blank"
        >
            {{ t('crypto.info.btn') }}
        </sw-button>
    </div>
</ng-container>
