import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NotificationService } from '@shared/services/notification.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-blocking-notifications',
    templateUrl: 'blocking-notifications.component.html',
    styles: [':host {display: contents}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockingNotificationsComponent implements OnDestroy, OnInit {
    @ViewChild('blockingNotifications', { static: false }) set blockingNotifications(
        el: ElementRef,
    ) {
        this.menuBurgerEl = document.querySelector('#menuBurger');
        if (!el && !this.menuBurgerEl) return;

        this.blockEl = el.nativeElement;

        if (this.menuBurgerEl) {
            this.renderer.setStyle(
                this.menuBurgerEl,
                'top',
                (this.blockEl.offsetHeight + this.paddingTop).toString() + 'px',
            );

            this.scrollListener = this.renderer.listen(window, 'scroll', (event) => {
                this.setTopPositionMenuBurger();
            });

            this._resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => this.setTopPositionMenuBurger());
            });
            this._resizeObserver.observe(this.blockEl);
        }
    }

    paddingTop = 8;

    blockEl;

    menuBurgerEl;

    _resizeObserver;

    scrollListener = () => {};

    constructor(
        public notificationService: NotificationService,
        public announcementService: AnnouncementService,
        private renderer: Renderer2,
        public viewportScroller: ViewportScroller,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.scrollListener();
        if (this._resizeObserver) this._resizeObserver.unobserve(this.blockEl);
    }

    changeNotificationState(id, state) {
        this.notificationService.changeState([id], state);
    }

    changeAnnouncementState(announcement) {
        this.announcementService.readAnnouncementNumber$.next(announcement.$snapshot.number);
    }

    setTopPositionMenuBurger() {
        if (this.menuBurgerEl) {
            this.renderer.setStyle(
                this.menuBurgerEl,
                'top',
                this.viewportScroller.getScrollPosition()[1] > this.blockEl.offsetHeight
                    ? this.paddingTop.toString() + 'px'
                    : (
                          this.blockEl.offsetHeight +
                          this.paddingTop -
                          this.viewportScroller.getScrollPosition()[1]
                      ).toString() + 'px',
            );
        }
    }
}
