import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { LoaderService } from '@shared/services/loader.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    assembly = environment;

    constructor(
        public app: AppService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbs: BreadcrumbsService,
        private loaderService: LoaderService,
    ) {
        const loader = this.loaderService.create({});
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.breadcrumbs.createBreadcrumbs(this.activatedRoute.root);
                this.loaderService.dismiss(loader);
            });
        this.setDocTitle('Smart Waste');
    }

    setDocTitle(title: string) {
        this.titleService.setTitle(title);
    }
}
