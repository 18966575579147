<div
    *transloco="let t"
    class="sw-notification-block"
>
    <header>
        <sw-toolbar>
            <div slot="start">
                <span
                    textMedium
                    textSize="s"
                >
                    {{
                        notifications.length === 0
                            ? t('notification.title.entity')
                            : t('count.notification.unread', {
                                  pluralType: notifications.length | plural,
                                  count: notifications.length
                              })
                    }}
                </span>
            </div>
            <div
                slot="end"
                class="sw-row-elements swh-align-items-center"
            >
                <span
                    textSize="s_reduced"
                    color="action"
                    class="swh-pointer"
                    (click)="readAll(notifications)"
                >
                    {{ t('notification.title.readAll') }}
                </span>
                <sw-button
                    fill="clear"
                    color="action"
                    routerLink="/profile/settings"
                >
                    <sw-icon
                        slot="icon-only"
                        name="settings"
                    ></sw-icon>
                </sw-button>
            </div>
        </sw-toolbar>
    </header>
    <div>
        <hr />
        <!-- Если нет уведомлений -->
        <app-empty
            *ngIf="notifications.length === 0"
            [messageKey]="'notification.title.empty'"
            cssClass="sw-empty_notifications"
            pathImg="/assets/images/empty_content.svg"
        ></app-empty>

        <!-- Если есть уведомления -->
        <div
            *ngIf="notifications.length > 0"
            class="sw-notification-block__body"
        >
            <app-notification-item
                *ngFor="let notification of notifications"
                [notification]="notification"
                (stateChanged)="changeState([notification.id], $event)"
                (importanceChanged)="changeImportance(notification.id, $event)"
                class="sw-notification-item"
            ></app-notification-item>
            <!--            <app-notification-item-->
            <!--                class="sw-notification-item"-->
            <!--                [typeMessage]="'success'"-->
            <!--                [message]="'Подобран перевозчик по позиции <a href>№ 12</a>'"-->
            <!--            >-->
            <!--                <div class="sw-row-elements swh-flex">-->
            <!--                    <sw-button color="action">-->
            <!--                        {{ t('action.signContract') }}-->
            <!--                    </sw-button>-->
            <!--                    <sw-button-->
            <!--                        fill="outline"-->
            <!--                        color="action"-->
            <!--                    >-->
            <!--                        {{ t('action.detail') }}-->
            <!--                    </sw-button>-->
            <!--                </div>-->
            <!--            </app-notification-item>-->
        </div>
        <hr />
    </div>
    <footer>
        <sw-link
            borderStyle="none"
            textSize="s_reduced"
            [routerLink]="'/profile/notifications'"
        >
            {{ t('notification.action.seeAll') }}
        </sw-link>
    </footer>
</div>

<ng-container *ngIf="notificationService.notificationsFromSocket$ | async"></ng-container>
