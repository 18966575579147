import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-default-loader',
    templateUrl: './default-loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultLoaderComponent implements OnInit {
    @Input() message: string = '';

    @Input() theme: 'light' | 'dark' | 'transparent' = 'light';

    constructor() {}

    ngOnInit(): void {}
}
