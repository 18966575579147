<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            notifications: notificationService.blockingNotifications$ | async,
            announcement: announcementService.topAnnouncement$ | async
        } as data"
    >
        <div #blockingNotifications>
            <ng-container *ngIf="data.notifications">
                <div
                    *ngIf="data.notifications.length"
                    [color]="'warning'"
                    class="sw-message-plane"
                >
                    <div
                        *ngFor="let notification of data.notifications"
                        class="sw-message-plane__item"
                    >
                        <div
                            class="swh-overhide swh-text-center"
                            [innerHTML]="notification.$snapshot.body | safe: 'html'"
                        ></div>
                        <sw-button
                            fill="clear"
                            class="sw-message-plane__close"
                            (click)="changeNotificationState(notification.id, 'read')"
                        >
                            <sw-icon
                                slot="icon-only"
                                name="cross"
                            ></sw-icon>
                        </sw-button>
                    </div>
                </div>
            </ng-container>
            <div
                *ngIf="data.announcement"
                [color]="'warning'"
                class="sw-message-plane"
            >
                <div class="sw-message-plane__item">
                    <div
                        class="swh-overhide swh-text-center"
                        [innerHTML]="data.announcement.$snapshot.body | safe: 'html'"
                    ></div>
                    <sw-button
                        fill="clear"
                        class="sw-message-plane__close"
                        (click)="changeAnnouncementState(data.announcement)"
                    >
                        {{ t('action.familiarized') }}
                    </sw-button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
