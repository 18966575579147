import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormSavingStatus } from '@form/models/form.types';

@Component({
    selector: 'app-entity-saving-status',
    templateUrl: './entity-saving-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySavingStatusComponent implements OnDestroy, OnInit {
    @Input()
    status$: Observable<FormSavingStatus>;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
