
<div class="sw-timepicker">
    <div class="sw-timepicker__content">
        <div class="sw-timepicker__scoreboard">
            <div class="sw-scoreboard__content">

                <div class="sw-scoreboard__item">
                    <sw-button class="sw-scoreboard__btn" fill="clear"
                        (click)="stepNextHour()"
                    >
                        <sw-icon slot="icon-only" name="angle-small-up"></sw-icon>
                    </sw-button>
                    <input type="text" class="sw-item__value" 
                        [class.invalid]="value && hoursInputControl.invalid" 
                        [formControl]="hoursInputControl" 
                        (input)="handleHoursBlur()"
                        (click)="checkHoursAndEmit()"
                        placeholder="--"
                    />
                    <sw-button class="sw-scoreboard__btn" fill="clear"
                        (click)="stepPrevHour()"
                    >
                        <sw-icon slot="icon-only" name="angle-small-down"></sw-icon>
                    </sw-button>
                </div>

                <div class="sw-scoreboard__item sw-scoreboard__item_delimiter">
                    <div class="sw-item__value">:</div>
                </div>

                <div class="sw-scoreboard__item">
                    <sw-button class="sw-scoreboard__btn" fill="clear"
                        (click)="stepNextMinute()"
                    >
                        <sw-icon slot="icon-only" name="angle-small-up"></sw-icon>
                    </sw-button>
                    <input type="text" class="sw-item__value" 
                        [class.invalid]="value && minutesInputControl.invalid" 
                        [formControl]="minutesInputControl" 
                        (input)="handleMinutesBlur()" 
                        (click)="checkMinutesAndEmit()"
                        placeholder="--"
                    />
                    <sw-button class="sw-scoreboard__btn" fill="clear"
                        (click)="stepPrevMinute()"
                    >
                        <sw-icon slot="icon-only" name="angle-small-down"></sw-icon>
                    </sw-button>
                </div>

            </div>
        </div>
<!-- 
        <div class="sw-calendar__foot" *ngIf="additionalBtns">
            <div class="sw-btn-group">
                <button class="sw-calendar__btn" type="button"
                    *ngFor="let additionalBtn of additionalBtns"
                    (click)="additionalBtn.clickFn()"
                >{{ additionalBtn.txt }}</button>
            </div>
        </div> -->
    </div>
</div>
