import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { shareReplay } from 'rxjs';
import { EntData } from '@shared/models/srv.types';
import { map, tap } from 'rxjs/operators';
import {
    HEADER_MENU,
    SHARE_REPLAY_SETTINGS,
    USER_MENU,
    UNDERCONSTRUCTION_TITLE,
    DEFAULT_LINK_ACTIVE_MATCH_OPTIONS,
} from '@app/configuration.service';
import { EntityService } from '@shared/services/entity.service';
import { TranslocoService } from '@ngneat/transloco';
import { LocaleService } from '@shared/services/locale.service';
import { AppService } from '@app/app.service';
import { PaymentService } from '@shared/services/payment.service';
import { environment } from '@environments/environment';
import { NotificationService } from '@shared/services/notification.service';
import { Instance, createPopper, preventOverflow } from '@popperjs/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {
    @Input()
    cssClass: string;

    @Output()
    changeRole = new EventEmitter();

    @ViewChild('triggerNotifications') set triggerNotifications(el: ElementRef) {
        if (!el) return;

        this.triggerNotificationsEl = el.nativeElement;
    }

    @ViewChild('blockNotifications') set blockNotifications(el: ElementRef) {
        if (!el) return;

        this.blockNotificationsEl = el.nativeElement;

        setTimeout(() => {
            const reference = this.triggerNotificationsEl;
            const popper = this.blockNotificationsEl;

            if (reference && popper) {
                this.popperInstance = createPopper(reference, popper, {
                    placement: 'bottom',
                    modifiers: [
                        preventOverflow,
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -8],
                            },
                        },
                    ],
                });
            }
        });
    }

    @ViewChild('visibleMenuEl') visibleMenuEl: ElementRef;

    @ViewChild('hiddenMenuEl') hiddenMenuEl: ElementRef;

    @ViewChild('userMenuEl') set userMenuEl(el: ElementRef) {
        if (!el) return;
        this.userMenuElement = el.nativeElement;
    }

    @ViewChild('moreEl') moreEl: ElementRef;

    @ViewChild('headerWrap') set headerWrapEl(el: ElementRef) {
        if (!el) return;

        this.headerWrap = el.nativeElement;
    }

    @HostListener('window:resize') onResize() {
        if (this.headerWrap?.offsetWidth >= this.minWindowWidthForMore && this.userMenuElement) {
            setTimeout(() => {
                this.updateNav();
            }, 100);
        }
    }

    activeOptions = DEFAULT_LINK_ACTIVE_MATCH_OPTIONS;

    readonly minWindowWidthForMore = 912;

    userInfo$;

    organizationInfo$;

    userMenu = USER_MENU;

    headerMenu = HEADER_MENU;

    featureUnderConstruction = UNDERCONSTRUCTION_TITLE.feature;

    breaks = [];

    headerWrap;

    userMenuElement;

    userMenuWidth;

    activeLanguage;

    role$ = this.appService.role$;

    paymentAccount$ = this.paymentService.paymentAccount$.pipe(
        tap(() => {
            this.onResize();
        }),
    );

    assembly = environment;

    popperInstance: Instance;

    triggerNotificationsEl;

    blockNotificationsEl;

    constructor(
        public renderer: Renderer2,
        private entityService: EntityService,
        private localeService: LocaleService,
        public appService: AppService,
        private paymentService: PaymentService,
        private translocoService: TranslocoService,
        public notificationService: NotificationService,
        private router: Router,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.activeLanguage = this.translocoService.getActiveLang();
        this.userInfo$ = this.appService.user$.pipe(
            map((userInfo: EntData) => {
                if (!userInfo) return null;
                const info = userInfo.$snapshot;
                const firstName = info.first_name ? info.first_name[0] : '';
                const lastName = info.last_name ? info.last_name[0] : '';
                return {
                    ...info,
                    shortName: `${firstName}${lastName}`.toUpperCase(),
                };
            }),
            tap(() => {
                this.onResize();
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        this.organizationInfo$ = this.appService.organizationInfo$.pipe(
            map((organizationInfo) => {
                return {
                    name: organizationInfo ? `${organizationInfo?.$snapshot.name_full}` : '',
                };
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.onResize();
    }

    ngOnDestroy() {
        if (this.popperInstance) this.popperInstance.destroy();
    }

    selectRole(roleId, userId) {
        this.changeRole.emit({ roleId, userId });
    }

    updateNav() {
        this.userMenuWidth = this.userMenuElement.offsetWidth + 10; // добавим отступ
        const header = this.headerWrap;
        const logoWidth = 110 + 44; // ширина картинки + отступ
        const more = this.moreEl?.nativeElement;
        const menu = this.visibleMenuEl.nativeElement;
        const hiddenMenu = this.hiddenMenuEl?.nativeElement;

        // there might be users without menu at all
        if (!more) return;

        const availableSpace = header.offsetWidth - logoWidth - this.userMenuWidth;
        // The visible list is overflowing the nav
        if (menu.offsetWidth > availableSpace) {
            // Show the dropdown btn
            if (more.classList.contains('swh-hide')) {
                this.renderer.removeClass(more, 'swh-hide');
            }

            const items = menu.querySelectorAll('.sw-header__menu__item:not(.swh-hide)');
            if (items.length > 1) {
                // Record the width of the list
                this.breaks.push(menu.offsetWidth);
                // Move item to the hidden list
                this.renderer.addClass(items[items.length - 1], 'swh-hide');

                const hiddenItems = hiddenMenu.querySelectorAll('.hidden-item.swh-hide');
                this.renderer.removeClass(hiddenItems[hiddenItems.length - 1], 'swh-hide');
                // The visible list is not overflowing

                // Recur if the visible list is still overflowing the nav
                if (menu.offsetWidth > availableSpace) {
                    this.updateNav();
                }
            }
        } else {
            // There is space for another item in the nav
            while (availableSpace > this.breaks[this.breaks.length - 1]) {
                // Move the item to the visible list
                this.renderer.removeClass(
                    menu.querySelector('.sw-header__menu__item.swh-hide'),
                    'swh-hide',
                );
                this.renderer.addClass(
                    hiddenMenu.querySelector('.hidden-item:not(.swh-hide)'),
                    'swh-hide',
                );
                this.breaks.pop();
            }

            if (menu.offsetWidth > availableSpace) {
                this.updateNav();
            }

            // Hide the dropdown btn if hidden list is empty
            if (this.breaks.length < 1 && !more.classList.contains('sw-item_more')) {
                this.renderer.addClass(more, 'swh-hide');
            }
        }
    }

    changeLanguage(lang: 'ru' | 'en') {
        this.activeLanguage = lang;
        this.localeService.changeLang(lang);
    }

    showNotifications() {
        // Make the tooltip visible
        this.blockNotificationsEl.setAttribute('data-show', '');

        // Enable the event listeners
        this.popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
        }));

        // Update its position
        this.popperInstance.update();
    }

    hideNotifications() {
        // Hide the tooltip
        this.blockNotificationsEl.removeAttribute('data-show');

        // Disable the event listeners
        this.popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
        }));
    }
}
