import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-nested-menu',
    templateUrl: './nested-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedMenuComponent {
    // родительский пункт меню, содержащий описание дочерних
    @Input()
    menu;

    @Output()
    clickAction = new EventEmitter();

    expanded = false;

    emitClick(e) {
        this.clickAction.emit(e);
    }
}
