import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class LocaleService {
    private _locale: string;

    set locale(value: string) {
        this._locale = value;
    }

    get locale(): string {
        return (
            this._locale ||
            (['ru', 'en'].indexOf(localStorage.getItem('lang')) > -1
                ? localStorage.getItem('lang')
                : 'ru')
        );
    }

    constructor(private translocoService: TranslocoService) {}

    changeLang(lang: string) {
        localStorage.setItem('lang', lang);
        this.registerLocale(lang);
        this.translocoService.setActiveLang(lang);
        location.reload();
    }

    private registerLocale(culture: string) {
        if (!culture) {
            return;
        }
        this.locale = culture;
    }
}
