<div
    *ngIf="typeSign !== 'none'"
    class="sw-step__sign"
    part="sign"
    [class.swh-hidden]="signHidden"
>
    <sw-icon
        *ngIf="typeSign === 'check'"
        name="check"
        size="small"
    ></sw-icon>
    <slot name="sign"></slot>
</div>

<div
    class="sw-step__label"
    part="label"
    [class.sw-step__label_another]="signHidden"
>
    <slot></slot>
</div>
