<ng-container *transloco="let t">
    <a
        nohref
        class="sw-item__trigger"
        (click)="expanded = !expanded"
    >
        {{ t(menu.titleKey) }}
        <sw-icon
            name="angle-small-down"
            size="small"
            slot="end"
            [color]="expanded ? '' : 'primary'"
            [class.sw-text_secondary]="expanded"
        ></sw-icon>
    </a>
    <ng-container *ngIf="expanded">
        <div
            *ngFor="let childItem of menu.children"
            class="sw-item__child"
        >
            <a
                [routerLink]="[childItem.url]"
                [queryParams]="childItem.queryParams"
                routerLinkActive="active"
                class="sw-text_secondary"
                (click)="emitClick($event)"
            >
                {{ t(childItem.titleKey) }}
            </a>
        </div>
    </ng-container>
</ng-container>
