import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[textMedium]',
})
export class TextMediumDirective {
    constructor() {}

    @HostBinding('class') get getTextMediumClass() {
        return 'sw-text-weight_medium';
    }
}
