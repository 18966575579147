import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    stopScroll(e) {
        let pagePos = window.scrollY;
        e.preventDefault();
        this.renderer.setStyle(document.body, 'top', `-${pagePos}px`);
        this.renderer.addClass(document.body, 'swh-stop-scroll');
        this.renderer.setAttribute(document.body, 'data-scroll', pagePos.toString());
    }

    startScroll(e) {
        e.preventDefault();
        this.renderer.removeClass(document.body, 'swh-stop-scroll');
        this.renderer.removeStyle(document.body, 'top');
    }

    scrollToPos() {
        let pos = parseInt(document.body.getAttribute('data-scroll'));
        window.scrollTo(0, pos);
    }
}
