import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { EntDescription } from '@shared/models/srv.types';
@Component({
    selector: 'app-signatures',
    templateUrl: './signatures.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturesComponent {
    constructor(public modalService: ModalService) {}

    @Input() signatures: EntDescription[];

    @Input() documentName: string;

    @Input() site: string;

    isScrollingContent: boolean = false;

    ngOnInit() {}

    onContentScroll(ev) {
        const scrollPosition = ev.target.scrollTop;
        this.isScrollingContent = scrollPosition > 50 ? true : false;
    }
}
