import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { RowElement } from './row.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [RowElement],
    exports: [RowElement],
})
export class RowModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(RowElement, {
            injector: this.injector,
        });

        customElements.define('sw-row', el);
    }
}
