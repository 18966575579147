import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { EntData } from '@shared/models/srv.types';
import moment from 'moment';
import { SimpleModalComponent } from '@shared/components/modals/simple-modal.component';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent implements OnInit {
    @Input() notification: EntData;

    // @HostBinding('class') get notoficationClass() {
    //     return {
    //         [`sw-notification-item--active`]: !this.isRead,
    //     };
    // }

    isImportant: boolean;

    @Input() message: string = null;

    @Input() typeMessage: 'info' | 'danger' | 'success' | 'negative' = 'info';

    @Output() stateChanged = new EventEmitter();

    @Output() importanceChanged = new EventEmitter();

    colorIcon: 'action' | 'danger' | 'success';

    nameIcon: 'info' | 'exclamation' | '/assets/icons/other/check-circle.svg';

    state;

    isToday = false;

    now = new Date();

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.state = this.notification.$snapshot.state;
        this.isImportant = this.notification.$snapshot.is_important;
        this.typeMessage = this.notification.$snapshot.notice_level;
        switch (this.typeMessage) {
            case 'info':
                this.colorIcon = 'action';
                this.nameIcon = 'info';
                break;
            case 'danger':
                this.colorIcon = 'danger';
                this.nameIcon = 'exclamation';
                break;
            case 'negative':
                this.colorIcon = 'danger';
                this.nameIcon = 'exclamation';
                break;
            case 'success':
                this.colorIcon = 'success';
                this.nameIcon = '/assets/icons/other/check-circle.svg';
                break;
            default:
                break;
        }

        this.isToday = moment(this.notification.$snapshot.dt_created).isSame(this.now, 'day');
    }

    changeState(state) {
        this.state = state;
        this.stateChanged.emit(state);
    }

    changeImportance(isImportant) {
        this.isImportant = isImportant;
        this.importanceChanged.emit(isImportant);
    }

    get isShortNotification(): boolean {
        if (!this.notification.$snapshot.body) return true;
        const text = this.htmlToText(this.notification.$snapshot.body);
        return text.length <= 250;
    }

    openModal(text) {
        this.modalService.create({
            component: SimpleModalComponent,
            componentProperties: {
                message: text,
            },
        });
    }

    private htmlToText(html): string {
        return html.replace(/<!--[\s\S]*?--!?>/g, '').replace(/<\/?[a-z][^>]*(>|$)/gi, '');
    }
}
