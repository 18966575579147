import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { LogoElement } from './logo.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [LogoElement],
    exports: [LogoElement],
})
export class LogoModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(LogoElement, {
            injector: this.injector,
        });

        customElements.define('sw-logo', el);
    }
}
