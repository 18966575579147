import { Injectable } from '@angular/core';
import { SrvService } from '@shared/services/srv.service';
import {
    BehaviorSubject,
    combineLatest,
    merge,
    Observable,
    of,
    shareReplay,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { EntData, EntList, GroupRequestItem } from '@shared/models/srv.types';
import { TemplateObservable } from '@shared/classes/template-observable';
import { WebSocketService } from '@shared/services/web-socket.service';
import { EntityService } from '@shared/services/entity.service';
import { buildFilterStr } from '@app/app.utils';
import { ListPagination } from '@list/models/list.types';
import { ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { catchError, debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { combine } from '@turf/turf';

const TAB_PARAMS = {
    all: {},
    emergency: {
        template__notice_level: 'danger',
    },
    important: {
        is_important: 'true',
    },
    informing: {
        template__notice_level: 'info',
    },
};

const ALL_TABS = [
    {
        id: 'all',
        titleKey: 'listStaticFilter.all',
        titleParams: { count: 0 },
    },
    // {
    //     id: 'emergency',
    //     titleKey: 'tab.emergency',
    //     titleParams: { count: 0 },
    // },
    {
        id: 'important',
        titleKey: 'tab.important',
        titleParams: { count: 0 },
    },
    // {
    //     id: 'informing',
    //     titleKey: 'tab.informing',
    //     titleParams: { count: 0 },
    // },
];

@Injectable({ providedIn: 'root' })
export class NotificationService {
    // isLoading
    isLoading = new TemplateObservable(true);

    private _updateTopNotificationsList$ = new BehaviorSubject(true);

    private _updateTabsCount$ = new BehaviorSubject(true);

    pagination: TemplateObservable<ListPagination> = new TemplateObservable({
        offset: 0,
        limit: 20,
        count: 0,
    });

    constructor(
        private srv: SrvService,
        private socketService: WebSocketService,
        private entityService: EntityService,
        private authService: AuthService,
    ) {
        combineLatest([this.authService.userId$, this.authService.role$])
            .pipe(
                debounceTime(100),
                tap((v) => {
                    this.newNotifications.set([]);
                    console.log('clr new notifications');
                }),
            )
            .subscribe();
    }

    nextPage$ = new BehaviorSubject(1);

    filter$ = new BehaviorSubject({});

    tab$ = new BehaviorSubject('all');

    newNotifications = new TemplateObservable([]);

    notificationsFromSocket$ = this.socketService.message$.pipe(
        filter(
            (v) =>
                v &&
                (v?.$snapshot?.notice_type === 'notice.message' ||
                    v?.$snapshot?.notice_type === 'notice.system'),
        ),
        tap((v) => {
            const newNotifications = this.newNotifications._;
            newNotifications.unshift(v);
            this.newNotifications.set(newNotifications);
            this._updateTabsCount$.next(true);
            this.nextPage$.next(this.nextPage$.value);
            this._updateStorage([v]);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    private _notificationStorage = new TemplateObservable({});

    filterChanged$ = this.filter$.pipe(
        tap((v) => {
            this.nextPage$.next(1);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    tabFilters$ = this.tab$.pipe(
        map((tab) => {
            return TAB_PARAMS[tab] || null;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    tabList$ = merge(this.filterChanged$, this._updateTabsCount$).pipe(
        debounceTime(0),
        withLatestFrom(this.filterChanged$),
        switchMap(([v, filters]) =>
            this.entityService.getListsCounts(
                ALL_TABS.map((tab) => ({
                    entityType: 'notifications.notice',
                    filters: { ...filters, ...TAB_PARAMS[tab.id] },
                })),
            ),
        ),
        map((response) => {
            let i = 0;
            return [...ALL_TABS].map((tab) => {
                if (!TAB_PARAMS[tab.id]) return tab;
                return {
                    ...tab,
                    titleParams: { count: response[i++] },
                };
            });
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // list of notifications
    listFromRequest$: Observable<EntData[]> = combineLatest([
        this.nextPage$,
        this.filterChanged$,
        this.tabFilters$,
    ]).pipe(
        debounceTime(0),
        tap((v) => this.isLoading.set(true)),
        switchMap(([page, filters, tabFilters]) =>
            this.entityService.getEntityList$(
                'notifications.notice',
                '',
                '',
                { filter: buildFilterStr({ ...filters, ...tabFilters }) },
                (page - 1) * this.pagination._.limit,
                this.pagination._.limit,
            ),
        ),
        tap((list) => {
            this.pagination.set(list.$meta?.pagination);
            this._updateStorage(list.list);
        }),
        map((v) => v.list),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    notifications$ = combineLatest([this.listFromRequest$, this._notificationStorage.$]).pipe(
        debounceTime(10),
        map(([notifications, storage]) => this._actualize(notifications)),
        tap((v) => {
            this.isLoading.set(false);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // list of unread notifications
    unreadNotifications$: Observable<EntData[]> = combineLatest([
        this._updateTopNotificationsList$,
        this.authService.role$,
    ]).pipe(
        filter(([update, role]) => !!role),
        switchMap(([update, role]) =>
            role !== ROLE.anonymous && role !== ROLE.base && role !== ROLE.director
                ? this.entityService.getEntityList$(
                      'notifications.notice',
                      '',
                      '',
                      { filter: buildFilterStr({ state: 'send' }) },
                      0,
                      1000,
                  )
                : of(null),
        ),
        tap((list) => {
            if (!list) return;
            this._updateStorage(list.list);
        }),
        map((v) => (v ? v.list : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    topNotifications$ = combineLatest([
        this.unreadNotifications$,
        this.newNotifications.$,
        this._notificationStorage.$,
    ]).pipe(
        debounceTime(10),
        map(([unreadNotifications, newNotifications, storageData]) =>
            unreadNotifications ? this.mergeListItems(unreadNotifications, newNotifications) : null,
        ),
        map((notifications) =>
            notifications
                ? this._actualize(notifications).filter(
                      (v) => v.$snapshot.state !== 'read' && !v.$snapshot.is_skip_save,
                  )
                : null,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    blockingNotifications$ = this.topNotifications$.pipe(
        map((notifications) =>
            notifications ? notifications.filter((v) => v.$snapshot.is_blocking_notice) : null,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    mergeListItems(main: any[], addToBeginElements: any[] = []) {
        const mainList = main;
        addToBeginElements.forEach((element) => {
            if (!mainList.find((v) => v.id === element.id)) {
                mainList.unshift(element);
            }
        });
        return main;
    }

    changeState(ids: string[], state = 'read') {
        this.srv
            .sendFormRequest$(
                'user/change_state_notifications',
                { state, notifications_id: ids },
                'post',
                'application/json',
            )
            .pipe(
                tap(() => {
                    this._updateStoragePropery(ids, { state });
                    if (state !== 'read') {
                        this._updateTopNotificationsList$.next(true);
                    }
                }),
                take(1),
            )
            .subscribe();
    }

    changeImportance(id: string, importance: boolean) {
        this.srv
            .saveEntity$(
                'notifications.notice',
                id,
                this.srv.generateEntityBody('notifications.notice', id, {
                    is_important: importance,
                }),
            )
            .pipe(
                tap(() => {
                    this._updateStoragePropery([id], { is_important: importance });
                    this._updateTabsCount$.next(true);
                }),
                withLatestFrom(this.tab$),
                tap(([response, tab]) => {
                    if (tab === 'important') this.tab$.next(tab);
                }),
                take(1),
            )
            .subscribe();
    }

    private _updateStoragePropery(ids, propertyObject) {
        this._updateStorage(
            ids.map((id) => {
                return {
                    ...this._notificationStorage._[id],
                    $snapshot: {
                        ...this._notificationStorage._[id].$snapshot,
                        ...propertyObject,
                    },
                };
            }),
        );
    }

    private _updateStorage(notifications) {
        const newNotificationsMap = notifications.reduce((acc, notification) => {
            acc[notification.id] = notification;
            return acc;
        }, {});
        this._notificationStorage.set({ ...this._notificationStorage._, ...newNotificationsMap });
    }

    private _actualize(notifications) {
        return notifications.map((v) => {
            return this._notificationStorage._[v.id] || v;
        });
    }
}
