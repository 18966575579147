import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Directives
import { ScrollDirective } from '@shared/directives/scroll.directive';
import { MaskDirective } from '@shared/directives/mask.directive';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ColorDirective } from '@shared/directives/color.directive';
import { TextSizeDirective } from '@shared/directives/text-size.directive';
import { BackgroundDirective } from '@shared/directives/background.directive';
import { IfPropertyDirective } from '@shared/directives/if-property.directive';
import { IfControlDirective } from '@shared/directives/if-control.directive';
import { StickyDirective } from '@shared/directives/sticky.directive';
import { TextMediumDirective } from '@shared/directives/text-medium.directive';
import { CoefDirective } from '@shared/directives/coef.directive';
import { InView } from '@shared/directives/in-view.directive';
import { FixNumberInputDirective } from '@app/shared/directives/fix-number-input.directive';
import { LocalRouterLinkDirective } from '@shared/directives/local-router-link.directive';
import { SwipeDirective } from '@shared/directives/swipe.directive';

// Pipes
import { StrReplacePipe } from '@shared/pipes/str-replace.pipe';
import { EntityPipe } from '@shared/pipes/entity.pipe';
import { EntityListPipe } from '@shared/pipes/entity-list.pipe';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { ReplaceCommaPipe } from '@shared/pipes/replace-comma.pipe';
import { StatusPipe } from '@shared/pipes/status.pipe';
import { TransitPipe } from '@shared/pipes/transit.pipe';
import { EnumPipe } from '@shared/pipes/enum.pipe';
import { PluralPipe } from '@shared/pipes/plural.pipe';
import { DateDiffPipe } from '@shared/pipes/date-diff.pipe';
import { OrganizationPipe } from '@shared/pipes/organization.pipe';
import { LocalDatePipe } from '@shared/pipes/local-date.pipe';
import { LocalNumberPipe } from '@shared/pipes/local-number.pipe';

// Modules
import { DatepickerModule } from '@shared/components/datepicker/datepicker.module';
import { TimepickerModule } from '@shared/components/timepicker/timepicker.module';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@shared/components/icon/icon.module';
import { ColModule } from '@shared/components/grid/col/col.module';
import { GridModule } from '@shared/components/grid/grid/grid.module';
import { RowModule } from '@shared/components/grid/row/row.module';
import { ToggleModule } from '@shared/components/toggle/toggle.module';
import { TabsModule } from '@shared/components/tabs/tabs.module';
import { ChipModule } from '@shared/components/chip/chip.module';
import { BadgeModule } from '@shared/components/badge/badge.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { LogoModule } from '@shared/components/logo/logo.module';
import { LinkModule } from '@shared/components/link/link.module';
import { TooltipModule } from '@shared/components/tooltip/tooltip.module';
import { ToolbarModule } from '@shared/components/toolbar/toolbar.module';
import { StepModule } from '@shared/components/step/step.module';
import { AccordionModule } from '@shared/components/accordion/accordion.module';
import { TripLineModule } from '@shared/components/trip-line/trip-line.module';
import { SkeletonModule } from '@shared/components/skeleton/skeleton.module';
import { RangeModule } from '@shared/components/range/range.module';
import { ThumbnailModule } from '@shared/components/thumbnail/thumbnail.module';

// Components
import { InfiniteListComponent } from '@shared/components/infinite-list.component';
import { InfiniteListSortSelectedComponent } from '@shared/components/infinite-list-sort-selected.component';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { GlobalLoaderComponent } from '@shared/components/loaders/global-loader.component';
import { DefaultLoaderComponent } from '@shared/components/loaders/default-loader.component';
import { TestModalComponent } from '@shared/components/modals/test-modal.component';
import { ModalsComponent } from '@shared/components/modal/modals.component';
import { CoordinatesComponent } from '@shared/components/coordinates.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { HeaderMobileComponent } from '@shared/components/header/header-mobile.component';
import { NestedMenuComponent } from '@shared/components/header/nested-menu.component';
import { FooterComponent } from '@shared/components/footer.component';
import { EntityStatusListComponent } from '@app/shared/components/entity-status-list.component';
import { ScheduleComponent } from '@shared/components/schedule.component';
import { ScheduleTimePipe } from '@shared/pipes/schedule-time.pipe';
import { GalleryModalComponent } from '@shared/components/modals/gallery-modal.component';
import { VehicleNumberComponent } from '@shared/components/vehicle-number/vehicle-number.component';
import { GallerySliderComponent } from '@app/shared/components/gallery/gallery-slider.component';
import { GalleryComponent } from '@shared/components/gallery/gallery.component';
import { EntitySavingStatusComponent } from '@shared/components/entity-saving-status.component';
import { StatusComponent } from '@shared/components/status/status.component';
import { FileInlineComponent } from '@shared/components/file/file-inline.component';
import { CardContactComponent } from '@shared/components/card-contact/card-contact.component';
import { DateplateComponent } from '@shared/components/dateplate/dateplate.component';
import { FormModule } from '@form/form.module';
import { GallerySingleSliderComponent } from '@shared/components/gallery/gallery-single-slider.component';
import { GallerySkeletonComponent } from '@shared/components/gallery/gallery-skeleton.component';
import { EntityStatusListSkeletonComponent } from '@shared/components/entity-status-list-skeleton.component';
import { EditEntityFooterSkeletonComponent } from '@shared/components/edit-entity-footer-skeleton.component';
import { NotificationHeaderComponent } from '@shared/components/notification/notification-header.component';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { NotificationItemComponent } from '@shared/components/notification/notification-item.component';
import { TopNotificationsComponent } from '@shared/components/top-notifications.component';
import { CookieComponent } from '@shared/components/cookie.component';
import { TabsSliderComponent } from '@shared/components/tabs-slider/tabs-slider.component';
import { LayoutPressFooterComponent } from '@shared/components/layout-press-footer/layout-press-footer.component';
import { BlockingNotificationsComponent } from '@shared/components/notification/blocking-notifications.component';
import { SimpleModalComponent } from '@shared/components/modals/simple-modal.component';
import { RoleMenuItemComponent } from '@shared/components/header/role-menu-item.component';
import { SliderComponent } from '@shared/components/slider/slider.component';
import { WebglDiggerComponent } from '@shared/components/webgl-digger.component';
import { DummyMobileComponent } from '@shared/components/dummy-mobile/dummy-mobile.component';
import { DummyModalComponent } from '@shared/components/modals/dummy-modal.component';
import { LoadMoreListComponent } from '@shared/components/loadmore-list.component';

const DECLARATIONS = [
    StrReplacePipe,
    EntityPipe,
    EntityListPipe,
    FileSizePipe,
    SafePipe,
    ReplaceCommaPipe,
    ScheduleTimePipe,
    StatusPipe,
    TransitPipe,
    EnumPipe,
    PluralPipe,
    DateDiffPipe,
    OrganizationPipe,
    LocalDatePipe,
    LocalNumberPipe,

    ScrollDirective,
    MaskDirective,
    ColorDirective,
    TextSizeDirective,
    ModalDirective,
    ClickOutsideDirective,
    BackgroundDirective,
    IfPropertyDirective,
    IfControlDirective,
    StickyDirective,
    TextMediumDirective,
    CoefDirective,
    InView,
    FixNumberInputDirective,
    LocalRouterLinkDirective,
    SwipeDirective,

    InfiniteListComponent,
    InfiniteListSortSelectedComponent,
    LoadMoreListComponent,
    BreadcrumbsComponent,
    CookieComponent,
    HeaderComponent,
    HeaderMobileComponent,
    NestedMenuComponent,
    RoleMenuItemComponent,
    FooterComponent,
    LayoutPressFooterComponent,
    CoordinatesComponent,
    EntityStatusListComponent,
    EntityStatusListSkeletonComponent,
    EntitySavingStatusComponent,
    EditEntityFooterSkeletonComponent,
    ScheduleComponent,
    VehicleNumberComponent,
    SliderComponent,
    GallerySliderComponent,
    GallerySingleSliderComponent,
    NotificationHeaderComponent,
    NotificationItemComponent,
    BlockingNotificationsComponent,

    AlertComponent,
    ToastComponent,
    ModalComponent,
    ModalsComponent,
    GlobalLoaderComponent,
    DefaultLoaderComponent,
    EmptyComponent,

    TestModalComponent,
    GalleryModalComponent,
    GalleryComponent,
    GallerySkeletonComponent,
    StatusComponent,
    FileInlineComponent,
    CardContactComponent,
    DateplateComponent,
    TopNotificationsComponent,
    SimpleModalComponent,
    TabsSliderComponent,

    WebglDiggerComponent,
    DummyMobileComponent,
    DummyModalComponent,
];

const WEB_COMPONENT_MODULES = [
    GridModule,
    RowModule,
    ColModule,
    IconModule,
    ToggleModule,
    TabsModule,
    ChipModule,
    BadgeModule,
    ButtonModule,
    LogoModule,
    LinkModule,
    DatepickerModule,
    TimepickerModule,
    TooltipModule,
    ToolbarModule,
    StepModule,
    AccordionModule,
    TripLineModule,
    SkeletonModule,
    RangeModule,
    ThumbnailModule,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        WEB_COMPONENT_MODULES,
    ],
    exports: [...DECLARATIONS, ...WEB_COMPONENT_MODULES],
    declarations: [...DECLARATIONS],
    providers: [StrReplacePipe],
})
export class SharedModule {}
