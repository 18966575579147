<ng-container *ngIf="{ loaders: loaderService.loaders.$ | async } as data">
    <div class="sw-loader"
        *ngIf="data.loaders.length > 0"
    >
        <svg class="sw-loader__spinner">
            <circle cx="25" cy="25" r="14" fill="none" stroke-width="1"></circle>
        </svg>
        <p *ngFor="let loader of data.loaders">
            {{ loader.options.message }}
        </p>
    </div>
</ng-container>
