<div
    class="sw-infinite-scroll-content"
    scroll
    (scrollToBottom)="needNextPage$.next(true)"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            list: list$ | async
        } as data"
    >
        <ng-container *ngIf="data.list && isMultiple && selectedMap.size > 0">
            <div class="sw-item sw-item_group">
                <div class="swh-flex swh-justify-content-between">
                    <span (click)="toggleSelectedGroup()">
                        {{ t('title.selected') }} ( {{ selectedMap.size }} )
                        <sw-icon
                            size="small"
                            [name]="showSelectedGroup ? 'angle-small-up' : 'angle-small-down'"
                        ></sw-icon>
                    </span>
                    <sw-link
                        textSize="m"
                        borderStyle="none"
                        fontWeight="500"
                        class="swh-hide-lg-up"
                        color="danger"
                        (click)="clearAll()"
                    >
                        {{ t('filter.clear') }}
                    </sw-link>
                </div>
            </div>
            <ng-container *ngIf="showSelectedGroup">
                <ng-container
                    *ngFor="let item of selectedList$ | async; let i = index; trackBy: trackById"
                >
                    <ng-template
                        [ngTemplateOutlet]="itemTpl"
                        [ngTemplateOutletContext]="{ item: item }"
                    ></ng-template>
                </ng-container>
            </ng-container>
        </ng-container>

        <div
            *ngIf="data.list && isMultiple"
            class="sw-item sw-item_group"
            (click)="toggleSearchGroup()"
        >
            <span>
                {{ t('title.search') }}
                <sw-icon
                    size="small"
                    [name]="showSearchGroup ? 'angle-small-up' : 'angle-small-down'"
                ></sw-icon>
            </span>
        </div>
        <ng-container *ngIf="showSearchGroup">
            <ng-container *ngFor="let item of data.list; let i = index; trackBy: trackById">
                <ng-template
                    *ngIf="
                        !(
                            selectedMap &&
                            selectedMap.has(
                                searchSettings.fieldForId
                                    ? item.$snapshot[searchSettings.fieldForId]?.id
                                    : item.id
                            )
                        ) || !isMultiple
                    "
                    [ngTemplateOutlet]="itemTpl"
                    [ngTemplateOutletContext]="{ item: item }"
                ></ng-template>
            </ng-container>
        </ng-container>
        <div
            class="sw-item swh-events-none"
            *ngIf="data.list?.length === 0"
        >
            {{ t(emptyListKey || 'title.emptyList') }}
        </div>
        <sw-button
            *ngIf="data.list && hasApplyBtn"
            color="action"
            class="swh-block"
            [disabled]="selectedMap.size === 0"
            (click)="applyAll()"
        >
            {{ t('action.showCount', { count: selectedMap.size }) }}
        </sw-button>
    </ng-container>
</div>
