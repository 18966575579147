import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SliderComponent } from '../slider/slider.component';

@Component({
    selector: 'app-gallery-slider',
    templateUrl: './gallery-slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySliderComponent extends SliderComponent implements OnInit {
    ngOnInit() {
        this.count = 10;
        this.width = 271;
        this.offset = 8;
        // this.iconPrev = 'angle-small-left';
        // this.iconNext = 'angle-small-right';
    }
}
