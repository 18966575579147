<div
    sticky
    [stickyBottom]="0"
    class="sw-container sw-form__footer"
>
    <sw-grid class="sw-grid_global">
        <sw-row class="swh-align-items-center">
            <sw-col size="8">
                <div class="swh-flex sw-row-elements">
                    <sw-skeleton
                        [animated]="true"
                        [width]="200"
                        [height]="36"
                        [bottom]="0"
                    ></sw-skeleton>
                    <sw-skeleton
                        [animated]="true"
                        [width]="200"
                        [height]="36"
                    ></sw-skeleton>
                </div>
            </sw-col>
            <sw-col size="4">
                <sw-skeleton
                    class="swh-float-right"
                    [animated]="true"
                    [width]="260"
                    [height]="24"
                    [top]="6"
                ></sw-skeleton>
            </sw-col>
        </sw-row>
    </sw-grid>
</div>
