<ng-container *transloco="let t">
    <div
        class="swh-flex"
        *ngIf="signature.id | entity: signature.type | async as signatureEntity"
    >
        <span
            class="sw-e-sign"
            color="primary"
        >
            <sw-icon
                size="small"
                src="/assets/icons/other/e-sign.svg"
            ></sw-icon>
        </span>
        <div>
            <h4>{{ signatureEntity.$snapshot.subject?.CN }}</h4>

            <dl class="sw-dl_inline">
                <dt>{{ t('document.modal.organization') }}</dt>
                <dd>{{ signatureEntity.$snapshot.subject.O }}</dd>
                <dt>{{ t('document.modal.signDate') }}</dt>
                <dd>{{ signatureEntity.$snapshot.dt_created | localDate: 'shortDateTime' }}</dd>
                <dt>{{ t('document.modal.certificate') }}</dt>
                <dd>{{ signatureEntity.$snapshot.cert_serial }}</dd>
                <dt>{{ t('document.modal.certificateDates') }}</dt>
                <dd>
                    {{
                        t('document.modal.certificateDatesExpression', {
                            from:
                                signatureEntity.$snapshot.dt_actual_start
                                | localDate: 'shortDateTime',
                            to:
                                signatureEntity.$snapshot.dt_actual_finish
                                | localDate: 'shortDateTime'
                        })
                    }}
                </dd>
                <dt>{{ t('document.modal.issuerOrganization') }}</dt>
                <dd>{{ signatureEntity.$snapshot.issuer?.O }}</dd>
            </dl>
            <sw-link
                borderStyle="none"
                textSize="s_reduced"
                (click)="downloadSign(signatureEntity.$snapshot.signature)"
            >
                <sw-icon
                    slot="start"
                    name="download"
                    size="small"
                ></sw-icon>
                {{t('document.action.downloadSignature')}}
            </sw-link>
            <!-- <sw-link (click)="showSignature = !showSignature">
                {{ showSignature ? t('document.action.hideSignature') : t('document.action.showSignature') }}
                <sw-icon
                    slot="end"
                    size="small"
                    [name]="showSignature ? 'angle-small-up' : 'angle-small-down'"
                ></sw-icon>
            </sw-link>
            <br>
            <textarea
                class="swh-margin-top"
                rows="3"
                *ngIf="showSignature"
            >
                {{ signatureEntity.$snapshot.signature }}
            </textarea> -->
        </div>
    </div>
</ng-container>
