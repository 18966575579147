<div
    class="sw-modal-wrap"
    [class.sw-modal--scrolling]="isScrollingContent"
    *transloco="let t"
>
    <div class="sw-modal__header">
        <h1>{{ t('document.modal.h1') }}</h1>
    </div>

    <div
        (scroll)="onContentScroll($event)"
        class="sw-modal__content"
    >
        <p class="swh-margin-bottom" coef="9">
            <span [innerHTML]="t('document.modal.description', { documentName: documentName })"></span>
            <a [href]="'https://'+ site">{{ site }}</a>
        </p>

        <div class="sw-list sw-list_e-sign">
            <div class="sw-item" *ngFor="let signature of signatures">
                <app-signature [signature]="signature"></app-signature>
            </div>
        </div>
    </div>

    <div class="sw-modal__footer">
        <sw-toolbar>
            <sw-button
                slot="end"
                color="action"
                (click)="modalService.dismiss()"
            >
                {{ t('action.close') }}
            </sw-button>
        </sw-toolbar>
    </div>
</div>
