import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'sw-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['timepicker.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerElement implements OnInit {
    @Input() debug: boolean;

    _value: Date = null;

    @Input() set value(newValue: Date) {
        if (this.debug) console.log('calendar:set value(' + newValue + ')');
        this._value = newValue;

        if (newValue != null) {
            this.updateHoursInputValue(moment(newValue).hour(), false);
            this.updateMinuteInputValue(moment(newValue).minute(), false);
        }
    }

    get value(): Date {
        return this._value;
    }

    @Input() minTime: string;

    @Input() maxTime: string;

    @Input() additionalBtns: { txt: string; clickFn: () => void }[];

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    public hoursInputControl = new FormControl(null, (control) => this.hoursValidator(control));

    public minutesInputControl = new FormControl(null, (control) => this.minutesValidator(control));

    public isPositionFixed: boolean = true;

    constructor() {}

    ngOnInit(): void {
        if (this.value === null) {
            this.value = new Date();
            this.checkHoursAndEmit();
            this.checkMinutesAndEmit();
        }

        this.hoursInputControl.valueChanges.subscribe(() => this.checkHoursAndEmit());
        this.minutesInputControl.valueChanges.subscribe(() => this.checkMinutesAndEmit());
    }

    private hoursValidator(control: AbstractControl) {
        return this.validHours(control.value) ? null : { wrongValue: 'неверное значение часов' };
    }

    private minutesValidator(control: AbstractControl) {
        return this.validMinutes(control.value) ? null : { wrongValue: 'неверное значение минут' };
    }

    private validHours(value: any) {
        let hours = parseInt(value, 10);
        return !Number.isNaN(hours) && hours >= 0 && hours < 24;
    }

    private validMinutes(value: any) {
        let hours = parseInt(value, 10);
        return !Number.isNaN(hours) && hours >= 0 && hours < 60;
    }

    private updateHoursInputValue(value: any, emitEvent = true) {
        let hours: string;
        if (this.validHours(value)) {
            hours = value.toString().padStart(2, '0');
            this.hoursInputControl.setValue(hours, { emitEvent });
        } else {
            if (value.toString().length > 2) {
                hours = value.toString().substr(0, 2);
                this.hoursInputControl.setValue(hours, { emitEvent });
            }
        }
    }

    private updateMinuteInputValue(value: any, emitEvent = true) {
        let minutes: string;
        if (this.validMinutes(value)) {
            minutes = value.toString().padStart(2, '0');
            this.minutesInputControl.setValue(minutes, { emitEvent });
        } else {
            if (value.toString().length > 2) {
                minutes = value.toString().substr(0, 2);
                this.minutesInputControl.setValue(minutes, { emitEvent });
            }
        }
    }

    stepPrevHour() {
        if (this.value) {
            const h = +this.hoursInputControl.value;
            this.updateHoursInputValue(h == 0 ? 23 : h - 1);
            this.checkHoursAndEmit();
        }
    }

    stepNextHour() {
        if (this.value) {
            const h = +this.hoursInputControl.value;
            this.updateHoursInputValue(h == 23 ? 0 : h + 1);
            this.checkHoursAndEmit();
        }
    }

    stepPrevMinute() {
        if (this.value) {
            const m = +this.minutesInputControl.value;
            this.updateMinuteInputValue(m == 0 ? 59 : m - 1);
            this.checkMinutesAndEmit();
        }
    }

    stepNextMinute() {
        if (this.value) {
            const m = +this.minutesInputControl.value;
            this.updateMinuteInputValue(m == 59 ? 0 : m + 1);
            this.checkMinutesAndEmit();
        }
    }

    handleHoursBlur() {
        let hours = this.hoursInputControl.value;
        this.updateHoursInputValue(hours, false); // дополним 0
    }

    handleMinutesBlur() {
        let minutes = this.minutesInputControl.value;
        this.updateMinuteInputValue(minutes, false); // дополним 0
    }

    public checkHoursAndEmit() {
        if (this.hoursInputControl.valid && this.value) {
            this.value = moment(this.value)
                .hour(+this.hoursInputControl.value)
                .toDate();
            this.valueChange.emit(moment(this.value).format('HH:mm'));
        }
    }

    public checkMinutesAndEmit() {
        if (this.minutesInputControl.valid && this.value) {
            this.value = moment(this.value)
                .minute(+this.minutesInputControl.value)
                .toDate();
            this.valueChange.emit(moment(this.value).format('HH:mm'));
        }
    }
}
