import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TabsElement } from './tabs.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [TabsElement],
    imports: [CommonModule, RouterModule, IconModule, TranslocoModule],
    exports: [TabsElement],
})
export class TabsModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TabsElement, {
            injector: this.injector,
        });

        customElements.define('sw-tabs', el);
    }
}
