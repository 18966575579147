<ng-container *transloco="let t">
    <header
        class="sw-header sw-container"
        [class.sw-header--fixed]="menuOpened || userMenuOpened"
        [ngClass]="cssClass"
        *ngIf="{
            user: userInfo$ | async,
            organization: organizationInfo$ | async,
            paymentAccount: paymentAccount$ | async,
            role: role$ | async
        } as data"
    >
        <div class="sw-header-wrap">
            <div class="sw-header__left">
                <a
                    class="sw-header__logo"
                    routerLink="/"
                >
                    <img
                        alt="SmartWaste Logo"
                        [attr.src]="'/assets/logos/logo-mobile.svg'"
                    />
                </a>
            </div>

            <div class="sw-header__user-menu sw-list sw-list_h swh-justify-content-end">
                <div class="sw-item">
                    <ng-container *ngIf="!data.user">
                        <sw-button
                            color="primary"
                            [routerLink]="['/login']"
                        >
                            {{ t('header.loginMobile') }}
                        </sw-button>
                    </ng-container>

                    <div
                        *ngIf="data.user"
                        class="sw-header__profile"
                    >
                        <span
                            class="sw-avatar sw-avatar_small"
                            (click)="toogleUserMenu($event)"
                        >
                            {{ data.user.shortName }}
                            <sw-icon
                                name="angle-small-down"
                                size="small"
                            ></sw-icon>
                        </span>
                    </div>
                </div>

                <div
                    class="sw-item swh-underconstruction swh-hide"
                    [title]="t(featureUnderConstruction)"
                >
                    <sw-button fill="clear">
                        <sw-icon
                            slot="icon-only"
                            name="search"
                        ></sw-icon>
                    </sw-button>
                </div>

                <div
                    class="sw-item"
                    *ngIf="data.user && data.role"
                >
                    <div *ngIf="notificationService.topNotifications$ | async as notifications">
                        <sw-button fill="clear">
                            <sw-icon
                                name="bell"
                                slot="icon-only"
                                size="medium"
                            ></sw-icon>
                            <sw-badge
                                color="primary"
                                size="xs"
                                *ngIf="notifications.length > 0"
                            ></sw-badge>
                        </sw-button>
                    </div>
                </div>

                <div class="sw-item swh-margin-start">
                    <sw-button
                        id="menuBurger"
                        color="action"
                        class="sw-menu-burger"
                        (click)="toogleMenu($event)"
                    >
                        <sw-icon
                            slot="icon-only"
                            [name]="menuOpened ? 'cross-small' : 'menu-burger'"
                        ></sw-icon>
                    </sw-button>
                </div>
            </div>
        </div>

        <div
            class="sw-header-dropdown"
            *ngIf="menuOpened"
        >
            <div class="sw-list sw-list_menu">
                <ng-container *ngFor="let menuItem of headerMenu[data.role]">
                    <li
                        *ngIf="menuItem.type !== 'more'"
                        class="sw-item"
                    >
                        <a
                            *ngIf="!menuItem.children"
                            [routerLink]="[menuItem.url]"
                            routerLinkActive="active"
                            [queryParams]="menuItem.queryParams"
                            (click)="closeMenu($event)"
                        >
                            {{ t(menuItem.titleKey) }}
                        </a>

                        <ng-container *ngIf="menuItem.children">
                            <app-nested-menu
                                [menu]="menuItem"
                                (clickAction)="closeMenu($event)"
                            ></app-nested-menu>
                        </ng-container>
                    </li>
                </ng-container>
                <ng-container
                    *ngIf="headerMenu[data.role][headerMenu[data.role].length - 1] as lastItem"
                >
                    <li
                        *ngIf="lastItem.type === 'more'"
                        class="sw-item"
                    >
                        <app-nested-menu
                            [menu]="lastItem"
                            (clickAction)="closeMenu($event)"
                        ></app-nested-menu>
                    </li>
                </ng-container>
            </div>

            <hr />

            <div
                class="swh-flex sw-row-elements"
                *ngIf="!assembly.production"
            >
                <sw-button
                    size="small"
                    color="action"
                    [fill]="activeLanguage === 'ru' ? 'solid' : 'outline'"
                    (click)="changeLanguage('ru', $event)"
                >
                    Ру
                </sw-button>
                <sw-button
                    size="small"
                    color="action"
                    [fill]="activeLanguage === 'en' ? 'solid' : 'outline'"
                    (click)="changeLanguage('en', $event)"
                >
                    Eng
                </sw-button>
            </div>

            <ng-container *ngIf="!data.user">
                <hr />
                <sw-button
                    color="primary"
                    class="swh-block"
                    [routerLink]="['/login']"
                    (click)="closeMenu($event)"
                >
                    {{ t('header.login') }}
                </sw-button>
            </ng-container>
        </div>

        <div
            class="sw-header-dropdown"
            *ngIf="userMenuOpened"
        >
            <div class="sw-avatar swh-float-left">
                {{ data.user.shortName }}
            </div>
            <div class="swh-overhide">
                <p
                    class="sw-text-action swh-no-margin"
                    textSize="l"
                >
                    {{ data.user?.first_name }} {{ data.user?.last_name }}
                </p>
                <p
                    class="sw-text_secondary swh-no-margin"
                    textSize="s_reduced"
                >
                    {{ data.organization?.name }}
                </p>
                <sw-chip
                    *ngIf="
                        data.user?.role_current.id
                            | entity : data.user?.role_current.type
                            | async as currentRole
                    "
                >
                    {{ currentRole.$snapshot.name.toLowerCase() }}
                </sw-chip>
            </div>
            <div class="sw-list sw-list_menu sw-list_overline">
                <ng-container *ngFor="let menuItem of userMenu">
                    <a
                        class="sw-item"
                        *ngIf="
                            menuItem.type !== 'roles' &&
                            !menuItem.underconstruction &&
                            menuItem.excludeForRoles.indexOf(data.role) === -1
                        "
                        [routerLink]="[menuItem.url]"
                        routerLinkActive="active"
                        (click)="closeMenu($event)"
                    >
                        <sw-icon [name]="menuItem.icon"></sw-icon>
                        {{ t(menuItem.titleKey) }}
                    </a>
                    <ng-container *ngIf="menuItem.type === 'roles'">
                        <ng-container *ngFor="let role of data.user.roles">
                            <app-role-menu-item
                                *ngIf="role.id !== data.user.role_current.id"
                                [menuItem]="menuItem"
                                [role]="role"
                                [currentUserRole]="data.user.role_current"
                                (select)="selectRole(role.id, data.user.id, $event)"
                            ></app-role-menu-item>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </header>
    <div
        class="sw-backdrop"
        *ngIf="menuOpened || userMenuOpened"
        (click)="closeMenuWithScroll($event)"
    ></div>
</ng-container>
