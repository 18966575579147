import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DatepickerElement } from './datepicker.component';
// import { DatepickerDirective } from './datepicker.directive';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { TranslocoModule } from '@ngneat/transloco';

// @NgModule({
//     imports: [FormsModule, ReactiveFormsModule, CommonModule],
//     declarations: [DatepickerElement, DatepickerDirective],
//     // entryComponents: [
//     //     DatepickerComponent
//     // ],
//     exports: [DatepickerElement, DatepickerDirective],
// })
// export class DatepickerModule {}

@NgModule({
    declarations: [DatepickerElement],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        IconModule,
        ButtonModule,
        TranslocoModule,
    ],
    exports: [DatepickerElement],
})
export class DatepickerModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(DatepickerElement, {
            injector: this.injector,
        });

        customElements.define('sw-datepicker', el);
    }
}
