<div
    class="sw-container"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            text: textSettings$ | async,
            role: role$ | async,
            agencyContract: agencyContract$ | async,
            partnerContract: partnerContract$ | async,
            organization: organization$ | async
        } as data"
    >
        <ng-container
            *ngIf="
                notificationsToShow['agencyContract'] &&
                data.text &&
                data.text['agencyContract'] &&
                (data.role === ROLE.wasteRecycling || data.role === ROLE.wasteTransportation) &&
                ((!data.agencyContract && data.agencyContract !== undefined) ||
                    (data.agencyContract && data.agencyContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'agencyContract',
                text: data.text['agencyContract']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['partnerContract'] &&
                data.text &&
                data.text['partnerContract'] &&
                data.role === ROLE.referral &&
                ((!data.partnerContract && data.partnerContract !== undefined) ||
                    (data.partnerContract && data.partnerContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'partnerContract',
                text: data.text['partnerContract']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['requisites'] &&
                data.text &&
                data.text['requisites'] &&
                data.role === ROLE.director &&
                data.organization?.$snapshot?.status?.id !== 'accepted'
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'requisites',
                text: data.text['requisites']
            }"
        ></ng-container>
    </ng-container>
</div>

<ng-template
    #notificationTpl
    let-id="id"
    let-text="text"
>
    <div
        class="sw-block sw-block_warning-message"
        *transloco="let t"
    >
        <sw-icon
            color="warning"
            name="exclamation"
            class="swh-margin-top"
            coef="0.5"
        ></sw-icon>
        <div class="swh-flex swh-justify-content-between">
            <div class="sw-block__content">
                <p
                    [innerHTML]="
                        t(
                            'topNotifications.' + id + '.' + text,
                            notificationSettings[id].texts[text].titleKeyParams
                        )
                    "
                ></p>
            </div>

            <sw-button
                size="small"
                fill="clear"
                (click)="hideNotification(id)"
            >
                <sw-icon
                    slot="icon-only"
                    name="cross"
                ></sw-icon>
            </sw-button>
        </div>
    </div>
</ng-template>
