<ng-container *transloco="let t">
    <sw-button fill="clear"
        class="sw-modal__close"
        (click)="closeModal()"
    >
        {{ t('action.close') }}
        <sw-icon name="cross" slot="end"></sw-icon>
    </sw-button>

    <div class="sw-modal__content">
        <ng-container *ngFor="let img of gallery; let i = index">
            <ng-container *ngIf="i === currentIndex">
                <ng-container *ngIf="img.id | entity: img.type | async as image">
                    <img
                        [src]="image.$snapshot.file"
                        [alt]="image.$snapshot.name"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <sw-button fill="outline"
        *ngIf="currentIndex > 0"
        class="sw-prev"
        (click)="next(-1)"
    >
        <sw-icon name="angle-small-left" slot="icon-only"></sw-icon>
    </sw-button>
    <sw-button fill="outline"
        *ngIf="currentIndex < gallery.length - 1"
        class="sw-next"
        (click)="next(1)"
    >
        <sw-icon name="angle-small-right" slot="icon-only"></sw-icon>
    </sw-button>
</ng-container>
