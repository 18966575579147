import { Injectable } from '@angular/core';
import { SrvService } from '@shared/services/srv.service';
import { combineLatest, of, shareReplay, Subject, switchMap } from 'rxjs';
import { TemplateObservable } from '@shared/classes/template-observable';
import { EntityService } from '@shared/services/entity.service';
import { buildFilterStr } from '@app/app.utils';
import { API_DATETIME_FORMAT_Z, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { catchError, filter, map, startWith, tap } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
    isLoading = new TemplateObservable(true);

    readAnnouncementNumber$ = new Subject();

    announcements$ = this.entityService
        .getEntityList$(
            'notifications.announcement',
            '',
            '',
            {
                filter: buildFilterStr({
                    dt_before_active__gte: moment.utc().format(API_DATETIME_FORMAT_Z),
                }),
            },
            0,
            1000,
        )
        .pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    topAnnouncement$ = combineLatest([this.authService.user$, this.announcements$]).pipe(
        map(([user, announcements]) => {
            if (!user || !announcements.list) return null;
            const newAnnouncements = announcements.list
                .filter((v) => v.$snapshot.number > user.$snapshot.announcement_number)
                .sort((a, b) => b.$snapshot.number - a.$snapshot.number);
            return newAnnouncements.length ? newAnnouncements[0] : null;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    readAnnouncement$ = combineLatest([this.readAnnouncementNumber$, this.authService.user$]).pipe(
        filter(([readAnnouncementNumber, user]) => readAnnouncementNumber > 0),
        switchMap(([readAnnouncementNumber, user]) => {
            return this.entityService
                .changeEntityProperty('authing.user', user.id, {
                    name: 'announcement_number',
                    value: readAnnouncementNumber,
                })
                .pipe(
                    tap((v) => {
                        this.readAnnouncementNumber$.next(0);
                    }),
                    switchMap((v) => this.entityService.getEntity$('authing.user', user.id, true)),
                    catchError((e) => of(null)),
                );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private srv: SrvService,
        private entityService: EntityService,
        private authService: AuthService,
    ) {
        this.readAnnouncement$.subscribe();
        this.announcements$.subscribe();
    }
}
