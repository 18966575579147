import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EntData } from '@shared/models/srv.types';
import { NotificationService } from '@shared/services/notification.service';

@Component({
    selector: 'app-notification-header',
    templateUrl: './notification-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationHeaderComponent implements OnInit {
    @Input()
    notifications: EntData[];

    constructor(public notificationService: NotificationService) {}

    ngOnInit() {}

    changeState(ids: string[], state) {
        this.notificationService.changeState(ids, state);
    }

    changeImportance(id: string, importance) {
        this.notificationService.changeImportance(id, importance);
    }

    readAll(notifications) {
        this.changeState(
            notifications.map((v) => v.id),
            'read',
        );
    }
}
