import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'strReplace',
})
export class StrReplacePipe implements PipeTransform {
    constructor() {}

    transform(entity: any, strTemplate: string) {
        const defaultStr = entity.__str__ || 'ID: ' + entity.id;
        return strTemplate ? strTemplate.replace(/\${(\w+)}/g, (_, v) => entity[v]) : defaultStr;
    }
}
