<ng-container *transloco="let t">
    <sw-chip
        *ngIf="status$ | async as status"
        fill="clear"
        class="sw-status-save"
    >
        <sw-icon
            name="circle-small-solid"
            [color]="
                status.status === 'success'
                    ? 'success'
                    : status.status === 'error'
                    ? 'danger'
                    : 'primary'
            "
        ></sw-icon>
        <span>
            {{ t('form.savingStatus.' + status.status) }}
            <ng-container *ngIf="status.status === 'success'">
                {{ status.time | localDate: 'time' }}
            </ng-container>
        </span>
    </sw-chip>
</ng-container>
