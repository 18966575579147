<ng-container *ngIf="isPreview">
    <div
        class="sw-gallery-preview"
        [ngClass]="cssClass"
    >
        <sw-skeleton
            [animated]="true"
            [inMedia]="true"
        ></sw-skeleton>
    </div>
</ng-container>
