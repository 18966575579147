import { Inject, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    forkJoin,
    from,
    fromEvent,
    merge,
    Observable,
    of,
    pairwise,
    shareReplay,
    Subject,
    switchMap,
    throwError,
    withLatestFrom,
    zip,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
    catchError,
    tap,
    map,
    filter,
    startWith,
    take,
    debounceTime,
    combineLatestWith,
} from 'rxjs/operators';
import { SrvService } from '@shared/services/srv.service';
import { Toast, ToastService } from '@shared/services/toast.service';
import { AuthToken, EntData, EntList } from '@shared/models/srv.types';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/storage.service';
import {
    MOBILE_ROLES,
    ROLE,
    SHARE_REPLAY_SETTINGS,
    SUCCESS_INVITE_PAGE,
    SUCCESS_LOGIN_PAGE,
} from '@app/configuration.service';
import { TemplateObservable } from '@shared/classes/template-observable';
import { EntityService } from '@shared/services/entity.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptoService } from '@crypto/crypto.service';
import jwt_decode from 'jwt-decode';
import { ModalService } from '@shared/services/modal.service';

export const AUTH_PAGE = '/login';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    token = new TemplateObservable('');

    private updateUser$ = new BehaviorSubject(undefined);
    private updateOrganization$ = new Subject();
    isLogouting = false;

    userRequest$: Observable<EntData> = null;

    orgList$ = new BehaviorSubject(null);

    changeRole$ = new Subject();

    private _changingRole = false;

    private _isFirstRedirect = true;

    auth$ = this.srv.commonGetRequest('auth').pipe(
        tap((response) => {
            if (!this.token._ && response.token) {
                localStorage.setItem('access_token', response.token);
                this.userRequest$ = null;
                this.token.set(response.token);
            }
        }),
        catchError((e) => {
            const selectOrg: any = e?.error?.errors?.find(
                (v) => v.code === 'NEXT' || v.code === 'SELECT_ORGANIZATION',
            );
            if (selectOrg) {
                this.orgList$.next(selectOrg.meta.actions);
                this.router.navigate(['/login/organization']);
            } else {
                this.resetToken();
            }
            return of(null);
        }),
    );

    user$: Observable<EntData> = merge(this.updateUser$).pipe(
        filter((v) => v !== undefined),
        switchMap((result) => {
            return result === null || result === ''
                ? of(null)
                : typeof result === 'string' || result instanceof String
                ? this.auth$.pipe(shareReplay(1))
                : of(result);
        }),
        withLatestFrom(this.updateUser$),
        switchMap(([data, updateUser]) => {
            if ((!this.userRequest$ && data) || updateUser === 'socket' || updateUser === 'role') {
                this.userRequest$ = this.entityService
                    .getEntity$('authing.user', data.user_id, updateUser === 'socket')
                    .pipe(
                        map((r) => {
                            this.cookieService.delete('bazis_auth', '/');
                            return r;
                        }),
                        catchError((error) => {
                            this.resetToken();
                            return of(null);
                        }),
                        shareReplay(SHARE_REPLAY_SETTINGS),
                    );
            }
            return data ? this.userRequest$ : of(null);
        }),
        catchError((error) => {
            return of(null);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    userId$: Observable<string> = this.user$.pipe(
        startWith(undefined),
        pairwise(),
        filter(([prevUser, currentUser]) =>
            !!prevUser && !!currentUser
                ? prevUser.id !== currentUser.id
                : !currentUser || prevUser !== currentUser,
        ),
        map(([prevUser, user]) => (user ? user.id : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    roleData$: Observable<EntData> = this.user$.pipe(
        startWith(undefined),
        pairwise(),
        // filter(([prevUser, currentUser]) =>
        //     !!prevUser && !!currentUser
        //         ? prevUser.$snapshot.role_current.id !== currentUser.$snapshot.role_current.id
        //         : prevUser !== currentUser,
        // ),
        switchMap(([prevUser, currentUser]) =>
            currentUser
                ? this.entityService.getEntity$(
                      currentUser.$snapshot.role_current.type,
                      currentUser.$snapshot.role_current.id,
                  )
                : of(null),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    role$: Observable<string> = this.roleData$.pipe(
        map((role: EntData) => (role ? role.$snapshot.slug : ROLE.anonymous)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    isSystemRole$: Observable<boolean> = this.roleData$.pipe(
        map((role: EntData) => (role ? role.$snapshot.is_system : false)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    changingRole$ = this.changeRole$.pipe(
        switchMap(({ roleId, userId }) => {
            this._changingRole = true;
            return this.entityService.changeEntityProperty('authing.user', userId, {
                type: 'permit.role',
                name: 'role_current',
                value: roleId,
            });
        }),

        tap((user) => {
            this._changingRole = false;
            this.storageService.clearStorage();
            localStorage.setItem(
                'event',
                JSON.stringify({ eventName: 'roleChanged', datetime: new Date().getTime() }),
            );
            //this.storageService.setItem('authing.user', user, user.id);
            this.calculateToken(null, 'role');
            this.redirectToHomePage();
        }),
        catchError((error) => {
            this._changingRole = false;
            return throwError(error);
        }),
    );

    accountType$ = this.role$.pipe(
        filter((v) => !!v && !this._changingRole),
        map((role) => {
            const arr = [...MOBILE_ROLES, ROLE.anonymous, ROLE.base, ROLE.director];
            if (arr.indexOf(role) > -1) return 'none';
            if (ROLE.referral === role) return 'partner';
            return ROLE.wasteGenerator === role ? 'customer' : 'executor';
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    organizationId$ = this.user$.pipe(
        startWith(undefined),
        pairwise(),
        filter(([prevUser, currentUser]) =>
            !!prevUser && !!currentUser
                ? prevUser.$snapshot.organization.id !== currentUser.$snapshot.organization.id
                : prevUser !== currentUser,
        ),
        map(([prevUser, currentUser]) =>
            currentUser ? currentUser.$snapshot.organization?.id : null,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    organizationInfo$ = this.organizationId$.pipe(
        switchMap((organizationId) =>
            organizationId ? this.entityService.getOrganizationEntity$(organizationId) : of(null),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    organizationInfoId$: Observable<string> = merge(this.organizationInfo$).pipe(
        startWith(undefined),
        pairwise(),
        filter(([prevOrgInfo, currentOrgInfo]) =>
            !!prevOrgInfo && !!currentOrgInfo
                ? prevOrgInfo.id !== currentOrgInfo.id
                : prevOrgInfo !== currentOrgInfo,
        ),
        map(([prevOrgInfo, currentOrgInfo]) => currentOrgInfo),
        map((organizationInfoId: EntData) => (organizationInfoId ? organizationInfoId.id : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    organizationInfoFull$: Observable<EntData> = this.organizationInfoId$.pipe(
        switchMap((organizationInfoId: string) =>
            organizationInfoId
                ? this.entityService.getEntity$(
                      'organization.organization_info',
                      organizationInfoId,
                  )
                : of(null),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updateAgencyContract$ = new Subject();

    agencyContract$ = merge(this.updateAgencyContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && (role === ROLE.wasteRecycling || role === ROLE.wasteTransportation)
                ? this.srv.fetchAllEntities$('long_term.agency_contract', '', {
                      filter: `org_owner=${organizationId}`,
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('long_term.agency_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updatePartnerContract$ = new Subject();

    partnerContract$ = merge(this.updatePartnerContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && role === ROLE.referral
                ? this.srv.fetchAllEntities$('long_term.partner_contract', '', {
                      filter: `org_owner=${organizationId}`,
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('long_term.partner_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    defaultPageRedirect$ = this.role$.pipe(
        debounceTime(0),
        withLatestFrom(this.user$),
        startWith([null, null]),
        pairwise(),
        filter(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            return prevUser?.id !== currentUser?.id;
        }),
        tap(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];

            if (
                currentUser &&
                !currentUser.$snapshot.raw_password &&
                currentUser.$snapshot.created_type_auth !== 'cert' &&
                currentUser.id !== prevUser?.id
            ) {
                this.router.navigate(['/login/init-password']);
                this._isFirstRedirect = false;
                return;
            }
            if (location.pathname.indexOf(AUTH_PAGE) > -1 && !currentUser) return;
            this.redirectToDefaultPage(currentRole, currentUser);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updatingOrganization$ = this.updateOrganization$.pipe(
        withLatestFrom(this.organizationId$, this.organizationInfoId$),
        switchMap(([update, orgId, orgInfoId]) =>
            forkJoin([
                this.entityService.getEntity$('organization.organization_info', orgInfoId, true),
                this.entityService.getOrganizationEntity$(orgId, true),
            ]),
        ),
    );

    roles$: Observable<EntData[]> = this.entityService.getAllEntitiesList$('permit.role').pipe(
        map((v) => (v ? v.list : null)),
        shareReplay(),
    );

    hasPartnerRole$: Observable<boolean | string> = combineLatest([this.roles$, this.user$]).pipe(
        map(([roles, user]) => {
            if (!user) return false;
            const partnerRole = roles.find((v) => v.$snapshot.slug === ROLE.referral);
            if (!partnerRole) return false;
            if (user.$snapshot.roles.find((role) => role.id === partnerRole.id))
                return partnerRole.id;
            return false;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    isPartner$: Observable<boolean> = this.role$.pipe(
        map((role) => role === ROLE.referral),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    canBecomePartner$: Observable<boolean> = this.role$.pipe(
        map(
            (role) =>
                [ROLE.anonymous, ROLE.wasteOperator, ROLE.driver, ROLE.wasteAcceptor].indexOf(
                    role,
                ) === -1,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private entityService: EntityService,
        private storageService: StorageService,
        private srv: SrvService,
        private http: HttpClient,
        private toast: ToastService,
        private router: Router,
        private cookieService: CookieService,
        private cryptoService: CryptoService,
        private modalService: ModalService,
    ) {
        this.updatingOrganization$.subscribe();
    }

    calculateToken(authResult = null, route = '') {
        if (authResult) {
            localStorage.setItem('access_token', authResult.token);
        }

        if (route === SUCCESS_INVITE_PAGE) localStorage.removeItem('access_token');

        const token = localStorage.getItem('access_token');

        if (!token) {
            localStorage.removeItem('notifications');
        }
        this.userRequest$ = null;
        this.token.set(token);
        this.updateUser$.next(authResult || route || token);
    }

    updateToken(token) {
        localStorage.setItem('access_token', token);
        this.token.set(token);
    }

    resetToken() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('notifications');
        this.userRequest$ = null;
        this.cookieService.delete('bazis_auth', '/');
        this.token.set('');
    }

    // redirect to default page
    redirectToDefaultPage(role = null, user) {
        switch (role) {
            case ROLE.anonymous:
            case ROLE.base:
                // do not redirect from public pages to user-initial
                const regexp = /^\/(faq|about|legal\-documents|referral){1}(\/.*)*$/;
                if (!regexp.test(location.pathname)) {
                    this.router.navigate(['/user-initial']);
                }
                break;
            case ROLE.driver:
            case ROLE.wasteAcceptor:
                this.router.navigate(['/mobile-role']);
                break;
            case ROLE.referral:
                if (location.pathname === '/') {
                    this.router.navigate(['/referral/summary']);
                    break;
                }
            default:
                if (location.pathname.indexOf('access-denied') > -1 && !this._isFirstRedirect) {
                    this.router.navigate(['/']);
                } else if (
                    location.pathname.indexOf(AUTH_PAGE) > -1
                    // to prevent redirect on home page when first entered page was access - denied
                ) {
                    if (user.$snapshot.roles.length === 1) this.router.navigate(['/']);
                    else this.router.navigate(['/login/select-role']);
                }
        }
        this._isFirstRedirect = false;
    }

    redirectToHomePage() {
        this.router.navigate(['/']);
    }

    authByPass$(
        username: string,
        password: string,
        o: {
            mainMsg: string;
            errMsg?: string;
            hasNoty?: boolean;
        },
    ): Observable<AuthToken | Toast> {
        return this.getAuthToken$().pipe(
            switchMap((token) =>
                this.srv.sendFormRequest$<AuthToken>(
                    'authing/password',
                    {
                        username,
                        password,
                    },
                    'post',
                    'application/json',
                ),
            ),
            tap((authResult: AuthToken) => {
                this.calculateToken(authResult);
            }),
            map((authResult: AuthToken) => authResult),
            catchError((e) => {
                let message = o.errMsg || 'Сервис недоступен';
                if (e.error) {
                    if (e.error.errors) {
                        message = e.error.errors.reduce(
                            (acc, v) =>
                                `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${
                                    v.detail || v.code || ''
                                }`,
                            '',
                        );
                    }
                }
                if (o.hasNoty) {
                    return of(
                        this.toast.create({
                            header: 'Ошибка доступа',
                            message: `${message}`,
                            color: 'danger',
                            position: 'middle',
                        }),
                    );
                }
                throw e;
            }),
        );
    }

    authBySignature$(certificate) {
        return this.getAuthToken$().pipe(
            map((token) => {
                const parsedToken: any = jwt_decode(token);
                return parsedToken?.sub;
            }),
            switchMap((str: string) =>
                from(
                    this.cryptoService.getSignForDataWithCertificate(window.btoa(str), certificate),
                ),
            ),
            switchMap((signature) => this.srv.createEntity$('authing.certificate', { signature })),
            tap((authResult: any) => {
                this.calculateToken(authResult);
            }),
            map((authResult: AuthToken) => authResult),
        );
    }

    esiaAuth() {
        location.href = `${location.protocol}//${location.host}/api/web/v1/authing/esia/request_user/?redirect_url=${location.protocol}//${location.host}${SUCCESS_LOGIN_PAGE}`;
    }

    selectOrganization(url) {
        location.href = `${location.protocol}//${location.host}${url}?redirect_url=${location.protocol}//${location.host}${SUCCESS_LOGIN_PAGE}`;
    }

    logout$() {
        this.isLogouting = true;
        localStorage.setItem(
            'event',
            JSON.stringify({ eventName: 'logout', datetime: new Date().getTime() }),
        );
        return this.srv.sendFormRequest$('authing/logout', {}, 'post', 'application/json').pipe(
            map((v) => {
                this.isLogouting = false;
                this.afterLogout();
                return 'success';
            }),
            catchError((e) => {
                this.isLogouting = false;
                this.afterLogout();
                return 'success';
            }),
        );
    }

    logout() {
        this.logout$().pipe(take(1)).subscribe();
    }

    logoutAndRedirect() {
        return this.logout$().pipe(
            map((v) => {
                this.redirectToHomePage();
                return 'logout';
            }),
            catchError((e) => {
                this.redirectToHomePage();
                return 'logout';
            }),
        );
    }

    afterLogout() {
        if (this.isLogouting) return;
        this.modalService.dismiss();
        this.resetToken();
        this.calculateToken();
        this.storageService.clearStorage();
    }

    redirectToLoginWithReturnPath(url = location.pathname) {
        if (location.pathname.indexOf(AUTH_PAGE) === -1) {
            this.router.navigate([AUTH_PAGE], {
                // queryParams: {
                //     returnUrl: url,
                // },
            });
        }
    }

    getAuthToken$() {
        return this.srv.commonGetRequest('auth').pipe(
            map((r) => r.token),
            catchError((e) => {
                if (!e?.error.errors || !e.error.errors[0]?.meta?.token) return of(null);
                return of(e.error.errors[0].meta.token);
            }),
        );
    }

    getAuthTypes$() {
        this.cookieService.delete('bazis_auth', '/');
        return this.srv.commonGetRequest('auth').pipe(
            map((r) => {}),
            catchError((e) => {
                if (!e?.error.errors || !e.error.errors[0]?.meta?.token) return of({});
                return of(
                    e.error.errors[0].meta.actions.reduce(
                        (acc, current) => ({ ...acc, [current.code]: true }),
                        {},
                    ),
                );
            }),
        );
    }

    changeRole({ roleId, userId }) {
        this.changeRole$.next({ roleId, userId });
    }

    needUpdateUser(data = null) {
        this.updateUser$.next(data);
    }

    needUpdateOrganization() {
        this.updateOrganization$.next(true);
    }
}
