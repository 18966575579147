<ng-container *transloco="let t">
    <div class="sw-file">
        <sw-badge
            color="danger"
            size="medium"
        >
            {{ extention }}
        </sw-badge>
        <span
            *ngIf="srcFile"
            class="sw-list sw-list_bullet sw-list_h"
        >
            <span class="sw-item">
                <sw-link
                    borderStyle="none"
                    [href]="hasLink ? srcFile : undefined"
                    [target]="hasLink ? '_blank' : undefined"
                    [title]="t(fileNameKey, fileNameParams) || file.$snapshot.name"
                >
                    <span
                        class="swh-ellipsis"
                        [style.--width]="widthName"
                    >
                        {{ t(fileNameKey, fileNameParams) || file.$snapshot.name }}
                    </span>
                </sw-link>
            </span>
            <span
                class="sw-item"
                *ngIf="showFileSize"
            >
                <span>
                    {{ file.$snapshot.size | fileSize }}
                </span>
                <span
                    *ngIf="tooltipKey"
                    #tooltipTarget
                >
                    &nbsp;
                    <sw-icon
                        name="interrogation"
                        size="xs"
                    ></sw-icon>
                    <sw-tooltip
                        [reference]="tooltipTarget"
                        placement="top-end"
                        [isStatic]="true"
                        [offset]="[8, 4]"
                        class="swh-popper-z-index"
                    >
                        {{ t(tooltipKey, tooltipParams) }}
                    </sw-tooltip>
                </span>
            </span>
        </span>
    </div>

    <!-- show signatures if they exist in file entity and required by parent component -->
    <sw-button
        *ngIf="showSignatures && file.$snapshot.signatures?.length > 0"
        fill="clear"
        (click)="openSignatureDetails(file.$snapshot.name)"
    >
        <sw-icon
            slot="icon-only"
            src="/assets/icons/other/key.svg"
        ></sw-icon>
    </sw-button>
</ng-container>
