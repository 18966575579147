<ng-container *transloco="let t">
    <footer
        class="sw-footer sw-container"
        *ngIf="{ user: userInfo$ | async } as data"
    >
        <a
            class="swh-float-sm-left"
            routerLink="/"
        >
            <sw-logo
                type="dark"
                short
                grayscale
            ></sw-logo>
        </a>

        <hr class="swh-hide-sm-up" />

        <sw-grid class="sw-footer__menu">
            <sw-row>
                <sw-col
                    sizeXxl="3"
                    sizeLg="5"
                    sizeSm="6"
                    sizeXs="12"
                >
                    <div
                        textSize="l"
                        textMedium
                    >
                        {{ t('footer.sw') }}
                    </div>
                    <ul class="sw-list">
                        <li class="sw-item">
                            <a routerLink="/about">{{ t('footer.about') }}</a>
                        </li>
                        <!--                        <li class="sw-item swh-underconstruction">-->
                        <!--                            <a-->
                        <!--                                routerLink="/"-->
                        <!--                                [title]="t(sectionUnderConstruction)"-->
                        <!--                                >{{ t('footer.safety') }}</a-->
                        <!--                            >-->
                        <!--                        </li>-->
                        <!--                        <li class="sw-item swh-underconstruction">-->
                        <!--                            <a-->
                        <!--                                routerLink="/"-->
                        <!--                                [title]="t(sectionUnderConstruction)"-->
                        <!--                                >{{ t('footer.news') }}</a-->
                        <!--                            >-->
                        <!--                        </li>-->
                        <!--                        <li class="sw-item swh-underconstruction">-->
                        <!--                            <a-->
                        <!--                                routerLink="/"-->
                        <!--                                [title]="t(sectionUnderConstruction)"-->
                        <!--                                >{{ t('footer.conditions') }}</a-->
                        <!--                            >-->
                        <!--                        </li>-->
                        <li class="sw-item">
                            <a routerLink="/about/contacts">{{ t('footer.contacts') }}</a>
                        </li>
                        <li class="sw-item">
                            <a routerLink="/referral">{{ t('footer.referralProgram') }}</a>
                        </li>
                        <li class="sw-item">
                            <a routerLink="/about/announcements">{{ t('footer.announcements') }}</a>
                        </li>
                    </ul>
                </sw-col>
                <sw-col
                    sizeXxl="3"
                    sizeLg="5"
                    sizeSm="6"
                    sizeXs="12"
                >
                    <div
                        textSize="l"
                        textMedium
                    >
                        {{ t('footer.support') }}
                    </div>
                    <ul class="sw-list">
                        <!-- <li class="sw-item">
                            <sw-icon
                                name="comment-solid"
                                size="small"
                            ></sw-icon>
                            <a routerLink="/">{{ t('footer.contactWithUs') }}</a>
                        </li> -->
                        <li class="sw-item">
                            <sw-icon
                                name="call-history-solid"
                                size="small"
                            ></sw-icon>
                            <a [href]="'tel:' + contacts.phone">{{ contacts.phone }}</a>
                        </li>
                        <li class="sw-item">
                            <sw-icon
                                name="envelope"
                                size="small"
                            ></sw-icon>
                            <a
                                class="swh-text-underline"
                                [href]="'mailto:' + contacts.email"
                                >{{ contacts.email }}</a
                            >
                        </li>
                        <li class="sw-item">
                            <sw-icon
                                name="paper-plane"
                                size="small"
                            ></sw-icon>
                            <a
                                [href]="telegram"
                                target="_blank"
                                >{{ t('footer.telegram') }}</a
                            >
                        </li>
                    </ul>
                </sw-col>
                <sw-col
                    sizeXxl="3"
                    sizeLg="5"
                    sizeSm="6"
                    sizeXs="12"
                >
                    <div
                        textSize="l"
                        textMedium
                    >
                        {{ t('footer.infoCenter') }}
                    </div>
                    <ul class="sw-list">
                        <!-- <li class="sw-item">
                            <a routerLink="/">{{ t('footer.rules') }}</a>
                        </li> -->
                        <!-- <li class="sw-item">
                            <a routerLink="/">{{ t('footer.dictionary') }}</a>
                        </li> -->
                        <li class="sw-item">
                            <a
                                [routerLink]="userAgreement"
                                target="_blank"
                                >{{ t('footer.legalInformation') }}</a
                            >
                        </li>
                        <!-- <li class="sw-item">
                            <a routerLink="/">{{ t('footer.personalData') }}</a>
                        </li> -->
                        <li class="sw-item">
                            <a
                                routerLink="/faq"
                                target="_blank"
                                >{{ t('footer.faq') }}</a
                            >
                        </li>
                    </ul>
                </sw-col>
                <sw-col
                    sizeXxl="3"
                    sizeLg="5"
                    sizeSm="6"
                    sizeXs="12"
                    *ngIf="{ role: role$ | async } as data"
                >
                    <div
                        textSize="l"
                        textMedium
                    >
                        {{ t('footer.profile') }}
                    </div>
                    <ul
                        *ngIf="data.role"
                        class="sw-list"
                    >
                        <ng-container *ngIf="data.role !== ROLE.anonymous; else tplNoRole">
                            <li
                                class="sw-item"
                                [ngClass]="item.underconstruction ? 'swh-underconstruction' : ''"
                                [title]="item.underconstruction ? t(sectionUnderConstruction) : ''"
                                *ngFor="let item of userMenu[data.role]"
                            >
                                <a [routerLink]="[item.url]">{{ t(item.titleKey) }}</a>
                            </li>
                        </ng-container>
                        <ng-template #tplNoRole>
                            <li class="sw-item">
                                <a [routerLink]="'/login'">{{ t('footer.login') }}</a>
                            </li>
                        </ng-template>
                    </ul>
                </sw-col>
                <sw-col
                    sizeXxl="3"
                    sizeLg="5"
                    sizeSm="6"
                    sizeXs="12"
                >
                    <div
                        textSize="l"
                        textMedium
                    >
                        {{ t('footer.mobileApp') }}
                    </div>
                    <ul class="sw-list">
                        <li class="sw-item">
                            <a
                                href="https://play.google.com/store/apps/details?id=ru.big3.smartwaste.agentapp"
                                target="_blank"
                            >
                                <img
                                    src="/assets/images/google-play.svg"
                                    alt="google play"
                                />
                            </a>
                        </li>
                        <li class="sw-item">
                            <a
                                href=""
                                [hidden]="true"
                            >
                                <img
                                    src="/assets/images/app-store.svg"
                                    alt="app store"
                                />
                            </a>
                        </li>
                    </ul>
                </sw-col>
            </sw-row>
        </sw-grid>

        <hr />

        <div class="sw-footer__footer">
            <ul class="sw-list sw-list_bullet sw-list_h">
                <li class="sw-item">
                    <span>{{ t('footer.copyright') }}, {{ now | date: 'yyyy' }}</span>
                </li>
                <li class="sw-item">
                    <a
                        [routerLink]="userAgreement"
                        target="_blank"
                        >{{ t('footer.userAgreement') }}</a
                    >
                </li>
                <li class="sw-item">
                    <a
                        [routerLink]="privacyPolicy"
                        target="_blank"
                        >{{ t('footer.privacyPolicy') }}</a
                    >
                </li>
            </ul>
            <hr class="swh-hide-xl-up" />
            <ng-container *ngIf="{ lang: langChanges$ | async } as langData">
                <div class="sw-footer__development">
                    <span>{{ t('footer.development') }}</span>
                    <a
                        href="https://big3.ru/"
                        target="_blank"
                    >
                        <img
                            *ngIf="langData.lang === 'ru'"
                            src="/assets/logos/logo_b3.svg"
                            alt="Большая Тройка"
                        />
                        <img
                            *ngIf="langData.lang === 'en'"
                            src="/assets/logos/logo_b3-en.svg"
                            alt="Big Three"
                        />
                    </a>
                </div>
            </ng-container>
        </div>
    </footer>
</ng-container>
