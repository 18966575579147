<div
    class="sw-modal-wrap"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            items: itemsToSign$ | async,
            filesIsUploaded: filesIsUploaded.$ | async
        } as data"
    >
        <!-- <ng-container *ngIf="!(currentStep === 3 && mqSrv.matchBreakpoint('xs'))"> -->
        <div
            class="sw-modal__header_shadow"
            *ngIf="currentStep < 4"
        >
            <h4>
                {{ t(data.items.length > 1 ? 'crypto.docsSigning' : 'crypto.docSigning') }}
            </h4>
        </div>
        <!-- </ng-container> -->

        <!--    STEP 1-->
        <ng-container *ngIf="currentStep === 1">
            <div class="sw-modal__content">
                <ng-container *ngIf="filesIsUploaded._ !== data.items.length">
                    <app-default-loader
                        [message]="
                            t(data.items.length > 1 ? 'crypto.loadedDocs' : 'crypto.loadedDoc')
                        "
                    ></app-default-loader>
                </ng-container>
                <ng-container *ngIf="filesIsUploaded._ === data.items.length">
                    <p
                        class="swh-margin-bottom"
                        coef="5"
                    >
                        {{ t(data.items.length > 1 ? 'crypto.checkDocs' : 'crypto.checkDoc') }}
                    </p>
                    <p
                        class="swh-margin-bottom"
                        textMedium
                        textSize="m"
                    >
                        <span *ngIf="data.items.length > 1">
                            {{ t('crypto.files', { count: data.items.length }) }}:
                        </span>
                        <span *ngIf="data.items.length === 1"> {{ t('crypto.file') }}: </span>
                    </p>
                </ng-container>

                <div *ngFor="let item of data.items">
                    <ng-container *ngIf="item.document$ | async as doc">
                        <sw-accordion
                            [isOpen]="false"
                            [hasAngleIcon]="false"
                            *ngIf="filesIsUploaded._ === data.items.length"
                        >
                            <div
                                slot="title"
                                class="swh-align-items-center"
                            >
                                <app-file-inline
                                    [file]="doc"
                                    [hasLink]="false"
                                ></app-file-inline>
                            </div>

                            <div
                                slot="btns"
                                class="swh-flex"
                            >
                                <ng-container
                                    *ngIf="!mqSrv.matchBreakpoint('xs'); else tplMobileBtns"
                                >
                                    <sw-button
                                        fill="clear"
                                        [href]="doc.$snapshot.file_upload_url"
                                        [download]="doc.$snapshot.name"
                                        target="_blank"
                                        color="action"
                                    >
                                        <sw-icon
                                            slot="icon-only"
                                            name="download"
                                        ></sw-icon>
                                    </sw-button>
                                    <sw-button fill="clear">
                                        <sw-icon
                                            slot="icon-only"
                                            name="print"
                                        ></sw-icon>
                                    </sw-button>
                                </ng-container>
                                <ng-template #tplMobileBtns>
                                    <div class="sw-dropdown sw-dropdown_action">
                                        <sw-button
                                            class="sw-dropdown__trigger"
                                            fill="clear"
                                            (click)="
                                                item.openDropdownMenu = !item.openDropdownMenu;
                                                $event.stopPropagation()
                                            "
                                        >
                                            <sw-icon
                                                slot="icon-only"
                                                src="/assets/icons/other/menu-dots-vertical.svg"
                                                size="small"
                                            ></sw-icon>
                                        </sw-button>

                                        <div
                                            class="sw-dropdown__body"
                                            *ngIf="item.openDropdownMenu"
                                        >
                                            <div class="sw-list sw-list_control-dd">
                                                <a
                                                    [href]="doc.$snapshot.file_upload_url"
                                                    [download]="doc.$snapshot.name"
                                                    target="_blank"
                                                    class="sw-item"
                                                >
                                                    {{ t('action.download') }}
                                                </a>
                                                <a
                                                    [href]="doc.$snapshot.file_upload_url"
                                                    class="sw-item"
                                                    target="_blank"
                                                >
                                                    {{ t('action.print') }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                            <iframe
                                #iframePdf
                                slot="body"
                                [src]="
                                    (doc.$snapshot.isPdf
                                        ? doc.$snapshot.file_upload_url
                                        : doc.$snapshot.nonPdfUrl
                                    ) | safe : 'resourceUrl'
                                "
                                [attr.type]="doc.$snapshot.isPdf ? 'application/pdf' : ''"
                                (load)="onloadFrame(iframePdf)"
                            ></iframe>
                        </sw-accordion>
                    </ng-container>
                </div>
            </div>

            <div class="sw-modal__footer">
                <sw-toolbar>
                    <div slot="start">
                        <sw-button
                            [fill]="mqSrv.matchBreakpoint('xs') ? 'outline' : 'clear'"
                            color="action"
                            (click)="close()"
                        >
                            {{ t('action.cancel') }}
                        </sw-button>
                    </div>
                    <div slot="end">
                        <div
                            [ngClass]="{
                                'swh-flex swh-align-items-center sw-row-elements':
                                    mqSrv.matchBreakpoint('sm')
                            }"
                        >
                            <span
                                textSize="m"
                                class="sw-text-action swh-hide-sm-down"
                                color="success"
                                >{{ t('crypto.allRight') }},&nbsp;</span
                            >

                            <sw-button
                                color="action"
                                [disabled]="data.filesIsUploaded !== data.items.length"
                                (click)="currentStep = currentStep + 1"
                            >
                                {{ t('action.sign') }}
                            </sw-button>
                        </div>
                    </div>
                </sw-toolbar>
            </div>
        </ng-container>

        <!--    STEP 2-->
        <ng-container *ngIf="currentStep === 2">
            <div class="sw-modal__content">
                <div
                    class="swh-margin-bottom"
                    textMedium
                >
                    <span *ngIf="data.items.length === 1">{{ t('crypto.file') }}</span>
                    <span *ngIf="data.items.length > 1">{{
                        t('crypto.remainToSign', {
                            remain: remainToSign$ | async,
                            total: data.items.length
                        })
                    }}</span>
                </div>
                <ul class="sw-list">
                    <li
                        class="sw-item"
                        *ngFor="let item of data.items"
                    >
                        <ng-container *ngIf="item.document$ | async as doc">
                            <app-file-inline [file]="doc"></app-file-inline>
                        </ng-container>
                    </li>
                </ul>

                <ng-container *ngIf="showCertificatesBlock">
                    <ng-container *ngIf="certificates$ | async">
                        <app-crypto-certificates
                            *ngIf="!pluginError"
                            [(certificate)]="certificate"
                            [certificates$]="certificates$"
                        ></app-crypto-certificates>
                        <app-crypto-info *ngIf="pluginError"> </app-crypto-info>
                    </ng-container>
                </ng-container>
            </div>

            <div class="sw-modal__footer">
                <sw-toolbar>
                    <sw-button
                        slot="start"
                        [fill]="mqSrv.matchBreakpoint('xs') ? 'outline' : 'clear'"
                        color="action"
                        (click)="currentStep = currentStep - 1"
                    >
                        {{ t('action.back') }}
                    </sw-button>
                    <ng-container *ngIf="certificate">
                        <div slot="end">
                            <div
                                [ngClass]="{
                                    'swh-flex swh-align-items-center sw-row-elements':
                                        mqSrv.matchBreakpoint('sm')
                                }"
                            >
                                <span
                                    textSize="m"
                                    class="sw-text-action swh-hide-sm-down"
                                    color="success"
                                    >{{ t('crypto.allRight') }},</span
                                >
                                <sw-button
                                    color="action"
                                    (click)="sign()"
                                >
                                    {{ t('action.sign') }}
                                </sw-button>
                            </div>
                        </div>
                    </ng-container>
                </sw-toolbar>
            </div>
        </ng-container>

        <!--    STEP 3-->
        <ng-container *ngIf="currentStep === 3">
            <div class="sw-loader sw-loader_signing">
                <svg class="sw-loader__spinner">
                    <circle
                        cx="25"
                        cy="25"
                        r="14"
                        fill="none"
                        stroke-width="1"
                    ></circle>
                </svg>
                <p>
                    {{ t('crypto.signLoading') }}
                </p>
            </div>
            <div class="sw-backdrop swh-hide-sm-up"></div>
        </ng-container>
        <!--    <div>signed {{ signed | json }}</div>-->
    </ng-container>

    <ng-container
        *ngIf="{
            signEntity: signEntity$ | async
        } as data"
    ></ng-container>
</div>
