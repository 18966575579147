import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, shareReplay } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { buildFilterStr, convertMbToBytes, flipObject } from '@app/app.utils';
import { SrvService } from '@shared/services/srv.service';
import { EntityService } from '@shared/services/entity.service';
import { IsActiveMatchOptions } from '@angular/router';

export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss+00:00';
export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
export const VAT = 20;

export const SUCCESS_LOGIN_PAGE = '/success-login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

export const MAX_FILE_SIZE = convertMbToBytes(10); // 10мб

export const FAQ_TG_CHANNEL_LINK = 'https://t.me/Smart_Waste';
export const TG_BOT = '@smartwaste_bot';
export const TG_COMMAND = '/start';

export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
    agencyContract: '/legal-documents/agency_contract',
    partnerContract: '/legal-documents/partner_contract',
};

export const UNDERCONSTRUCTION_TITLE = {
    feature: 'title.featureUnderconstruction',
    section: 'title.sectionUnderconstruction',
};

export const OPERATOR_REPORT_SETTINGS = {
    startYear: 2022,
    startMonth: 11,
    filterTimeCorrection: -3,
};

export const ORDER_BID_VALIDATION_PARAMS = {
    maxDiffExportAndDelivery: 7, // (days) Доставка тары не ранее чем за неделю до минимальной даты вывоза отхода (position_item)
    minCarrierSearchDiffWithExecutionDate: 2, // (days) окончание сбора предложений не позднее первой даты исполнения (доставки тары или вывоза отходов) минус 2 дня.
    maxCarrierDiffWithCurrentDate: 10, // (days) период сбора предложений должен быть не дольше 10 дней от текущей даты.
    minCarrierSearchDiffWithCurrentDate: 1, // (hours) Также период сбора предложений должен быть не менее 1 часа.
    allExportLimit: 7, // (days) date_waste_export во всех waste_position_item всей заявки (bid) должны укладываться в 7 дней
};

// configuration is set up in standard angular date pipe format
const BASIC_DATETIME_LOCALE = {
    shortDate: 'dd.MM.y',
    shortDateShortWeekDay: 'dd.MM.y, EEEEEE',
    shortDateMediumWeekDay: 'dd.MM.y, EEE',
    shortDateLongWeekDay: 'dd.MM.y, EEEE',
    shortDateTime: 'dd.MM.y, HH:mm',
    shortDateMonth: 'dd.MM',
    shortTimeDate: 'HH:mm, dd.MM.y',
    time: 'HH:mm',
    mediumDate: 'dd MMM y',
    mediumDateShortWeekDay: 'dd MMM y, EEEEEE',
    mediumDateTime: 'dd MMM y, HH:mm',
    mediumCurrentDateTimeSeconds: 'dd MMM HH:mm:ss',
    mediumTimeDate: 'HH:mm, dd MMM y',
    mediumDateMonth: 'dd MMM',
    longDate: 'dd MMMM y',
    longDateTime: 'dd MMMM y, HH:mm',
    longDateShortWeekDayTime: 'dd MMMM y, EEEEEE, HH:mm',
    longTimeDate: 'HH:mm, dd MMMM y',
    longDateMonthShortWeekDay: 'dd MMMM, EEEEEE',
    longDateMonthYearShortWeekDay: 'dd MMMM y, EEEEEE',
    longDateMonth: 'd MMMM',
    longMonthYear: 'MMMM y',
    year: 'y',
    month: 'MM',
    mediumMonth: 'MMM',
    mediumMonthStandalone: 'LLL',
};
export const DATETIME_LOCALE = {
    ru: { ...BASIC_DATETIME_LOCALE },
    en: { ...BASIC_DATETIME_LOCALE },
};

export const ROLE = {
    wasteGenerator: 'AIS_OSSIG_MO.OSSIG_MO_LK_OO',
    wasteRecycling: 'AIS_OSSIG_MO.OSSIG_MO_LK_OI',
    wasteTransportation: 'AIS_OSSIG_MO.OSSIG_MO_LK_OT',
    wasteOperator: 'AIS_OSSIG_MO.OSSIG_MO_ADMIN',
    anonymous: 'AIS_OSSIG_MO.OSSIG_PUBLIC_USER',
    base: 'AIS_OSSIG_MO.OSSIG_BASE_USER',
    director: 'AIS_OSSIG_MO.OSSIG_MO_LK_SUPERVISOR',
    driver: 'AIS_OSSIG_MO.OSSIG_MO_LK_DRIVER',
    wasteAcceptor: 'AIS_OSSIG_MO.OSSIG_MO_LK_ACCEPTOR',
    referral: 'AIS_OSSIG_MO.OSSIG_MO_LK_PARTNER',
    empty: null,
};

export const ROLE_REVERT = flipObject(ROLE);

export const CARRIER_REPORTS_PRIORITY = {
    created: {
        id: 'created',
        titleKey: 'created',
        dispatcherStatus: 'created',
        color: 'secondary',
        priority: 0,
    },
    wastesource_arrival_reports: {
        id: 'wastesource_arrival_reports',
        titleKey: 'arrivedAtWasteSource',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 1,
    },
    loading_reports: {
        id: 'loading_reports',
        titleKey: 'completedLoading',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 2,
    },
    facility_arrival_reports: {
        id: 'facility_arrival_reports',
        titleKey: 'arrivedAtFacility',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 3,
    },
    unloading_reports: {
        id: 'unloading_reports',
        titleKey: 'completedUnloading',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 4,
    },
    impossible_task_reports: {
        id: 'impossible_task_reports',
        titleKey: 'impossible',
        dispatcherStatus: 'error',
        color: 'danger',
        priority: 10,
    },
    completed_task_reports: {
        id: 'completed_task_reports',
        titleKey: 'acceptedByFacility',
        dispatcherStatus: 'done',
        color: 'success',
        priority: 5,
    },
    incompleted_task_reports: {
        id: 'incompleted_task_reports',
        titleKey: 'notAcceptedByFacility',
        dispatcherStatus: 'error',
        color: 'danger',
        priority: 5,
    },
    waste_tank_delivery_reports: {
        id: 'waste_tank_delivery_reports',
        titleKey: 'deliveredWastetank',
        dispatcherStatus: 'done',
        color: 'success',
        priority: 5,
    },
};
export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const PHONE_MASK = '+7 999 999-99-99';
export const SNILS_MASK = '999-999-999 99';

export const FKKO_UNIVERSAL_FILTER = 'class_fkko=3&&class_fkko=4&&class_fkko=5';
export const AVG_SPEED = 50;

export const FKKO_CLASS_OPTIONS_LIST = [
    {
        id: '3',
        nameKey: 'title.classNumber',
        nameParams: { number: 3 },
    },
    {
        id: '4',
        nameKey: 'title.classNumber',
        nameParams: { number: 4 },
    },
    {
        id: '5',
        nameKey: 'title.classNumber',
        nameParams: { number: 5 },
    },
];

export const FORM_SAVE_BUTTON_SETTINGS = {
    titleKey: 'action.save',
};

export const MOBILE_ROLES = [ROLE.driver, ROLE.wasteAcceptor];

export const HEADER_MENU = {
    [ROLE.anonymous]: [
        {
            titleKey: 'header.lots',
            url: '/lots',
        },
        // {
        //     titleKey: 'header.myWasteplaces',
        //     url: '/wasteplaces',
        // },
        {
            titleKey: 'header.infoCenter',
            url: '/',
            children: [
                {
                    titleKey: 'header.agreement',
                    url: DOCUMENT_LINKS.userAgreement,
                },
                {
                    titleKey: 'header.faq',
                    url: '/faq',
                },
            ],
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'header.userInitial',
            url: '/user-initial',
        },
    ],
    [ROLE.driver]: [],
    [ROLE.wasteAcceptor]: [],
    [ROLE.director]: [
        {
            titleKey: 'header.companyUsers',
            url: '/director/users',
        },
        {
            titleKey: 'header.roleRequests',
            url: '/director/requests',
        },
    ],
    [ROLE.referral]: [
        {
            titleKey: 'header.summary',
            url: '/referral/summary',
        },
        {
            titleKey: 'header.partners',
            url: '/referral/partners',
        },
        {
            titleKey: 'header.checkPartners',
            url: '/referral/check-partners',
        },
        {
            titleKey: 'header.grades',
            url: '/referral/grades',
        },
        // {
        //     titleKey: 'header.promo',
        //     url: '/referral/promo',
        // },
    ],
    [ROLE.wasteGenerator]: [
        {
            titleKey: 'header.addRequest',
            children: [
                {
                    titleKey: 'header.addRequestOneTime',
                    url: 'drafts/order-bid/new/',
                },
                {
                    titleKey: 'header.addRequestLongTime',
                    url: 'drafts/contract-bid/new/',
                },
                {
                    titleKey: 'header.addContractOrder',
                    url: 'drafts/contract-order-bid/new/',
                },
            ],
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.wasteplaces',
            url: '/wasteplaces',
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
            // children: [],
        },
        // {
        //     titleKey: 'header.acts',
        //     url: '/',
        // },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.myRequests',
                    url: '/bids',
                },
                {
                    titleKey: 'header.carrierTasks',
                    url: '/carrier-tasks',
                },
            ],
        },
    ],
    [ROLE.wasteRecycling]: [
        {
            titleKey: 'header.facilities',
            url: '/facilities',
        },
        {
            titleKey: 'header.facilityOffers',
            url: '/facility-offers',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/carrier-tasks',
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
        },
        // {
        //     titleKey: 'header.documents',
        //     url: '/',
        //     children: [
        //         {
        //             titleKey: 'header.licenses',
        //             url: '/',
        //         },
        //         {
        //             titleKey: 'header.acts',
        //             url: '/',
        //         },
        //     ],
        // },
        // {
        //     titleKey: 'header.more',
        //     url: '/',
        //     type: 'more',
        //     children: [
        //         // {
        //         //     titleKey: '',
        //         //     url: '',
        //         // },
        //         // {
        //         //     titleKey: '',
        //         //     url: '',
        //         // },
        //     ],
        // },
    ],
    [ROLE.wasteTransportation]: [
        {
            titleKey: 'header.lots',
            url: '/lots',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/carrier-tasks',
        },
        {
            titleKey: 'header.myVehicles',
            url: '/vehicles',
            children: [
                {
                    titleKey: 'header.myVehicleList',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.setupTelemetry',
                    url: '/setup-telemetry',
                },
            ],
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
            // children: [],
        },
        {
            titleKey: 'header.dispatcher',
            url: '/dispatcher',
        },
        // {
        //     titleKey: 'header.acts',
        //     url: '/acts',
        // },
        // {
        //     titleKey: 'header.more',
        //     url: '/',
        //     type: 'more',
        //     children: [
        //         // {
        //         //     titleKey: '',
        //         //     url: '',
        //         // },
        //         // {
        //         //     titleKey: '',
        //         //     url: '',
        //         // },
        //     ],
        // },
    ],
    [ROLE.wasteOperator]: [
        {
            titleKey: 'header.facilityOffers',
            url: '/facility-offers',
        },
        {
            titleKey: 'header.lots',
            url: '/lots',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
        },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.wasteplaces',
                    url: '/wasteplaces',
                },
                {
                    titleKey: 'header.vehicles',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.facilities',
                    url: '/facilities',
                },
                {
                    titleKey: 'header.dispatcher',
                    url: '/dispatcher',
                },
                {
                    titleKey: 'header.operatorReports',
                    url: '/operator-reports',
                    queryParams: { status: 'draft' },
                },
                {
                    titleKey: 'header.ossigPermissions',
                    url: '/ossig-permissions',
                },
                {
                    titleKey: 'header.roleRequests',
                    url: '/director/requests',
                },
                {
                    titleKey: 'header.organizations',
                    url: '/organizations',
                },
                {
                    titleKey: 'header.summary',
                    url: '/summary',
                },
            ],
        },
    ],
    [ROLE.empty]: [{}],
};

export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.account',
        url: '/profile/account',
        icon: 'credit-card',
        excludeForRoles: [ROLE.base, ROLE.director, ...MOBILE_ROLES],
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
        excludeForRoles: [ROLE.base, ROLE.director],
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];

export const USER_STATUSES = {
    active: {
        color: 'success',
        nameKey: 'director.users.status.active',
    },
    waiting: {
        color: 'secondary',
        nameKey: 'director.users.status.waiting',
    },
    blocked: {
        color: 'error',
        nameKey: 'director.users.status.blocked',
    },
};

export const FOOTER_MENU = {
    [ROLE.wasteGenerator]: [
        {
            titleKey: 'footer.addRequest',
            url: '/drafts/bid/new',
        },
        {
            titleKey: 'footer.requests',
            url: '/bids',
        },
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteRecycling]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteTransportation]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteOperator]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.director]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
};

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'subset',
    fragment: 'exact',
    paths: 'subset',
};

export const SW_ORG_TIN = '7707459899';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private http: HttpClient, private entityService: EntityService) {}

    private _configuration = null;

    public loadConfiguration(): Observable<any> {
        return combineLatest([
            this.http.get('/api/schemas.json'),
            of({
                tiles: {
                    default: {
                        url: 'https://api.mapbox.com/styles/v1/smart-waste/cl7t8ng4c001315rq2r291g0l/tiles/256/{z}/{x}/{y}?&access_token=pk.eyJ1Ijoic21hcnQtd2FzdGUiLCJhIjoiY2w3dDdyYWtvMDFzcjNucWxkaDR6aXk3dSJ9.DwsYQbL40ED-guYA9sE-0g',
                        size: 256,
                    },
                    grey: {
                        url: 'https://api.mapbox.com/styles/v1/smart-waste/cl7t82ptn006315lg5yiys80b/tiles/256/{z}/{x}/{y}?&access_token=pk.eyJ1Ijoic21hcnQtd2FzdGUiLCJhIjoiY2w3dDdyYWtvMDFzcjNucWxkaDR6aXk3dSJ9.DwsYQbL40ED-guYA9sE-0g',
                        size: 256,
                    },
                },
                emptyInputValue: '-',
                defaultLocation: [55.75222, 37.61556],
                contacts: { email: 'info@smartwaste.ru', phone: '+7 495 822-22-33' },
                vehicleNumberMask: ['V', '999', 'VV', '999'],
            }),
        ]).pipe(
            tap(([schemas, config]) => {
                this._configuration = {
                    ...config,
                    schemas: this._processSchemas(schemas),
                };
            }),
        );
    }

    swOrgInfo$ = this.entityService
        .getEntityList$(
            'organization.organization_info',
            '',
            '',
            {
                filter: buildFilterStr({ tin: SW_ORG_TIN }),
            },
            0,
            1,
        )
        .pipe(
            map((v) => (v.list?.length > 0 ? v.list[0] : null)),
            shareReplay(),
        );

    private _generateFrontSchemaKey = (key) => {
        const parts = key.split('__');
        let schemaPartIndex = parts.findIndex((part) => part.indexOf('schema') > -1);
        if (schemaPartIndex === -1) schemaPartIndex = parts.length + 1;
        return parts.slice(0, schemaPartIndex + 1).join('__');
    };

    private _processSchemas(schemas) {
        const frontSchemas = {};
        Object.keys(schemas).forEach((schemaKey) => {
            const frontSchemaKey = this._generateFrontSchemaKey(schemaKey);

            if (schemas[schemaKey].properties?.data?.allOf) {
                const props = schemas[schemaKey].properties?.data?.allOf[0];
                frontSchemas[frontSchemaKey] = {};
                const attrs = props.properties.attributes.allOf;
                const relationships = props.properties.relationships.allOf;
                if (attrs && attrs[0]) {
                    frontSchemas[frontSchemaKey].attributes = attrs[0].properties;
                }
                if (relationships && relationships[0]) {
                    frontSchemas[frontSchemaKey].relationships = relationships[0].properties;
                }
            } else {
                frontSchemas[frontSchemaKey] = schemas[schemaKey];
            }
        });
        // console.log('Common schemas in front format', frontSchemas);
        return frontSchemas;
    }

    get schemas(): any {
        return this._configuration?.schemas || null;
    }

    get tiles(): any {
        return this._configuration?.tiles || null;
    }

    get mapDefaultSettings(): any {
        return this.getMapSettings('default');
    }

    get mapGreySettings(): any {
        return this.getMapSettings('grey');
    }

    get emptyInputValue(): any {
        return this._configuration?.emptyInputValue || null;
    }

    get contacts(): any {
        return this._configuration?.contacts || null;
    }

    get vehicleNumberMask(): any {
        return this._configuration?.vehicleNumberMask || null;
    }

    get defaultLocation(): any {
        return this._configuration?.defaultLocation || null;
    }

    getMapSettings(tileType = 'default') {
        if (!this._configuration?.tiles) return null;
        return {
            tile: this._configuration.tiles[tileType],
        };
    }
}
