import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-edit-entity-footer-skeleton',
    templateUrl: './edit-entity-footer-skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEntityFooterSkeletonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
