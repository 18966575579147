<ng-container *transloco="let t">
    <div
        *ngIf="list$ | async as breadcrumbs"
        class="sw-container sw-breadcrumbs"
    >
        <div
            *ngIf="breadcrumbs.length > 1"
            class="sw-list sw-list_h"
        >
            <div
                *ngFor="let breadcrumb of breadcrumbs; let i = index"
                class="sw-item"
            >
                <sw-icon
                    *ngIf="i !== 0"
                    name="angle-small-right"
                    size="small"
                ></sw-icon>
                <!-- move to breadcrumbs item component if we will have more different types -->
                <a
                    *ngIf="!breadcrumb.name && breadcrumb.type === 'entityData'"
                    [routerLink]="breadcrumb.url"
                >
                    <ng-container
                        *ngIf="breadcrumb.entity.entityType !== 'organization.organization_info'"
                    >
                        <!-- if breadcrumb with entity type is last in a chain, suppose that this entity will be asked in main component -->
                        <ng-container
                            *ngIf="
                                breadcrumb.params[breadcrumb.entity.idParamName || 'id']
                                    | entity
                                        : breadcrumb.entity.entityType
                                        : false
                                        : i === breadcrumbs.length - 1
                                    | async as entity
                            "
                        >
                            {{ entity.$snapshot[breadcrumb.entity.nameField || 'name'] }}
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="breadcrumb.entity.entityType === 'organization.organization_info'"
                    >
                        <ng-container
                            *ngIf="
                                breadcrumb.params[breadcrumb.entity.idParamName || 'id']
                                    | organization
                                    | async as entity
                            "
                            >{{ entity.$snapshot[breadcrumb.entity.nameField || 'name'] }}
                        </ng-container>
                    </ng-container>
                </a>
                <a
                    *ngIf="breadcrumb.name"
                    [routerLink]="breadcrumb.url"
                    >{{ t(breadcrumb.name, breadcrumb.params) }}</a
                >
            </div>
        </div>
    </div>
</ng-container>
