import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { shareReplay } from 'rxjs';
import { EntData } from '@shared/models/srv.types';
import { map, tap } from 'rxjs/operators';
import {
    HEADER_MENU,
    SHARE_REPLAY_SETTINGS,
    USER_MENU,
    UNDERCONSTRUCTION_TITLE,
} from '@app/configuration.service';
import { TranslocoService } from '@ngneat/transloco';
import { LocaleService } from '@shared/services/locale.service';
import { AppService } from '@app/app.service';
import { PaymentService } from '@shared/services/payment.service';
import { environment } from '@environments/environment';
import { NotificationService } from '@shared/services/notification.service';
import { ScrollService } from '@shared/services/scroll.service';

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileComponent implements OnDestroy, OnInit, OnChanges {
    @Input()
    cssClass: string;

    @Output()
    changeRole = new EventEmitter();

    menuOpened = false;

    userMenuOpened = false;

    userInfo$;

    organizationInfo$;

    userMenu = USER_MENU;

    headerMenu = HEADER_MENU;

    featureUnderConstruction = UNDERCONSTRUCTION_TITLE.feature;

    breaks = [];

    activeLanguage;

    role$ = this.appService.role$;

    paymentAccount$ = this.paymentService.paymentAccount$;

    assembly = environment;

    constructor(
        private localeService: LocaleService,
        public appService: AppService,
        private paymentService: PaymentService,
        private translocoService: TranslocoService,
        public notificationService: NotificationService,
        private scrollService: ScrollService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.activeLanguage = this.translocoService.getActiveLang();
        this.userInfo$ = this.appService.user$.pipe(
            map((userInfo: EntData) => {
                if (!userInfo) return null;
                const info = userInfo.$snapshot;
                const firstName = info.first_name ? info.first_name[0] : '';
                const lastName = info.last_name ? info.last_name[0] : '';
                return {
                    ...info,
                    shortName: `${firstName}${lastName}`.toUpperCase(),
                };
            }),
            tap(() => {}),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        this.organizationInfo$ = this.appService.organizationInfo$.pipe(
            map((organizationInfo) => {
                return {
                    name: organizationInfo ? `${organizationInfo?.$snapshot.name_full}` : '',
                };
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    selectRole(roleId, userId, event) {
        this.changeRole.emit({ roleId, userId });
        this.closeMenu(event);
    }

    changeLanguage(lang: 'ru' | 'en', e) {
        this.closeMenuWithScroll(e);
        this.activeLanguage = lang;
        this.localeService.changeLang(lang);
    }

    toogleMenu(e) {
        if (this.menuOpened) {
            this.closeMenuWithScroll(e);
        } else {
            this.openMenu(e);
        }
    }

    closeMenu(e) {
        this.menuOpened = false;
        this.userMenuOpened = false;
        this.scrollService.startScroll(e);
    }

    closeMenuWithScroll(e) {
        this.closeMenu(e);
        this.scrollService.scrollToPos();
    }

    openMenu(e) {
        this.menuOpened = true;
        this.userMenuOpened = false;
        this.scrollService.stopScroll(e);
    }

    toogleUserMenu(e) {
        if (this.userMenuOpened) {
            this.closeMenuWithScroll(e);
        } else {
            this.openUserMenu(e);
        }
    }

    openUserMenu(e) {
        this.userMenuOpened = true;
        this.menuOpened = false;
        this.scrollService.stopScroll(e);
    }
}
