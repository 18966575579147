<ng-container *ngIf="!onlyArrow; else tplOnlyArrow">
    <div
        *ngIf="hasPoints"
        class="sw-trip-line__point"
        part="start-point"
    ></div>
    <div class="sw-trip-line__line" part="line"></div>
    <div
        *ngIf="hasArrow"
        class="sw-trip-line__arrow"
        part="arrow"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
            <path d="M10.811 18.707l-1.41-1.414L14.688 12 9.4 6.707l1.415-1.414 5.285 5.293a2 2 0 010 2.828l-5.289 5.293z" fill="currentColor"/>
        </svg>
    </div>
    <div
        *ngIf="sign"
        class="sw-trip-line__sign"
        part="sign"
    >{{ sign }}</div>
    <div
        *ngIf="!sign && hasPoints"
        class="sw-trip-line__point"
        part="end-point"
    ></div>
</ng-container>

<ng-template #tplOnlyArrow>
    <div class="sw-trip-line__arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
            <path d="M10.811 18.707l-1.41-1.414L14.688 12 9.4 6.707l1.415-1.414 5.285 5.293a2 2 0 010 2.828l-5.289 5.293z" fill="currentColor"/>
        </svg>
    </div>
</ng-template>
