<!-- группы можно вынести в toast-group c учетом параметров,
принимающихся сервисом, можно добавлять/редактировать параметры при необходимости
-->
<ng-container *ngIf="toastService.toastGroups.$ | async as groups">
    <div
        *ngFor="let group of currentGroups$ | async"
        [class]="'sw-toast-group sw-toast-group_' + group"
    >
        <div
            *ngFor="let toast of groups[group]"
            class="sw-toast"
        >
            <div
                *ngIf="toast.icon"
                class="sw-toast__icon"
            >
                <div
                    class="sw-sign"
                    [color]="toast.color"
                >
                    <sw-icon [name]="toast.icon"></sw-icon>
                </div>
            </div>
            <div
                class="sw-toast__content"
                *transloco="let t"
            >
                <div
                    *ngIf="toast.title"
                    class="sw-toast__title"
                >
                    {{ toast.title }}
                </div>
                <div
                    *ngIf="toast.titleKey"
                    class="sw-toast__title"
                    [innerHTML]="t(toast.titleKey, toast.titleParams)"
                ></div>

                <div
                    class="sw-toast__message"
                    *ngIf="toast.message"
                >
                    {{ toast.message }}
                </div>
                <div
                    class="sw-toast__message"
                    *ngIf="toast.messageKey"
                    [innerHTML]="t(toast.messageKey, toast.messageParams)"
                ></div>
            </div>
            <button
                class="sw-toast__close"
                (click)="dismiss(toast.id, group)"
            >
                <sw-icon
                    name="cross-small"
                    size="small"
                ></sw-icon>
            </button>
        </div>
    </div>
</ng-container>
