import { HttpClient } from '@angular/common/http';
import {
    TRANSLOCO_LOADER,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule,
    TranslocoLoader,
    Translation,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EMPTY, forkJoin, Observable, retry } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    // simple get translation
    // getTranslation(lang: string) {
    //     console.log(lang);
    //     return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    // }

    // get translation with multiple scopes

    getTranslation(langPath: string) {
        let langParts = langPath.split('/');
        const currentLang = langParts.pop();

        /* в корне загрузки аппа загрузится только основной язык,
         * поэтому если грузим scopes, значит, что основные языковые файлы уже загружены
         * в home module пример указания нескольких scope
         */
        let obs: Observable<any>[] = [
            this.http.get<Translation>(`/assets/i18n/${currentLang}.json`),
        ];

        langParts.forEach((path) => {
            obs.push(this.http.get<Translation>(`/assets/i18n/${path}/${currentLang}.json`));
        });

        return forkJoin([...obs]).pipe(
            map(([translation, vendor]) => {
                return { ...translation, ...vendor };
            }),
            retry(3),
            catchError((e) => EMPTY),
        );
    }
}

@NgModule({
    exports: [TranslocoModule],
    imports: [TranslocoMessageFormatModule.forRoot()],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['ru', 'en'],
                defaultLang:
                    ['ru', 'en'].indexOf(localStorage.getItem('lang')) > -1
                        ? localStorage.getItem('lang')
                        : 'ru',
                fallbackLang: 'ru',
                // Remove this option if your application
                // doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule {}
