import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Schedule } from '@form/models/form-element.types';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnDestroy, OnInit {
    @Input()
    schedule: Schedule;

    daysOfWeek = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
