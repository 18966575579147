import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private translocoService: TranslocoService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = request.headers;
        let params = request.params;

        const isOurApi =
            request.url.indexOf('selcloud') === -1 && request.url.indexOf('bik-info.ru') === -1;
        if (this.authService.token._ && isOurApi) {
            headers = headers.set(
                'authorization',
                this.authService.token._ ? `bearer ${this.authService.token._}` : null,
            );
        }

        let isDefaultLocaleType =
            request.url.indexOf('/legal_doc/') === -1 && request.url.indexOf('/faq/') === -1;

        if (isOurApi && isDefaultLocaleType && request.url.indexOf('/assets/i18n') === -1)
            params = params.set('lang', this.translocoService.getActiveLang());

        const requestWithUpdatedHeaders = request.clone({ headers, params });

        return next.handle(requestWithUpdatedHeaders);
    }
}
