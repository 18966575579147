import { Injectable } from '@angular/core';
import { TemplateObservable } from '@shared/classes/template-observable';
import { EntData, EntList, User } from '@shared/models/srv.types';
import { SrvService } from '@shared/services/srv.service';
import {
    BehaviorSubject,
    EMPTY,
    merge,
    mergeMap,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    throwError,
    withLatestFrom,
} from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import { catchError, debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { ToastService } from '@shared/services/toast.service';
import { DocumentService } from '@shared/services/document.service';
import { buildFilterStr, fileDownloaderFn } from '@app/app.utils';
import { WebSocketService } from '@shared/services/web-socket.service';
import { AuthService } from '@shared/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    updatePaymentData$ = new Subject();

    socketPaymentInfo$ = this.socketService.message$.pipe(
        filter((v) => v && v?.$snapshot?.notice_type === 'notice.payment'),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    paymentAccount$: Observable<EntData> = merge(
        this.authService.accountType$,
        this.updatePaymentData$,
        this.socketPaymentInfo$,
    ).pipe(
        debounceTime(0),
        filter((v) => !!v),
        withLatestFrom(this.authService.accountType$, this.authService.organizationId$),
        switchMap(([initiator, accountType, organizationId]) => {
            return accountType !== 'none'
                ? this.entityService.getEntityList$(
                      `payment.payment_account_${accountType}`,
                      '',
                      '',
                      { filter: buildFilterStr({ org_owner: organizationId }) },
                      0,
                      1,
                  )
                : of(null);
        }),
        map((account: EntList) => {
            if (!account || !account.list[0]) return null;
            return account.list[0];
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private documentService: DocumentService,
        private entityService: EntityService,
        private srv: SrvService,
        private authService: AuthService,
        private toastService: ToastService,
        private socketService: WebSocketService,
    ) {
        this.paymentAccount$.subscribe();
    }

    withdraw(sum) {
        return this.srv
            .sendFormRequest$(
                'payment/payment_account_customer/withdraw',
                { amount: sum },
                'post',
                'application/json',
            )
            .pipe(
                tap((v) => {
                    this.toastService.create({
                        titleKey: 'profile.account.success.withdrawSuccessTitle',
                        // messageKey: 'profile.account.success.withdrawSuccessMessage',
                        type: 'success',
                    });
                    this.updatePaymentData$.next(true);
                }),
                catchError((error) => {
                    this.toastService.create({
                        titleKey: 'profile.account.error.withdrawErrorTitle',
                        messageKey: this.srv.generateErrorMessage(error),
                        type: 'error',
                    });
                    return of(error);
                }),
                take(1),
            );
    }

    depositInvoice(amount) {
        return this.srv
            .sendFormRequest$(
                'payment/payment_account_customer/deposit_invoice',
                { amount: amount },
                'post',
                'application/json',
            )
            .pipe(
                map((entity) => {
                    fileDownloaderFn(
                        entity.data.attributes.file_upload_url,
                        'deposit-invoice-' + `${amount}`.replace('.', ','),
                    );
                }),
                catchError((error) => {
                    this.toastService.create({
                        titleKey: 'profile.account.error.depositErrorTitle',
                        messageKey: this.srv.generateErrorMessage(error),
                        type: 'error',
                    });
                    return of(error);
                }),
            );
    }

    withdrawInvoice(sum) {
        return this.srv
            .sendFormRequest$(
                'payment/payment_account_customer/withdraw_invoice',
                { amount: sum },
                'post',
                'application/json',
                true,
            )
            .pipe(
                switchMap((entity) => {
                    return this.documentService
                        .createGroupToSignBySettings([
                            {
                                entityId: entity.id,
                                entityType: entity.$snapshot.type,
                                document: entity,
                                signBodyPayload: { note: 'Вывод средств' },
                            },
                        ])
                        .pipe(
                            filter((remaining: []) => !remaining || !remaining.length),
                            mergeMap((remaining) => {
                                if (!remaining || remaining.length > 0) {
                                    return throwError(null);
                                }
                                if (remaining.length === 0) {
                                    this.toastService.create({
                                        titleKey: 'profile.account.success.withdrawSuccessTitle',
                                        // messageKey:
                                        //     'profile.account.success.withdrawSuccessMessage',
                                        type: 'success',
                                    });
                                    this.updatePaymentData$.next(true);
                                    return of(remaining);
                                }
                            }),
                        );
                }),
                catchError((error) => {
                    this.toastService.create({
                        titleKey: 'profile.account.error.withdrawErrorTitle',
                        messageKey: error
                            ? this.srv.generateErrorMessage(error)
                            : 'profile.account.error.withdrawSignErrorMessage',
                        type: 'error',
                    });
                    return of(error);
                }),
            );
    }
}
