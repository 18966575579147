<div
    part="header"
    class="sw-accordion__header"
    (click)="toggle()"
>
    <sw-toolbar>
        <div slot="start">
            <slot name="icon"></slot>
            <slot name="title"></slot>
        </div>
        <div slot="end">
            <slot name="btns"></slot>
            <div class="sw-accordion__open-close" *ngIf="hasAngleIcon && collapsable">
                <sw-icon
                    color="action"
                    [icon]="isOpen ? iconUp : iconDown"
                    [size]="iconSize"
                ></sw-icon>
            </div>
        </div>
    </sw-toolbar>
    <div part="title-mobile">
        <slot name="title-mobile"></slot>
    </div>
</div>
<div
    part="body"
    class="sw-accordion__body"
    *ngIf="isOpen && collapsable"
>
    <slot name="body"></slot>
</div>
