import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[textSize]',
})
export class TextSizeDirective {
    constructor() {}

    @Input() textSize:
        | 'xl'
        | 'l'
        | 'm'
        | 'm_reduced'
        | 's'
        | 's_reduced'
        | 'xs'
        | 'xs_caps'
        | 'xs_reduced'
        | 'mini'
        | 'minicaps';

    @HostBinding('class') get getTextSizeClass() {
        return this.textSize
            ? {
                  [`sw-text-size_${this.textSize}`]: true,
              }
            : null;
    }
}
