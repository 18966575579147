import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { GridElement } from './grid.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [GridElement],
    exports: [GridElement],
})
export class GridModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(GridElement, {
            injector: this.injector,
        });

        customElements.define('sw-grid', el);
    }
}
