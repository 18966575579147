import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'sw-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['accordion.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class AccordionElement {
    // Можно задать первоночальное состояние аккордиона
    @Input() isOpen: boolean = true;

    // Наличие ионки сворачивания
    @Input() hasAngleIcon: boolean = true;

    @Input() type: 'underline' | 'clear' | 'outline' | string;

    @Input() collapsable: boolean = true;

    @Input() iconUp = 'angle-small-up';

    @Input() iconDown = 'angle-small-down';

    @Input() iconSize = 'medium';

    @HostBinding('class') get accordionClass() {
        return {
            ['sw-accordion--opened']: this.isOpen,
            [`sw-accordion_${this.type}`]: !!this.type,
            ['sw-accordion_no-collapsable']: !this.collapsable,
        };
    }

    constructor() {}

    toggle() {
        if (!this.collapsable) return;
        this.isOpen = !this.isOpen;
    }
}
