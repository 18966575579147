import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { EntDescription } from '@shared/models/srv.types';

@Component({
    selector: 'app-gallery-modal',
    templateUrl: './gallery-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent implements OnDestroy, OnInit {
    @Input()
    gallery: EntDescription[];

    @Input()
    startIndex: number = 0;

    currentIndex: number;

    constructor(protected modalService: ModalService) {}

    ngOnInit(): void {
        this.currentIndex = this.startIndex;
    }

    ngOnDestroy(): void {}

    next(step = 1) {
        let newIndex = this.currentIndex + step;

        if (newIndex > this.gallery.length - 1) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = this.gallery.length - 1;
        }

        this.currentIndex = newIndex;
    }

    closeModal() {
        this.modalService.dismiss();
    }
}
