import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoaderService } from '@shared/services/loader.service';

@Component({
    selector: 'app-global-loader',
    templateUrl: './global-loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent implements OnInit {
    constructor(public loaderService: LoaderService) {}

    ngOnInit(): void {}
}
