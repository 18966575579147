import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChipElement } from './chip.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@shared/components/icon/icon.module';

@NgModule({
    declarations: [ChipElement],
    imports: [CommonModule, IconModule],
    exports: [ChipElement],
})
export class ChipModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ChipElement, {
            injector: this.injector,
        });

        customElements.define('sw-chip', el);
    }
}
