import { EntData } from '@shared/models/srv.types';
import { CARRIER_REPORTS_PRIORITY } from '@app/configuration.service';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

export const fileDownloaderFn = (fileUrl: string, fileName: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', fileUrl);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export const copyMessageFn = (val: string) => {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    window.navigator.clipboard.writeText(val);
    document.body.removeChild(selBox);
};

export const buildFilterStr = (params: any) => {
    const paramsArr = [];
    for (let key in params) {
        if (Array.isArray(params[key])) {
            params[key].forEach((arrayItem) => {
                paramsArr.push(`${key}=${arrayItem}`);
            });
        } else {
            paramsArr.push(`${key}=${params[key]}`);
        }
    }
    return paramsArr.join('&');
};

export const getCarrierTaskStatus = (taskEntity: any) => {
    if (!taskEntity) return null;
    const reports = [...taskEntity.active_reports].sort(
        (a, b) => CARRIER_REPORTS_PRIORITY[b].priority - CARRIER_REPORTS_PRIORITY[a].priority,
    );
    return reports.length > 0
        ? CARRIER_REPORTS_PRIORITY[reports[0]]
        : CARRIER_REPORTS_PRIORITY.created;
};

export const subtractPartsOfDateTime = (a, b, fromDate = 'fromDate', fromTime = 'fromTime') => {
    const aValue =
        a[fromDate] && a[fromTime]
            ? moment(`${a[fromDate]} ${a[fromTime]}`, 'YYYY-MM-DD HH:mm:ss').valueOf()
            : 0;
    const bValue =
        b[fromDate] && b[fromTime]
            ? moment(`${b[fromDate]} ${b[fromTime]}`, 'YYYY-MM-DD HH:mm:ss').valueOf()
            : 0;
    return aValue - bValue;
};

export const gatherBulkErrors = (error) => {
    if (error instanceof HttpErrorResponse) {
        if (Array.isArray(error.error)) {
            return new HttpErrorResponse({
                status: 422,
                error: {
                    errors: error.error
                        .filter((v) => v.endpoint)
                        .map((v) => v.response.errors)
                        .reduce((acc, current) => acc.concat(current), []),
                },
            });
        }
    }
    return null;
};

export const highlightText = (
    initialText: string,
    textToHighlight: string,
    tag: string = 'b',
    tagClass: string = '',
) => {
    return initialText.replace(
        new RegExp(textToHighlight + '(?!([^<]+)?<)', 'gi'),
        `<${tag} class="${tagClass}">$&</${tag}>`,
    );
};

export const flipObject = (object) => {
    return Object.keys(object).reduce((acc, current) => {
        acc[object[current]] = current;
        return acc;
    }, {});
};

export const mathFloatRound = (floatNum: number, toRound?: number) => {
    if (floatNum === 0 || floatNum === null) return floatNum;
    const floatNumStr = floatNum.toString();
    const separator =
        floatNumStr.indexOf(',') !== -1 ? ',' : floatNumStr.indexOf('.') !== -1 ? '.' : undefined;

    if (!separator) {
        if (toRound && toRound !== 0) {
            return parseFloat(floatNum.toFixed(toRound));
        } else return floatNum;
    } else {
        if (toRound && toRound === 0) {
            return Math.round(floatNum);
        } else {
            let finishNum: number;
            // создаем массив из целой и дробной части
            const floatArr = floatNumStr.split(separator);
            // последний символ до округления
            const lastNumStr: string = floatArr[1].charAt(toRound);

            // округление вверх
            if (Number(lastNumStr) === 5) {
                // создаем строковое число, равное одной части разрядности,
                // для округления числа вверх
                let onePartOfRankStrArr = ['0', separator];
                for (let index = 1; index < toRound; index++) {
                    onePartOfRankStrArr.push('0');
                }
                onePartOfRankStrArr.push('1');
                const ceil = Number(onePartOfRankStrArr.join(''));

                // отсекаем часть после плавающей точки, до необходимой разрядности
                const intNumStr: string = floatArr[1].slice(0, toRound);
                floatArr[1] = intNumStr;
                // прибавляем одну часть разряда
                finishNum = Number(floatArr.join(separator)) + ceil;
            } else {
                finishNum = floatNum;
            }

            return parseFloat(finishNum.toFixed(toRound));
        }
    }
};

export const floatMultiply = (num1: number, capacity1: number, num2: number, capacity2: number) => {
    const result = (num1 * capacity1 * num2 * capacity2) / (capacity1 * capacity2);
    return result;
};

export const convertMbToBytes = (value) => {
    return value * 1048576;
};
export const convertBytesToMb = (value) => {
    return value / 1048576;
};

export const latinizeStr = (str: string): string => {
    const RUS_LAT_PAIRS = {
        А: 'A',
        В: 'B',
        Е: 'E',
        К: 'K',
        М: 'M',
        Н: 'H',
        О: 'O',
        Р: 'P',
        С: 'C',
        Т: 'T',
        У: 'Y',
        Х: 'X',
    };
    return [...str].map((v) => RUS_LAT_PAIRS[v] || v).join('');
};

export const isWebgl2Supported = (): boolean => {
    const canvas = document.createElement('canvas');
    const webgl2Context = canvas.getContext('webgl2');
    let supported = true;

    if (!webgl2Context) {
        supported = false;

        return supported;
    }

    const params = [
        { pname: 'MAX_3D_TEXTURE_SIZE', min: 256 },
        { pname: 'MAX_DRAW_BUFFERS', min: 4 },
        { pname: 'MAX_COLOR_ATTACHMENTS', min: 4 },
        { pname: 'MAX_VERTEX_UNIFORM_BLOCKS', min: 12 },
        { pname: 'MAX_VERTEX_TEXTURE_IMAGE_UNITS', min: 16 },
        { pname: 'MAX_FRAGMENT_INPUT_COMPONENTS', min: 60 },
        { pname: 'MAX_UNIFORM_BUFFER_BINDINGS', min: 24 },
        { pname: 'MAX_COMBINED_UNIFORM_BLOCKS', min: 24 },
    ];

    for (let i = 0; i < params.length; i += 1) {
        const param = params[i];
        const value = webgl2Context.getParameter(webgl2Context[param.pname]);

        if (typeof value !== 'number' || Number.isNaN(value) || value < param.min) {
            supported = false;

            break;
        }
    }

    return supported;
};

export const isLocal = (): boolean => {
    return (
        document.location.host.indexOf('localhost') > -1 || document.location.protocol === 'http'
    );
};
