<div class="sw-loader_inline" [ngClass]="['sw-loader_' + theme]">
    <svg class="sw-loader__spinner">
        <circle
            cx="25"
            cy="25"
            r="14"
            fill="none"
            stroke-width="1"
        ></circle>
    </svg>
    <p *ngIf="message" [innerHTML]="message"></p>
</div>
