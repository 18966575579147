<ng-container *transloco="let t">
    <!-- если поиск активен для его верного перепозиционирования в шапке, необходимо в шапке задать класс 'sw-header_search-active' -->
    <header
        class="sw-header sw-container"
        [class.sw-header_search-active]="false"
        [ngClass]="cssClass"
        *ngIf="{
            user: userInfo$ | async,
            organization: organizationInfo$ | async,
            paymentAccount: paymentAccount$ | async,
            role: role$ | async
        } as data"
    >
        <div
            class="sw-header-wrap"
            #headerWrap
        >
            <div class="sw-header__left">
                <a
                    class="sw-header__logo"
                    routerLink="/"
                >
                    <sw-logo type="dark"></sw-logo>
                </a>

                <ul
                    class="sw-header__menu sw-list sw-list_h"
                    #visibleMenuEl
                >
                    <ng-container *ngFor="let menuItem of headerMenu[data.role]">
                        <li
                            *ngIf="menuItem.type !== 'more'"
                            class="sw-item sw-header__menu__item"
                        >
                            <a
                                *ngIf="!menuItem.children"
                                [routerLink]="[menuItem.url]"
                                [queryParams]="menuItem.queryParams"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="activeOptions"
                            >
                                {{ t(menuItem.titleKey) }}
                            </a>

                            <div
                                *ngIf="menuItem.children"
                                class="sw-dropdown sw-dropdown_hoverable"
                            >
                                <a
                                    class="sw-dropdown__trigger"
                                    nohref
                                >
                                    {{ t(menuItem.titleKey) }}
                                    <sw-icon
                                        name="angle-small-down"
                                        size="small"
                                        slot="end"
                                    ></sw-icon>
                                </a>
                                <div class="sw-dropdown__body">
                                    <div class="sw-list sw-list_menu">
                                        <ng-container *ngFor="let childItem of menuItem.children">
                                            <a
                                                class="sw-item"
                                                [routerLink]="[childItem.url]"
                                                [queryParams]="childItem.queryParams"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="activeOptions"
                                            >
                                                {{ t(childItem.titleKey) }}
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>

                    <ng-container
                        *ngIf="headerMenu[data.role][headerMenu[data.role].length - 1] as lastItem"
                    >
                        <li
                            class="sw-item"
                            [class.swh-hide]="lastItem.type !== 'more'"
                            [class.sw-item_more]="lastItem.type === 'more'"
                            #moreEl
                        >
                            <div class="sw-dropdown sw-dropdown_hoverable">
                                <a
                                    class="sw-dropdown__trigger"
                                    nohref
                                >
                                    {{ t('header.more') }}
                                    <sw-icon
                                        name="angle-small-down"
                                        size="small"
                                        slot="end"
                                    ></sw-icon>
                                </a>
                                <div class="sw-dropdown__body">
                                    <div
                                        class="sw-list sw-list_menu"
                                        #hiddenMenuEl
                                    >
                                        <ng-container
                                            *ngFor="let menuItem of headerMenu[data.role]"
                                        >
                                            <span
                                                *ngIf="menuItem.type !== 'more'"
                                                class="hidden-item swh-hide"
                                            >
                                                <a
                                                    *ngIf="!menuItem.children"
                                                    class="sw-item"
                                                    [routerLink]="[menuItem.url]"
                                                    [queryParams]="menuItem.queryParams"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="activeOptions"
                                                >
                                                    {{ t(menuItem.titleKey) }}
                                                </a>
                                                <ng-container *ngIf="menuItem.children">
                                                    <a
                                                        class="sw-item"
                                                        nohref
                                                    >
                                                        {{ t(menuItem.titleKey) }}
                                                    </a>
                                                    <ng-container
                                                        *ngFor="let childItem of menuItem.children"
                                                    >
                                                        <a
                                                            class="sw-item"
                                                            [routerLink]="[childItem.url]"
                                                            [queryParams]="childItem.queryParams"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="
                                                                activeOptions
                                                            "
                                                        >
                                                            — {{ t(childItem.titleKey) }}
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="lastItem.type === 'more'">
                                            <ng-container
                                                *ngFor="let childItem of lastItem.children"
                                            >
                                                <a
                                                    class="sw-item"
                                                    [routerLink]="[childItem.url]"
                                                    [queryParams]="childItem.queryParams"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="activeOptions"
                                                >
                                                    {{ t(childItem.titleKey) }}
                                                </a>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div
                class="sw-header__user-menu sw-list sw-list_h swh-justify-content-end"
                #userMenuEl
            >
                <div
                    class="sw-item swh-underconstruction swh-hide"
                    [title]="t(featureUnderConstruction)"
                >
                    <div class="sw-control sw-search_global">
                        <div class="sw-control__field-group">
                            <div class="sw-control__field-group__start">
                                <sw-icon name="search"></sw-icon>
                            </div>
                            <input
                                class="sw-control__field"
                                type="text"
                                [placeholder]="t('header.searchPlaceholder')"
                            />
                            <!-- <div class="sw-control__field-group__end">
                                <sw-button fill="clear">
                                    <sw-icon name="cross-small"></sw-icon>
                                </sw-button>
                                <sw-button fill="clear">
                                    <sw-icon name="microphone"></sw-icon>
                                </sw-button>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div
                    class="sw-item"
                    *ngIf="data.user && data.role"
                >
                    <div
                        *ngIf="notificationService.topNotifications$ | async as notifications"
                        #triggerNotifications
                        class="sw-dropdown sw-dropdown_notifications"
                        (mouseenter)="showNotifications()"
                        (mouseleave)="hideNotifications()"
                    >
                        <sw-button
                            class="sw-dropdown__trigger"
                            fill="clear"
                        >
                            <sw-icon
                                name="bell"
                                slot="icon-only"
                                size="medium"
                            ></sw-icon>
                            <sw-badge
                                color="primary"
                                *ngIf="notifications.length > 0"
                            >
                                {{ notifications.length }}
                            </sw-badge>
                        </sw-button>
                        <div
                            class="sw-dropdown__body"
                            #blockNotifications
                        >
                            <app-notification-header
                                [notifications]="notifications"
                            ></app-notification-header>
                        </div>
                    </div>
                </div>

                <div
                    class="sw-item"
                    *ngIf="data.role && data.paymentAccount"
                >
                    <div class="sw-dropdown sw-dropdown_hoverable">
                        <!-- balance doesn't exist in api now -->
                        <sw-button
                            class="sw-dropdown__trigger"
                            fill="clear"
                        >
                            <span>
                                {{
                                    data.paymentAccount.$snapshot.amount_common -
                                        data.paymentAccount.$snapshot.amount_reserved
                                        | localNumber : '1.0-2'
                                }}
                                {{ t('measurement.money') }}
                            </span>
                            <sw-icon
                                name="credit-card"
                                size="small"
                                slot="end"
                            ></sw-icon>
                            <sw-icon
                                name="angle-small-down"
                                size="small"
                                slot="end"
                            ></sw-icon>
                        </sw-button>
                        <div class="sw-dropdown__body">
                            <ul class="sw-list sw-list_between sw-list_underline">
                                <li class="sw-item">
                                    <span>{{ t('header.balanceCommonAmount') }}:</span>
                                    <span>
                                        {{
                                            data.paymentAccount.$snapshot.amount_common -
                                                data.paymentAccount.$snapshot.amount_reserved
                                                | localNumber : '1.0-2'
                                        }}
                                        {{ t('measurement.money') }}
                                    </span>
                                </li>
                                <li class="sw-item">
                                    <span>{{ t('header.balanceReservedAmount') }}:</span>
                                    <span>
                                        {{
                                            data.paymentAccount.$snapshot.amount_reserved
                                                | localNumber : '1.0-2'
                                        }}
                                        {{ t('measurement.money') }}
                                    </span>
                                </li>
                                <li class="sw-item">
                                    <span>{{ t('header.balanceIncomingAmount') }}:</span>
                                    <span>
                                        {{
                                            data.paymentAccount.$snapshot.amount_incoming
                                                | localNumber : '1.0-2'
                                        }}
                                        {{ t('measurement.money') }}
                                    </span>
                                </li>
                            </ul>
                            <p
                                class="swh-text-right swh-margin-top"
                                textSize="m"
                            >
                                <a
                                    class="sw-text-action"
                                    routerLink="/profile/account"
                                >
                                    {{ t('action.detail') }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="sw-item">
                    <div class="sw-header__profile sw-dropdown sw-dropdown_hoverable">
                        <ng-container *ngIf="data.user">
                            <div class="sw-dropdown__trigger">
                                <a
                                    class="sw-avatar sw-avatar_small"
                                    [routerLink]="['/profile/personal-info']"
                                >
                                    {{ data.user.shortName }}
                                    <sw-icon
                                        name="angle-small-down"
                                        size="small"
                                    ></sw-icon>
                                </a>
                            </div>

                            <div class="sw-dropdown__body">
                                <div class="sw-avatar swh-float-left">
                                    {{ data.user.shortName }}
                                </div>
                                <div class="swh-overhide">
                                    <p
                                        class="sw-text-action swh-no-margin"
                                        textSize="l"
                                    >
                                        {{ data.user?.first_name }} {{ data.user?.last_name }}
                                    </p>
                                    <p
                                        class="sw-text_secondary swh-no-margin"
                                        textSize="s_reduced"
                                    >
                                        {{ data.organization?.name }}
                                    </p>
                                    <sw-chip
                                        *ngIf="
                                            data.user?.role_current.id
                                                | entity : data.user?.role_current.type
                                                | async as currentRole
                                        "
                                    >
                                        {{ currentRole.$snapshot.name.toLowerCase() }}
                                    </sw-chip>
                                </div>
                                <div class="sw-list sw-list_menu">
                                    <ng-container *ngFor="let menuItem of userMenu">
                                        <!--                                        <span-->
                                        <!--                                            class="swh-underconstruction"-->
                                        <!--                                            *ngIf="-->
                                        <!--                                                menuItem.type !== 'roles' &&-->
                                        <!--                                                menuItem.underconstruction-->
                                        <!--                                            "-->
                                        <!--                                        >-->
                                        <!--                                            <a-->
                                        <!--                                                class="sw-item"-->
                                        <!--                                                [title]="t(featureUnderConstruction)"-->
                                        <!--                                            >-->
                                        <!--                                                <sw-icon [name]="menuItem.icon"></sw-icon>-->
                                        <!--                                                {{ t(menuItem.titleKey) }}-->
                                        <!--                                            </a>-->
                                        <!--                                        </span>-->
                                        <a
                                            class="sw-item"
                                            *ngIf="
                                                menuItem.type !== 'roles' &&
                                                !menuItem.underconstruction &&
                                                menuItem.excludeForRoles.indexOf(data.role) === -1
                                            "
                                            [routerLink]="[menuItem.url]"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="activeOptions"
                                        >
                                            <sw-icon [name]="menuItem.icon"></sw-icon>
                                            {{ t(menuItem.titleKey) }}
                                        </a>
                                        <ng-container *ngIf="menuItem.type === 'roles'">
                                            <ng-container *ngFor="let role of data.user.roles">
                                                <app-role-menu-item
                                                    *ngIf="role.id !== data.user.role_current.id"
                                                    [menuItem]="menuItem"
                                                    [role]="role"
                                                    [currentUserRole]="data.user.role_current"
                                                    (select)="selectRole(role.id, data.user.id)"
                                                ></app-role-menu-item>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!data.user">
                            <a
                                [routerLink]="['/login']"
                                class="sw-dropdown__trigger"
                            >
                                <sw-icon
                                    name="user"
                                    size="small"
                                ></sw-icon>
                                {{ t('header.login') }}
                                <sw-icon
                                    name="angle-small-down"
                                    size="small"
                                    slot="end"
                                ></sw-icon>
                            </a>
                        </ng-container>
                    </div>
                </div>

                <div
                    *ngIf="!assembly.production"
                    class="sw-item sw-lang"
                >
                    <div class="sw-dropdown sw-dropdown_hoverable">
                        <sw-icon
                            class="sw-dropdown__trigger"
                            name="globe"
                            size="small"
                        ></sw-icon>
                        <div class="sw-dropdown__body">
                            <ul class="sw-list sw-list_select">
                                <li
                                    class="sw-item"
                                    [class.sw-item--selected]="activeLanguage === 'ru'"
                                    (click)="changeLanguage('ru')"
                                >
                                    Русский
                                </li>
                                <li
                                    class="sw-item"
                                    [class.sw-item--selected]="activeLanguage === 'en'"
                                    (click)="changeLanguage('en')"
                                >
                                    English
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</ng-container>
