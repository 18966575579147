import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { createCustomElement } from '@angular/elements';
import { ThumbnailElement } from './thumbnail.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    declarations: [ThumbnailElement],
    imports: [CommonModule, IconModule],
    exports: [ThumbnailElement],
})
export class ThumbnailModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ThumbnailElement, {
            injector: this.injector,
        });

        customElements.define('sw-thumbnail', el);
    }
}
