// http://qaru.site/questions/231043/input-mask-fields-in-angular2-forms

import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ElementRef,
    Renderer2,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import * as m from 'moment';

const moment = m;

@Directive({
    selector: '[sw-mask]',
    host: {
        '(keydown)': 'syncScrollLeftMask()',
        '(keypress)': 'syncScrollLeftMask()',
        '(keyup)': 'syncScrollLeftMask()',
        '(focusout)': 'syncScrollLeftMask()',
    },
})
export class MaskDirective implements OnInit, OnDestroy {
    private static readonly ALPHA = 'A';

    private static readonly ALPHA_LAT = 'Z';

    private static readonly ALPHA_RU = 'Я';

    private static readonly NUMERIC = '9';

    private static readonly ALPHANUMERIC = '*';

    private static readonly ALPHANUMERIC_LAT = '$';

    private static readonly ALPHANUMERIC_RU = '₽';

    private static readonly RUS_VEHICLE_LIMITED = 'V';

    private static readonly REGEX_MAP = new Map([
        [MaskDirective.ALPHA, /^[A-Za-zА-Яа-я]/],
        [MaskDirective.ALPHA_LAT, /^[A-Za-z]/],
        [MaskDirective.ALPHA_RU, /^[А-Яа-я]/],
        [MaskDirective.NUMERIC, /\d/],
        [MaskDirective.ALPHANUMERIC, /^[0-9A-Za-zА-Яа-я]/],
        [MaskDirective.ALPHANUMERIC_LAT, /^[0-9A-Za-z]/],
        [MaskDirective.ALPHANUMERIC_RU, /^[0-9А-Яа-я]/],
        [MaskDirective.RUS_VEHICLE_LIMITED, /^[abekmhopctyxавекмнорстухABEKMHOPCTYXАВЕКМНОРСТУХ]/],
    ]);

    // for cyrillic vehicle's number
    private static readonly RUS_LAT_PAIRS = {
        А: 'A',
        В: 'B',
        Е: 'E',
        К: 'K',
        М: 'M',
        Н: 'H',
        О: 'O',
        Р: 'P',
        С: 'C',
        Т: 'T',
        У: 'Y',
        Х: 'X',
    };

    private static displayValue: string = '';

    private value: string = '';

    private maskGroups = [];

    private visibleMask: BehaviorSubject<string> = new BehaviorSubject('');

    private selectedGroup$: BehaviorSubject<number> = new BehaviorSubject(0);

    private maskSbscrptn: Subscription;

    private selectedGroupSbscrptn: Subscription;

    private _validator: ValidatorFn;

    _mask: string;

    private _isFocused = new BehaviorSubject(false);

    lastKeypressedButton = null;

    get mask(): string {
        return this._mask;
    }

    @Input() set mask(value: string) {
        this._mask = value;
        this.updateMask();
    }

    @Input() public editByParts: boolean;

    @Input() public forceMask: boolean;

    @Input() public set maskValue(value: string) {
        value = value || '';
        if (value !== this.value || this.forceMask) {
            this.value = value;
            this.defineValue();
        }
    }

    @Output() public maskValueChange = new EventEmitter<string>();

    @HostListener('input', ['$event']) public onInput(event: { target: any }): void {
        this.onValueChange(event.target.value);
    }

    @HostListener('focus', ['$event']) public onFocus(event: { target: any }): void {
        this._isFocused.next(true);
        if (!this.editByParts || !this.mask) return;

        this.ngControl.control.clearValidators();
        this.ngControl.control.updateValueAndValidity();
        if (!event.target.value) {
            this.onValueChange('', true);
        } else {
            this.onValueChange(event.target.value);
        }
        // выделяем группу
        this.selectGroup();
        // console.log('[MASK][HostListener]', event)
    }

    @HostListener('blur', ['$event']) public onBlur(event: { target: any }): void {
        this._isFocused.next(false);

        if (!this.editByParts || !this.mask) return;
        if (this.forceMask) return;

        // когда поле теряет фокус из значения должны быть удалены символы маски, если кроме них нет других символов
        if (this.valueIsOnlyMaskChar(event.target.value)) {
            this.ngControl.control.setValue('');
        }
        if (this._validator) {
            this.ngControl.control.setValidators(this._validator);
            this.ngControl.control.updateValueAndValidity();
        }
    }

    @HostListener('click', ['$event']) public onClick(): void {
        // выделяем группу
        if (this.editByParts && this.mask) this.selectGroup();
        this.lastKeypressedButton = null;
    }

    @HostListener('keydown', ['$event']) public onKeyDown(event): void {
        if (!this.editByParts || !this.mask) return;

        const current = this.selectedGroup$.getValue();

        // при перемещении стрелками выделяем следующую/предыдущую группу
        if (['ArrowLeft'].indexOf(event.code) > -1)
            this.selectedGroup$.next(current > 0 ? current - 1 : 0);
        if (['ArrowRight'].indexOf(event.code) > -1)
            this.selectedGroup$.next(
                current < this.maskGroups.length - 1 ? current + 1 : this.maskGroups.length - 1,
            );
        if (
            ['Backspace'].indexOf(event.code) > -1 &&
            this.lastKeypressedButton === 'Backspace' &&
            this.editByParts
        ) {
            MaskDirective.delay().then(() => {
                const valueGroups = MaskDirective.getValuePartsByMask(
                    this.value,
                    this.mask,
                    0,
                    this.maskGroups,
                );
                const clrValueGroup = valueGroups.groups[current].replace(/_/g, '');
                if (clrValueGroup === '') this.selectedGroup$.next(current > 0 ? current - 1 : 0);
            });
        }

        this.lastKeypressedButton = event.code;
    }

    constructor(
        private ngControl: NgControl,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        let ncMask = this.renderer.createElement('input');
        ncMask.setAttribute('class', 'sw-mask sw-control__field');
        let wrapField = this.renderer.parentNode(this.el.nativeElement);
        if (
            !wrapField.classList.contains('sw-control__field-wrapper') &&
            !wrapField.classList.contains('sw-control__field')
        ) {
            ncMask.setAttribute('style', 'display: none');
        }
        ncMask.setAttribute('tabIndex', '-1');
        if (this.el.nativeElement.tagName === 'INPUT') this.renderer.appendChild(wrapField, ncMask);
        else this.renderer.appendChild(this.el.nativeElement, ncMask);

        this.maskSbscrptn = combineLatest([this.visibleMask, this._isFocused]).subscribe(
            ([value, isFocused]) => {
                wrapField.querySelector('.sw-mask').value =
                    isFocused || this.forceMask ? value : '';
            },
        );
        this.updateMask();

        this.selectedGroupSbscrptn = this.selectedGroup$.subscribe((groupIndex) => {
            const el =
                this.el.nativeElement.tagName === 'INPUT'
                    ? this.el.nativeElement
                    : this.el.nativeElement.querySelector('.native-input');
            if (el)
                setTimeout(() =>
                    el.setSelectionRange(
                        this.maskGroups[groupIndex].startIndex,
                        this.maskGroups[groupIndex].startIndex +
                            this.maskGroups[groupIndex].value.length,
                    ),
                );
        });
        // console.log('[DEV][MASK]', this);
    }

    updateMask() {
        if (this.mask) {
            this.visibleMask.next(MaskDirective.getVisibleMask(this.value, this.mask));
            this.defineMaskGroups();
        }
        if (this.mask && this.editByParts) {
            this._validator = this.ngControl.control.validator;
        }
    }

    ngOnDestroy() {
        this.maskSbscrptn.unsubscribe();
        if (this.selectedGroupSbscrptn) this.selectedGroupSbscrptn.unsubscribe();
    }

    private defineMaskGroups() {
        const defaultGroup = { value: '', delimiter: '', startIndex: 0 };
        let currentGroup = { ...defaultGroup };
        this.maskGroups = [];
        this.mask.split('').forEach((char, i) => {
            if (MaskDirective.REGEX_MAP.get(char)) currentGroup.value += char;
            else {
                if (currentGroup.value || !this.maskGroups.length) {
                    currentGroup.delimiter += char;
                    this.maskGroups.push(currentGroup);
                    const prevGroup = this.maskGroups[this.maskGroups.length - 1];
                    currentGroup = {
                        ...defaultGroup,
                        startIndex:
                            prevGroup.startIndex +
                            prevGroup.delimiter.length +
                            prevGroup.value.length,
                    };
                } else {
                    this.maskGroups[this.maskGroups.length - 1].delimiter += char;
                }
            }
        });
        if (currentGroup.value) {
            this.maskGroups.push(currentGroup);
        }
    }

    private selectGroup() {
        const el =
            this.el.nativeElement.tagName === 'INPUT'
                ? this.el.nativeElement
                : this.el.nativeElement.querySelector('.native-input');
        const posCursor = el.selectionStart;
        const groupIndexToSelect = this.maskGroups.findIndex(
            (v) => posCursor < v.startIndex + v.value.length,
        );
        this.selectedGroup$.next(
            groupIndexToSelect > -1 ? groupIndexToSelect : this.maskGroups.length - 1,
        );
    }

    private updateValue(value: string, posCursor: number = 0) {
        this.value = value;
        this.maskValueChange.emit(value);

        MaskDirective.delay().then(() => {
            this.ngControl.control.updateValueAndValidity();
            this.syncPosCursorMask(posCursor);
        });
    }

    private defineValue() {
        let value: string = this.value;
        let displayValue: string = null;
        // console.log('[DEV][MASK]', this.value);
        if (this.mask) {
            const newValueAndCursorPosition = this.editByParts
                ? MaskDirective.getValuePartsByMask(value, this.mask, 0, this.maskGroups)
                : MaskDirective.getValueByMask(value, this.mask);
            if (
                !this.forceMask ||
                (!this._isFocused.value &&
                    this.valueIsOnlyMaskChar(newValueAndCursorPosition.value))
            ) {
                displayValue = '';
            } else {
                displayValue = newValueAndCursorPosition.value;
            }

            value = displayValue;
            this.visibleMask.next(MaskDirective.getVisibleMask(displayValue, this.mask));
        } else {
            displayValue = this.value;
        }

        if (!this.forceMask) return;

        MaskDirective.delay()
            .then(() => {
                if (MaskDirective.displayValue !== displayValue) {
                    MaskDirective.displayValue = displayValue;
                    this.ngControl.control.setValue(displayValue);
                    return MaskDirective.delay();
                }
            })
            .then(() => {
                if (value !== this.value) return this.updateValue(value);
            });
    }

    private onValueChange(newValue: string, forceEmpty: boolean = false) {
        if (newValue !== MaskDirective.displayValue || forceEmpty) {
            let displayValue = newValue;
            let value = newValue;
            let posCursor: number =
                this.el.nativeElement.tagName === 'INPUT'
                    ? this.el.nativeElement.selectionStart
                    : this.el.nativeElement.querySelector('.native-input').selectionStart;

            if (!forceEmpty && (newValue == null || newValue.trim() === '')) {
                value = null;
            } else if (this.mask) {
                const newValueAndCursorPosition = this.editByParts
                    ? MaskDirective.getValuePartsByMask(
                          newValue,
                          this.mask,
                          posCursor,
                          this.maskGroups,
                      )
                    : MaskDirective.getValueByMask(newValue, this.mask, posCursor);
                displayValue = newValueAndCursorPosition.value;
                posCursor = newValueAndCursorPosition.posCursor;
                this.visibleMask.next(MaskDirective.getVisibleMask(displayValue, this.mask));
                value = displayValue;

                // для корректного срабатывания переходов по стрелкам между группами
                // обновляем текущую выбранную группу на основании пересчитанной позиции курсора
                if (this.editByParts) {
                    const groupIndexToSelect = this.maskGroups.findIndex(
                        (v) => v.startIndex === posCursor,
                    );
                    if (groupIndexToSelect > -1) this.selectedGroup$.next(groupIndexToSelect);
                }
            }

            MaskDirective.displayValue = displayValue;

            if (newValue !== displayValue) {
                if (this.el.nativeElement.tagName !== 'INPUT')
                    this.el.nativeElement.querySelector('.native-input').value = displayValue;
                this.ngControl.control.setValue(displayValue);
            }

            this.syncPosCursorMask(posCursor);

            if (value !== this.value) {
                this.updateValue(value, posCursor);
            }
        }
    }

    private syncPosCursorMask(posCursor) {
        // console.log('[DEBUG] syncPosCursorMask', posCursor)
        let el =
            this.el.nativeElement.tagName === 'INPUT'
                ? this.el.nativeElement
                : this.el.nativeElement.querySelector('.native-input');
        if (el) {
            el.selectionStart = posCursor;
            el.selectionEnd = posCursor;
        }
        let wrapField = this.renderer.parentNode(this.el.nativeElement);
        wrapField.querySelector('.sw-mask').selectionStart = posCursor;
        wrapField.querySelector('.sw-mask').selectionEnd = posCursor;
    }

    public syncScrollLeftMask() {
        let el =
            this.el.nativeElement.tagName === 'INPUT'
                ? this.el.nativeElement
                : this.el.nativeElement.querySelector('.native-input');

        let wrapField = this.renderer.parentNode(this.el.nativeElement);
        wrapField.querySelector('.sw-mask').scrollLeft = el.scrollLeft;
        wrapField.querySelector('.sw-mask').selectionStart = el.selectionStart;
        wrapField.querySelector('.sw-mask').selectionEnd = el.selectionEnd;
    }

    private valueIsOnlyMaskChar(value: string): boolean {
        if (MaskDirective.getVisibleMask('', this.mask) === value) return true;
        return false;
    }

    // tslint:disable-next-line: member-ordering
    private static getValueByMask(
        value,
        mask: string,
        posCursor: number = 0,
    ): { value: string; posCursor: number } {
        value = value.toString();

        let len = value.length;
        let maskLen = mask.length;
        let pos = 0;
        let newValue = '';

        for (let i = 0; i < Math.min(len, maskLen); i++) {
            let maskChar = mask.charAt(i);
            let newChar = value.charAt(pos);
            let regex: RegExp = MaskDirective.REGEX_MAP.get(maskChar);

            if (regex) {
                pos++;

                if (regex.test(newChar)) {
                    if (maskChar === MaskDirective.RUS_VEHICLE_LIMITED) {
                        newChar = newChar.toUpperCase();
                        if (MaskDirective.RUS_LAT_PAIRS[newChar]) {
                            newChar = MaskDirective.RUS_LAT_PAIRS[newChar];
                        }
                    }
                    newValue += newChar;
                } else {
                    i--;
                    len--;
                }
            } else {
                maskChar === newChar ? pos++ : len++;
                if (maskChar !== newChar && posCursor >= i) posCursor++;
                newValue += maskChar;
            }
        }
        // console.log('[DEV][MASK] newValue', newValue, value, mask)
        return { value: newValue, posCursor };
    }

    // tslint:disable-next-line: member-ordering
    private static getValuePartsByMask(
        value,
        mask: string,
        posCursor: number = 0,
        maskGroups,
    ): { value: string; groups: string[]; posCursor: number } {
        value = value ? value.toString() : '';
        let newValue = '';

        // разбиваем по delimiter пришедшее в value на группы, соответствующие маске
        let valueGroups = [];
        let tmpValue = value;
        // maskGroups.forEach((v) => {
        //     const parts = v.delimiter ? tmpValue.split(v.delimiter) : [tmpValue];
        //     const val = parts.splice(0, 1)[0];
        //     valueGroups.push(val);
        //     tmpValue = parts.join(v.delimiter);
        // });
        maskGroups.forEach((v) => {
            const parts = v.delimiter ? tmpValue.split(v.delimiter) : [tmpValue];
            const val = parts.splice(0, 1)[0];
            valueGroups.push(val.substr(0, v.value.length));
            const remain = val.substring(v.value.length);
            if (val.length > v.value.length && remain !== '_') {
                if (parts.length > 0) {
                    parts[0] = remain + parts[0];
                } else {
                    parts.push(remain);
                }
            }
            tmpValue = parts.join(v.delimiter);
        });
        // фикс, для ситуации, когда backspace удаляет разделитель и эл-ты сдвигаются
        // например, 01.02.2020, пользователь удалил точку, приходит 0102.2020
        // TODO: более универсальный способ отловить этот момент
        if (valueGroups.filter((v) => v).length < maskGroups.length) {
            maskGroups.forEach((v, i) => {
                if (
                    i < maskGroups.length - 1 &&
                    v.value.length + maskGroups[i + 1].value.length === valueGroups[i].length
                ) {
                    valueGroups.splice(i + 1, 0, valueGroups[i].substr(v.value.length));
                    valueGroups.splice(-1, 1);
                }
            });
        }
        // console.log('[DEV][MASK] maskGroups, valueGroups', maskGroups, valueGroups)

        // каждую группу посимвольно сличаем с данными маски, учитывая изменения в позиции курсора при удалении эл-тов,
        // несоответствующих шаблону и добавлении _
        // _ обязательно используем в невалидных/отсутствующих позициях в группе,
        // чтобы сохранить положение символов и синхронизировать содержимое текста с маской-подложкой
        let groups = [];

        valueGroups.forEach((group, groupIndex) => {
            let len = group.length;
            const maskLen = maskGroups[groupIndex].value.length;
            let position = 0;
            let newGroup = '';

            for (let i = 0; i < Math.min(len, maskLen); i++) {
                let newChar = group.charAt(position);
                const regexp = MaskDirective.REGEX_MAP.get(
                    maskGroups[groupIndex].value.charAt(position),
                );
                if (regexp && regexp.test(newChar)) {
                    if (
                        maskGroups[groupIndex].value.charAt(position) ===
                        MaskDirective.RUS_VEHICLE_LIMITED
                    ) {
                        newChar = newChar.toUpperCase();
                        if (MaskDirective.RUS_LAT_PAIRS[newChar]) {
                            newChar = MaskDirective.RUS_LAT_PAIRS[newChar];
                        }
                    }
                    newGroup += newChar;
                } else {
                    i--;
                    len--;
                    if (posCursor > newGroup.length + newValue.length) posCursor--;
                }
                position++;
            }

            // заполняем недостающие символы в группе символами _ + смещаем с их учетом курсор
            if (newGroup.length < maskLen) {
                const addEmpty = maskLen - newGroup.length;
                if (posCursor > newGroup.length + newValue.length) posCursor += addEmpty;
                newGroup += new Array(addEmpty).fill('_').join('');
            }

            newValue += newGroup;

            // если курсор достиг конца группы, надо его перенести через делимитер,
            if (posCursor === newValue.length) posCursor += maskGroups[groupIndex].delimiter.length;

            newValue += maskGroups[groupIndex].delimiter;
            groups.push(newGroup);
        });

        // console.log('[DEV][MASK] newValue', newValue, value, mask, posCursor)
        return { value: newValue, groups, posCursor };
    }

    // tslint:disable-next-line: member-ordering
    private static getVisibleMask(value, mask: string): string {
        if (typeof value === 'object' && value != null) return '';
        value = value === null ? '' : value.toString();

        let maskLen = mask.length;
        let newValue = '';

        for (let i = 0; i < maskLen; i++) {
            let maskChar = mask.charAt(i);
            let newChar = value.charAt(i);
            let regex: RegExp = MaskDirective.REGEX_MAP.get(maskChar);

            newValue += regex ? (regex.test(newChar) ? newChar : '_') : maskChar;
        }
        // console.log('[DEV][MASK]', newValue, value)
        return value === newValue ? '' : newValue;
    }

    // tslint:disable-next-line: member-ordering
    private static getValueSansSpecialChars(maskedValue: string, mask: string): string {
        let maskLen = (mask && mask.length) || 0;
        return maskedValue
            .split('')
            .filter((currChar, idx) => idx < maskLen && MaskDirective.REGEX_MAP.has(mask[idx]))
            .join('');
    }

    // tslint:disable-next-line: member-ordering
    private static delay(ms: number = 0): Promise<void> {
        return new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(() => null);
    }
}
