<span
    class="sw-range__pin"
    *ngIf="pin"
>
    {{ range.value }} {{ pinDimension }}
</span>
<input
    #range
    type="range"
    class="sw-range__input sw-range--progress"
    [attr.name]="name"
    [attr.min]="min"
    [attr.max]="max"
    [attr.step]="step"
    [attr.disabled]="disabled ? '' : null"
    [value]="value"
/>
