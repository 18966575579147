import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { EntityService } from '@shared/services/entity.service';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'status',
})
export class StatusPipe implements PipeTransform {
    constructor(private entity: EntityService) {}

    transform(id: string) {
        if (!id) return of(null);

        return this.entity.getEntity$('statusy.status', id).pipe(map((v) => v?.$snapshot));
    }
}
