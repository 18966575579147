import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EntData, EntList, EntSchema, SchemaType } from '@shared/models/srv.types';

type StorageItem = EntData | EntList | EntSchema;

interface EntStorage<T, U, S> {
    list: {
        [index: string]: U;
    };
    entity: {
        [index: string]: T;
    };
    schema: {
        schema_list: {
            [index: string]: S;
        };
        schema_create: {
            [index: string]: S;
        };
        schema_transit: {
            [index: string]: S;
        };
        schema_retrieve: {
            [index: string]: S;
        };
        schema_update: {
            [index: string]: S;
        };
    };
}

const DEFAULT_STORAGE: EntStorage<EntData, EntList, EntSchema> = {
    list: {},
    entity: {},
    schema: {
        schema_list: {},
        schema_create: {},
        schema_update: {},
        schema_retrieve: {},
        schema_transit: {},
    },
};

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private storage: EntStorage<EntData, EntList, EntSchema> = JSON.parse(
        JSON.stringify(DEFAULT_STORAGE),
    );

    private storage$: EntStorage<
        BehaviorSubject<EntData>,
        BehaviorSubject<EntList>,
        BehaviorSubject<EntSchema>
    > = JSON.parse(JSON.stringify(DEFAULT_STORAGE));

    constructor() {}

    getItem(entityType: string, id: string = ''): EntData {
        return this._getExistedItem(this.storage, entityType, id);
    }

    getSchema(schemaType: SchemaType, entityType: string, id: string = ''): EntSchema {
        return this._getExistedSchema(this.storage, schemaType, entityType, id);
    }

    getItem$(entityType: string, id: string = ''): Observable<EntData> {
        return this._getItem$(entityType, id).asObservable();
    }

    getSchema$(schemaType: SchemaType, entityType: string, id: string = ''): Observable<EntSchema> {
        return this._getSchema$(schemaType, entityType, id).asObservable();
    }

    getList$(entityType: string): Observable<EntList> {
        return this._getList$(entityType);
    }

    patchItem(entityType: string, id: string, properties: any) {
        const root = this._getItemRoot(this.storage, 'entity');
        const path = this._getItemPath(entityType, id);
        const item = this.getItem(entityType, id);
        if (!item) return;
        const newItem = {
            ...item,
            $snapshot: {
                ...item.$snapshot,
                ...properties,
            },
        };
        root[path] = newItem;
        const item$ = this._getItem$(entityType, id);
        if (item$) item$.next(newItem);
    }

    setItem(entityType: string, item, id: string = '') {
        if (item.included) {
            item.included.forEach((itemIncluded) => {
                this.getItem$(itemIncluded.type, itemIncluded.id);
                this.setItem(itemIncluded.type, itemIncluded, itemIncluded.id);
            });

            item.included = item.included.map((v) => {
                return {
                    type: v.type,
                    id: v.id,
                };
            });
        }
        const root = this._getItemRoot(this.storage, 'entity');
        const path = this._getItemPath(entityType, id);
        root[path] = item;
        const item$ = this._getItem$(entityType, id);
        if (item$) item$.next(item);
    }

    setSchema(schemaType: SchemaType, entityType: string, item, id: string = '') {
        const existedSchema$ = this._getExistedSchema$(schemaType, entityType, id);
        if (existedSchema$) existedSchema$.next(item);

        const schemaRoot$ = this._getItemRoot(this.storage$, 'schema', schemaType);
        const schemaRoot = this._getItemRoot(this.storage, 'schema', schemaType);

        const schemaPath = this._getItemPath(entityType, id);
        schemaRoot[schemaPath] = item;
        if (schemaRoot$[schemaPath]) {
            schemaRoot$[schemaPath].next(item);
        }
    }

    // перезаписываем/записываем все содержимое списка
    setList(entityType: string, list) {
        const root = this._getItemRoot(this.storage, 'list');
        const path = this._getItemPath(entityType);
        root[path] = list;
        const existedItem$ = this._getExistedList$(entityType);
        if (existedItem$) existedItem$.next(list);

        const listEnt = list;
        const entityRoot$ = this._getItemRoot(this.storage$, 'entity');
        const entityRoot = this._getItemRoot(this.storage, 'entity');
        if (!listEnt.list) return;

        listEnt.list.forEach((listItem) => {
            const listItemPath = this._getItemPath(listItem.type, listItem.id);
            entityRoot[listItemPath] = listItem;
            if (entityRoot$[listItemPath]) {
                entityRoot$[listItemPath].next(listItem);
            }
        });
    }

    //чистим все хранилище
    clearStorage() {
        ['list', 'entity', 'schema'].forEach((storageType) => {
            if (storageType === 'schema') {
                Object.keys(this.storage$[storageType]).forEach((schemaType) => {
                    Object.keys(this.storage$[storageType][schemaType]).forEach((path) => {
                        this.removeItemByPath(storageType, path, schemaType);
                    });
                });
            } else {
                Object.keys(this.storage$[storageType]).forEach((path) => {
                    this.removeItemByPath(storageType, path);
                });
            }
        });
    }

    // удаляем сущность по типу и id
    removeItem(storageType: string, entityType: string, id: string = '', schemaType: string = '') {
        const path = this._getItemPath(entityType, id);
        this.removeItemByPath(storageType, path, schemaType);
    }

    removeItemByPath(storageType: string, path: string = '', schemaType: string = '') {
        this._checkAndDeleteStorageItem(storageType, path, schemaType);
    }

    // существует ли уже behavior BehaviorSubject для сущности, если да,
    // предпологается, что она будет заполнена данными
    isExistedItem(entityType: string, id: string = ''): boolean {
        return !!this._getExistedItem(this.storage$, entityType, id);
    }

    // существует ли уже behavior BehaviorSubject для схемы, если да,
    // предпологается, что она будет заполнена данными
    isExistedSchema(schemaType: SchemaType, entityType: string, id: string = ''): boolean {
        return !!this._getExistedSchema(this.storage$, schemaType, entityType, id);
    }

    isExistedList(entityType: string): boolean {
        return !!this._getExistedList(this.storage$, entityType);
    }

    // Получаем сущность асинхронно
    // если сущность отсутствует создаем BehaviorSubject
    private _getItem$(entityType: string, id: string = ''): BehaviorSubject<EntData> {
        let existedItem = this._getExistedItem$(entityType, id);
        if (existedItem) return existedItem;
        const root$ = this._getItemRoot(this.storage$, 'entity');

        const path = this._getItemPath(entityType, id);
        root$[path] = new BehaviorSubject(null);
        return this._getItem$(entityType, id);
    }

    // Получаем схему асинхронно
    // если сущность отсутствует создаем BehaviorSubject
    private _getSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string = '',
    ): BehaviorSubject<EntSchema> {
        let existedSchema = this._getExistedSchema$(schemaType, entityType, id);
        if (existedSchema) return existedSchema;
        const root$ = this._getItemRoot(this.storage$, 'schema');
        const path = this._getItemPath(entityType, id);
        root$[schemaType][path] = new BehaviorSubject(null);
        return this._getSchema$(schemaType, entityType, id);
    }

    private _getList$(entityType: string): BehaviorSubject<EntList> {
        let existedItem = this._getExistedList$(entityType);
        if (existedItem) return existedItem;
        const root$ = this._getItemRoot(this.storage$, 'list');

        const path = this._getItemPath(entityType);
        root$[path] = new BehaviorSubject(null);
        return this._getList$(entityType);
    }

    private _checkAndDeleteStorageItem(storageType, path, schemaType = '') {
        const root$ = this._getItemRoot(this.storage$, storageType, schemaType);
        const root = this._getItemRoot(this.storage, storageType, schemaType);
        if (!root$[path]) return;
        // if (root$[path]?.observed) {
        //     root$[path].next(null);
        // }
        root$[path]?.complete();
        delete root$[path];
        delete root[path];
    }

    private _getItemPath(entityType: string = '', id: string = '') {
        return id ? `${entityType}-${id}` : entityType;
    }

    private _getItemRoot<T, U, S>(
        from: EntStorage<T, U, S>,
        type: string = '',
        schemaType: string = '',
    ): T {
        return schemaType ? from[type][schemaType] : from[type];
    }

    private _getExistedItem<T, U, S>(
        from: EntStorage<T, U, S>,
        entityType: string = '',
        id: string = '',
    ): T {
        return from.entity[this._getItemPath(entityType, id)];
    }

    private _getExistedSchema<T, U, S>(
        from: EntStorage<T, U, S>,
        schemaType: SchemaType,
        entityType: string = '',
        id: string = '',
    ): S {
        return from.schema[schemaType][this._getItemPath(entityType, id)];
    }

    private _getExistedList<T, U, S>(from: EntStorage<T, U, S>, entityType: string = ''): U {
        let path = this._getItemPath(entityType);
        let existedItem = from.list[path];
        return existedItem;
    }

    // существует ли уже behavior BehaviorSubject для сущности, если да,
    // предпологается, что она будет заполнена данными
    private _getExistedItem$(entityType: string, id: string = ''): BehaviorSubject<EntData> {
        return this._getExistedItem(this.storage$, entityType, id);
    }

    // существует ли уже behavior BehaviorSubject для схемы, если да,
    // предпологается, что она будет заполнена данными
    private _getExistedSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string = '',
    ): BehaviorSubject<EntSchema> {
        return this._getExistedSchema(this.storage$, schemaType, entityType, id);
    }

    private _getExistedList$(entityType: string): BehaviorSubject<EntList> {
        return this._getExistedList(this.storage$, entityType);
    }
}
