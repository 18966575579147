import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AlertOptions {
    headerKey: string;
    headerParams?: { [index: string]: any };
    header?: string;
    messageKey: string;
    message?: string;
    messageParams?: { [index: string]: any };
    buttons: AlertButton[];
    cssClass?: string | string[];
    icon?: string;
    color?: string;
    hasCloseIcon?: boolean;
}
export interface Alert {
    options: AlertOptions;
    onDidDismiss?: <T = any>() => Promise<T>;
    dismiss?: Function;
}
export interface AlertButton {
    titleKey: string;
    titleParams?: { [index: string]: any };
    color?: string;
    fillButton?: string;
    iconStart?: string;
    iconEnd?: string;
    action?: string;
    actionPayload?: any;
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private _defaultParams: AlertOptions = {
        messageKey: '',
        headerKey: '',
        buttons: [
            {
                titleKey: 'ok',
            },
        ],
        hasCloseIcon: true,
    };

    private _alert: Alert;

    private _needUpdateAlerts$ = new BehaviorSubject(true);

    alert$ = this._needUpdateAlerts$.asObservable().pipe(map(() => this._alert));

    constructor() {}

    create(params) {
        params = {
            ...this._defaultParams,
            ...params,
        };

        const alert: Alert = {
            options: params,
            onDidDismiss: () => {
                return new Promise((resolve) => {
                    alert.dismiss = resolve;
                });
            },
        };

        this._alert = alert;

        this._needUpdateAlerts$.next(true);
        return alert;
    }

    dismiss(result = null, alert = this._alert) {
        if (!alert) return;

        alert.dismiss(result);
        this._alert = null;
        this._needUpdateAlerts$.next(true);
    }
}
